<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" :width="width" :height="height">
    <g>
      <polygon fill="#0E512B" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
      <path
        fill="#E6E6E6"
        d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"
      ></path>
      <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
      <path fill="#1B7343" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
      <path
        fill="#FFFFFF"
        d="M17.592,36h-2.455l-1.531-2.455L12.092,36H9.691l2.605-4.018l-2.449-3.835H12.2l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L17.592,36z"
      ></path>
      <path fill="#FFFFFF" d="M18.473,36v-7.853h2.122v6.14h3.024V36H18.473z"></path>
      <path
        fill="#FFFFFF"
        d="M30.01,33.615c0,0.487-0.124,0.919-0.371,1.297s-0.604,0.672-1.069,0.881 c-0.465,0.21-1.012,0.314-1.638,0.314c-0.523,0-0.961-0.037-1.316-0.11s-0.723-0.201-1.106-0.384v-1.891 c0.405,0.208,0.825,0.37,1.262,0.486s0.838,0.174,1.203,0.174c0.315,0,0.546-0.054,0.693-0.163s0.22-0.25,0.22-0.422 c0-0.107-0.03-0.201-0.088-0.282c-0.06-0.08-0.154-0.162-0.285-0.244c-0.13-0.082-0.479-0.251-1.044-0.505 c-0.512-0.232-0.896-0.458-1.152-0.677c-0.256-0.219-0.446-0.469-0.569-0.752c-0.124-0.283-0.186-0.617-0.186-1.004 c0-0.724,0.263-1.288,0.79-1.692s1.25-0.606,2.17-0.606c0.813,0,1.642,0.188,2.487,0.563l-0.65,1.638 c-0.734-0.336-1.368-0.504-1.901-0.504c-0.276,0-0.476,0.048-0.602,0.145s-0.188,0.217-0.188,0.359c0,0.154,0.08,0.292,0.239,0.414 c0.159,0.122,0.591,0.344,1.297,0.666c0.677,0.305,1.146,0.631,1.41,0.98C29.878,32.646,30.01,33.085,30.01,33.615z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M38.298,36h-2.455l-1.531-2.455L32.798,36h-2.401l2.605-4.018l-2.449-3.835h2.353l1.418,2.428l1.364-2.428 h2.422l-2.487,4.001L38.298,36z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: { type: String, default: '' },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
