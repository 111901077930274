<template>
  <docs-item-view
    :id="0"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.ExternalLink"
    :name="link.title"
    :created-at="''"
    :author="''"
    :edited-at="''"
    :editor="''"
    :group="''"
    :icon="icons.arrowUndo"
    :link-url="link.url"
    @onItemClick="open"
  />
</template>

<script lang="ts" setup>
import { arrowUndoOutline } from 'ionicons/icons';
import type { PropType } from 'vue';

import { DocsItemView } from '@/components';
import type { DocsViewTypeEnum } from '@/enums';
import { DocumentTypeEnum } from '@/enums';
import { openLink } from '@/helpers';
import type { ExternalLinkModel } from '@/types';

const icons = {
  arrowUndo: arrowUndoOutline,
};
const props = defineProps({
  link: {
    type: Object as PropType<ExternalLinkModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

const open = async () => {
  await openLink(props.link.url);
};
</script>
