import type { customPageShowTypeEnum } from '@/enums';
import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseCustomPagesModel,
  ResponseCustomPageModel,
  RequestCustomPageCreateModel,
  ResponseSuccessModel,
  RequestUpdatePageModel,
  CustomPageEditRowModel,
} from '@/types';

export class CustomPageApiService {
  async getAllPages(): Promise<ResponseCustomPagesModel | ResponseErrorModel> {
    return axios.get('/customPages/all');
  }
  async getPageById(id: number): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.get(`/customPages/byId/${id}`);
  }
  async getPageDraftById(id: number): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.get(`/customPages/draft/${id}`);
  }
  async autocomplete(text: string): Promise<ResponseCustomPagesModel | ResponseErrorModel> {
    return axios.get(`/customPages/all?search=${text}`);
  }
  async loadMore(url: string): Promise<ResponseCustomPagesModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async createPage(data: RequestCustomPageCreateModel): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.post(`/customPages/create`, data);
  }
  async updatePage(data: RequestUpdatePageModel): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.post(`/customPages/update`, data);
  }
  async updateDashboard(
    data: { rows: CustomPageEditRowModel[] },
    title: string,
    groupId: number
  ): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.post(`/customPages/updateDashboard`, { groupId, title, data });
  }
  async deletePage(pageId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/customPages/delete/${pageId}`);
  }
  async setShowType(
    pageId: number,
    showType: customPageShowTypeEnum
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/customPages/setShowType`, { pageId, showType });
  }
  async getGroupDashboard(groupId: number): Promise<ResponseCustomPageModel | ResponseErrorModel> {
    return axios.get(`/customPages/groupDashboard/${groupId}`);
  }
}
