<template>
  <div class="group-select" :style="`flex-shrink: ${showResetBtn ? 0 : 1}`">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" key="spinner" class="select-button">
        <ion-button fill="outline">
          <icons-provider
            :icon-props="{ width: '16', height: '16' }"
            :name="AppIconsEnum.DotsAnim"
            fill="var(--ion-color-medium)"
          />
        </ion-button>
      </div>

      <div v-else style="display: flex; height: 100%">
        <div v-if="showResetBtn" class="reset-button">
          <ion-button mode="md" fill="outline" @click="resetGroup()">
            <icons-provider
              slot="icon-only"
              :icon-props="{
                width: '16',
                height: '16',
                fill: 'var(--ion-color-medium)',
              }"
              name="remove"
            />
          </ion-button>
        </div>

        <div
          v-if="postType === PostTypeActionEnum.Task ? selectedGroup === undefined : true"
          key="text"
          class="select-button"
        >
          <ion-button fill="outline" :disabled="accessToRepickGroup" @click="openModal()">
            <ion-label>{{ selectText }}</ion-label>
          </ion-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonLabel } from '@ionic/vue';
import { computed, watch, onMounted, ref } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import { useRoute } from 'vue-router';

import { IconsProvider } from '@/components';
import { PostTypeActionEnum, UserRoleEnum, GroupsFilterEnum, AppIconsEnum } from '@/enums';
import { componentGroupSelect, getGroupsWhereUserIsAdmin } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useUserStore, useGroupsStore, useProjectsStore, useNetworkStore } from '@/store';
import type { GroupModel } from '@/types';

const props = defineProps({
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    default: '',
  },
  isUnbound: {
    type: Boolean,
    required: true,
  },
  note: {
    type: String,
    default: '',
  },
  authorId: {
    type: Number as PropType<number | undefined>,
    default: undefined,
  },
});
const { t } = useI18n();
const route = useRoute();

// Store
const userStore = useUserStore();
const groupStore = useGroupsStore();
const projectsStore = useProjectsStore();
const networkStore = useNetworkStore();

// Variables
const selectedGroup = ref<GroupModel | undefined>(undefined);
const selectedAuthorId = ref<number | undefined>(undefined);
const isLoading: ComputedRef<boolean> = computed(() => groupStore.isLoading);
const networkAllowPostToFeed: ComputedRef<boolean> = computed(() => networkStore.settings?.allowPostToFeed ?? false);
const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);
const selectText: ComputedRef<string> = computed(() => {
  if (selectedGroup.value !== undefined) {
    return selectedGroup.value.title;
  } else {
    return !networkAllowPostToFeed.value ? t('appMenu.select') : t('appMenu.feed');
  }
});
const canPostGroups: ComputedRef<GroupModel[]> = computed(() => {
  if (props.postType === PostTypeActionEnum.Announcement) {
    return currentUserRoleId.value < UserRoleEnum.Administrator
      ? getGroupsWhereUserIsAdmin(groupStore.getCanPostGroups().data)
      : groupStore.getCanPostGroups().data;
  } else {
    return groupStore.getCanPostGroups().data;
  }
});
const accessToRemoveGroup: ComputedRef<boolean> = computed(
  () => (route.name !== ROUTES_NAME.GROUP_BY_ID || props.isUnbound) && route.name !== ROUTES_NAME.PROJECT_BY_ID
);
const accessToRepickGroup: ComputedRef<boolean> = computed(
  () => (isLoading.value || route.name === ROUTES_NAME.GROUP_BY_ID) && !props.isUnbound
);
const accessToRepickProject: ComputedRef<boolean> = computed(() => {
  if (route.name === ROUTES_NAME.PROJECT_BY_ID) {
    return false;
  } else {
    return true;
  }
});
const showResetBtn: ComputedRef<boolean> = computed(
  () =>
    selectedGroup.value !== undefined &&
    props.postType === PostTypeActionEnum.Task &&
    !accessToRepickGroup.value &&
    accessToRepickProject.value
);
//NOTE: if you can post in the feed
const accessToPickAllNetwork: ComputedRef<boolean> = computed(
  () =>
    accessToRemoveGroup.value &&
    networkAllowPostToFeed.value &&
    (route.name !== ROUTES_NAME.GROUP_BY_ID || (route.name === ROUTES_NAME.GROUP_BY_ID && props.isUnbound)) &&
    route.name !== ROUTES_NAME.PROJECT_BY_ID &&
    props.postType !== PostTypeActionEnum.Task
);

// Methods
const autoSelect = () => {
  if (route.name !== ROUTES_NAME.GROUP_BY_ID || route.name !== ROUTES_NAME.PROJECT_BY_ID || !props.isUnbound) {
    if (
      props.postType === PostTypeActionEnum.Announcement &&
      currentUserRoleId.value < UserRoleEnum.Administrator &&
      canPostGroups.value.length > 0
    ) {
      selectedGroup.value = canPostGroups.value[0];
    } else {
      selectedGroup.value = undefined;
    }
  }

  if (route.name === ROUTES_NAME.GROUP_BY_ID || route.name === ROUTES_NAME.GROUP_DASHBOARD) {
    if (canPostGroups.value.length > 0) {
      const { id } = route.params;
      const index = canPostGroups.value.findIndex((n) => n.id === Number(id));
      if (~index) {
        selectedGroup.value = canPostGroups.value[index];
      }
    } else {
      selectedGroup.value = undefined;
    }
  }

  if (route.name === ROUTES_NAME.PROJECT_BY_ID) {
    const project = projectsStore.getCurrentProject;

    if (project) {
      selectedGroup.value = project.group as any;
    } else {
      selectedGroup.value = undefined;
    }
  }
};

const openModal = async () => {
  //NOTE: Get the selected group from the selection window

  let filter;
  switch (props.postType) {
    case PostTypeActionEnum.Announcement:
      filter = GroupsFilterEnum.Admin;
      break;
    case PostTypeActionEnum.Task:
      filter = GroupsFilterEnum.AvailableProjects;
      break;
    default:
      filter = selectedAuthorId.value ? GroupsFilterEnum.ByUser : GroupsFilterEnum.CanPost;
  }

  const result = await componentGroupSelect(filter, accessToPickAllNetwork.value, props.note, selectedAuthorId.value);
  selectedGroup.value = result.data ?? undefined;
};

const resetGroup = () => {
  autoSelect();
};

// Watchers
watch(selectedGroup, () => {
  emit('onSelectChange', selectedGroup.value);
});

watch(
  () => props.authorId,
  (newValue: number | undefined) => {
    selectedAuthorId.value = newValue;
  }
);

// Lifecycle
onMounted(async () => {
  if (canPostGroups.value.length === 0) await groupStore.canPostGroups();
  autoSelect();
});

// Emits
const emit = defineEmits(['onSelectChange']);
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
ion-button {
  margin: 0;
}
.group-select {
  position: relative;
  height: 100%;

  ion-button {
    min-height: 40px;
    position: relative;
    --border-color: var(--ion-color-custom-element-lighter);
    --color: var(--ion-color-medium);
    --background-hover: var(--ion-color-custom-element-lighter);
    --border-radius: #{app-radius(md)};
  }

  .select-button {
    position: relative;
    min-width: 85px;
    height: 100%;
    margin-left: app-padding(md);
    ion-button {
      width: 100%;
      ion-label {
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        font-size: 0.9rem;
      }
    }
  }
}

@include respond-to-min-width(2xl) {
  ion-label {
    font-size: 1rem;
  }
}
</style>
