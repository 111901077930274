import { peopleOutline, keyOutline, settingsOutline, mailOpenOutline, openOutline } from 'ionicons/icons';
import { cloneDeep, omit, pick, pickBy } from 'lodash';
import type { ComputedRef } from 'vue';
import { computed, inject } from 'vue';
import type { ComposerTranslation } from 'vue-i18n';

import { formatDateHelper } from './helper';
import { componentAdminUserAccessModal, componentAdminUserRoleModal, componentPasswordChange } from './modalComponents';
import { useUsers } from './useUsersHelper';

import type { NetworkSettingsTypeEnum } from '@/enums';
import {
  AdminUserManagementMenuEnum,
  AllowExternalUsersToGroupEnum,
  AllowMobileAppEnum,
  AccessByRoleEnum,
  AppMenuEnum,
  DigestSubscribeEnum,
  DocEditAccessEnum,
  DownloadPostAccessLevel,
  HomePageEnum,
  OrgChartTypeEnum,
  ProfileEditEnum,
  RegistrationModeEnum,
  SelfInviteAllowedEnum,
  UserAdminAccessLevel,
  UserRoleEnum,
  UseritemLinkExpirationEnum,
  aiAssistantAccessLevelEnum,
  taskManagementAccessLevelEnum,
  UserGroupRoleEnum,
  AdminInviteUserModeEnum,
  NotificationTemplatesTypeEnum,
  PostObserveViewersAccessEnum,
} from '@/enums';
import { useToasts } from '@/helpers';
import router, { ROUTES_NAME } from '@/router';
import { useAdminStore, useUserStore } from '@/store';
import type {
  AdminAccordionGroupModel,
  AdminParamsModel,
  AdminRegisterModel,
  AppMenuItem,
  FormSelectDataModel,
  HomePageModel,
  InviteUserModel,
  NetworkBrandingModel,
  NetworkDesignModel,
  NetworkEmailFooterModel,
  NetworkFullSettingsModel,
  NetworkMobileAppsModel,
  NetworkSettingsModel,
  NotificationTemplateShortModel,
  SendInvitationsModel,
  TabCategories,
  UserModel,
} from '@/types';

type IUseAdmin = {
  /**
   * Get admin menu items
   * Main menu items by admin area
   */
  getMenuItems(): AppMenuItem[];

  /**
   * Get accordion group
   * Returns subcategories depending on the page
   *
   * @param  page - Page
   */
  getAccordionGroup(page: AppMenuEnum): AdminAccordionGroupModel;

  /**
   * Save settings
   * Called when saving data in the admin area
   *
   * @param  page - Page
   */
  saveSettings(page: AppMenuEnum, type: NetworkSettingsTypeEnum): Promise<void>;

  /**
   * Get settings
   * Get settings from the network
   *
   * @param page - Page
   */
  getSettings(page: AppMenuEnum): Promise<void>;

  /**
   * Handle network setting
   * Actions when changing settings
   *
   * @param setting - Setting key
   */
  handleNetworkSetting(
    setting: keyof NetworkFullSettingsModel | null,
    value: NetworkFullSettingsModel[keyof NetworkFullSettingsModel]
  ): Promise<void>;

  /**
   * Get domain title by type
   *
   * @param type - Self invite allowed type
   */
  getDomainTitleByType(type: SelfInviteAllowedEnum): string;

  /**
   * Get home page title by type
   *
   * @param type - Home page type
   * @param title - Homee page Entity title
   */
  getHomePageTitleByType(type: HomePageEnum, title?: string): string;

  /**
   * Get home page items
   *
   * @param homePage - Home page model
   */
  getHomePageItems(homePage: HomePageModel): FormSelectDataModel;

  /**
   * Get user management menu items
   *
   * @param userId - User ID
   */
  getUserManagementMenuItems(userId: number): TabCategories<AdminUserManagementMenuEnum>[];

  /**
   * User management menu action
   *
   * @param action - Action for user management
   * @param user - User entity
   */
  userManagementMenuAction(action: AdminUserManagementMenuEnum, user: UserModel): Promise<void>;

  /** Get admin pages */
  getAdminPages(): string[];

  /** Get user-management table header */
  getUserManagementTableHeader(): any[];

  /** Get domains table header */
  getDomainsTableHeader(): any[];

  /** Get register-user table header */
  getRegisterUserTableHeader(): any[];

  /** Get register-user table rows */
  getRegisterUserTableRows(): { tag: string; description: string }[];

  /** Get email-footer table header */
  getEmailFooterTableHeader(): any[];

  /** Get email-footer table rows */
  getEmailFooterTableRows(): { tag: string; description: string }[];

  /** Get invite-template user table header */
  getInviteUserTableHeader(): any[];

  /** Get invite-template user table rows */
  getInviteUserTableRows(): { tag: string; description: string }[];

  /**
   * Invite user via admin
   *
   * @param data - Data for invite user
   */
  inviteUser(data: SendInvitationsModel): Promise<{ rows: { email: string; status: string }[]; cols: any } | null>;

  /**
   * Register user via admin
   *
   * @param data - Data for register user
   */
  registerUser(data: AdminRegisterModel): Promise<{ rows: { email: string; status: string }[]; cols: any } | null>;

  /** Get register/invite user tabs */
  getRegisterUserTabs(): TabCategories<AdminInviteUserModeEnum>[];

  /**
   * Save template
   *
   * @param type - Template type
   * @param data - Template data
   */
  saveTemplate(type: NotificationTemplatesTypeEnum, data: NotificationTemplateShortModel): Promise<void>;
};

export function useAdmin(): IUseAdmin {
  const userStore = useUserStore();
  const adminStore = useAdminStore();
  const t = inject('i18nT') as ComposerTranslation;

  const currentNetworkSettings: ComputedRef<NetworkFullSettingsModel | null> = computed(
    () => adminStore.networkSettings
  );
  const currentDesignSettings: ComputedRef<NetworkDesignModel | null> = computed(() => adminStore.design);
  const currentEmailFooterSettings: ComputedRef<string | null> = computed(
    () => adminStore.templates[NotificationTemplatesTypeEnum.EmailFooter]?.body ?? null
  );

  const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);

  //#region Private methods
  /**
   * Returns a block from “Network Settings params”
   *
   */
  const _getNetworkSettingsParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: t('networkSettings.networkSettings.title'),
        modelName: 'title',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.title || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: true,
        required: accessToSetting('title'),
      },
      {
        title: t('networkSettings.homePageJson.title'),
        modelName: 'homePageJson',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: null,
        button: getHomePageTitleByType(
          currentNetworkSettings.value?.homePageJson.type ?? HomePageEnum.DefaultNewsFeed,
          currentNetworkSettings.value?.homePageJson?.title
        ),
        size: 4,
        enabled: accessToSetting('homePageJson'),
      },
      {
        title: '',
        modelName: 'orgChartType',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.orgChartType ?? OrgChartTypeEnum.Off,
          title: t('networkSettings.orgChartType.title'),
          subTitle: t('networkSettings.orgChartType.description'),
          options: [
            {
              title: t('off'),
              value: OrgChartTypeEnum.Off,
            },
            {
              title: 'Social',
              value: OrgChartTypeEnum.Social,
            },
            {
              title: 'ActiveDirectory',
              value: OrgChartTypeEnum.ActiveDirectory,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('orgChartType'),
      },
      {
        title: '',
        modelName: 'defaultDigestSubscribe',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.defaultDigestSubscribe
            ? currentNetworkSettings.value.defaultDigestSubscribe === DigestSubscribeEnum.Manual
              ? DigestSubscribeEnum.Weekly
              : currentNetworkSettings.value.defaultDigestSubscribe
            : DigestSubscribeEnum.Never,
          title: t('networkSettings.defaultDigestSubscribe.title'),
          subTitle: t('networkSettings.defaultDigestSubscribe.description'),
          options: [
            {
              title: t('dates.never'),
              value: DigestSubscribeEnum.Never,
            },
            {
              title: t('dates.daily'),
              value: DigestSubscribeEnum.Daily,
            },
            {
              title: t('dates.weekly'),
              value: DigestSubscribeEnum.Weekly,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('defaultDigestSubscribe'),
      },
      {
        title: '',
        modelName: 'docEditAccess',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.docEditAccess ?? DocEditAccessEnum.Off,
          title: t('networkSettings.docEditAccess.title'),
          subTitle: t('networkSettings.docEditAccess.description'),
          options: [
            {
              title: t('off'),
              value: DocEditAccessEnum.Off,
            },
            {
              title: t('roles.standardUser'),
              value: DocEditAccessEnum.AllExceptExternal,
            },
            {
              title: t('roles.onlyAdmins'),
              value: DocEditAccessEnum.Administrators,
            },
            {
              title: t('everyone'),
              value: DocEditAccessEnum.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('docEditAccess'),
      },
      {
        title: '',
        modelName: 'allowSeeDocHistory',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.allowSeeDocHistory ?? AccessByRoleEnum.Off,
          title: t('networkSettings.allowSeeDocHistory.title'),
          subTitle: t('networkSettings.allowSeeDocHistory.description'),
          options: [
            {
              title: t('off'),
              value: AccessByRoleEnum.Off,
            },
            {
              title: t('roles.moderatorOrHigher'),
              value: AccessByRoleEnum.ModeratorOrHigher,
            },
            {
              title: t('roles.adminOrHigher'),
              value: AccessByRoleEnum.AdminOrHigher,
            },
            {
              title: t('everyone'),
              value: AccessByRoleEnum.AllUsers,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('allowSeeDocHistory'),
      },
      {
        title: '',
        modelName: 'taskManagementAccessLevel',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.taskManagementAccessLevel ?? taskManagementAccessLevelEnum.None,
          title: t('networkSettings.taskManagementAccessLevel.title'),
          subTitle: t('networkSettings.taskManagementAccessLevel.description'),
          options: [
            {
              title: t('off'),
              value: taskManagementAccessLevelEnum.None,
            },
            {
              title: t('roles.standardUser'),
              value: taskManagementAccessLevelEnum.User,
            },
            {
              title: t('roles.onlyNetworkAdmins'),
              value: taskManagementAccessLevelEnum.Administrator,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('taskManagementAccessLevel'),
      },
      {
        title: '',
        modelName: 'aiAssistantAccessLevel',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.aiAssistantAccessLevel ?? aiAssistantAccessLevelEnum.None,
          title: t('networkSettings.aiAssistantAccessLevel.title'),
          subTitle: t('networkSettings.aiAssistantAccessLevel.description'),
          options: [
            {
              title: t('off'),
              value: aiAssistantAccessLevelEnum.None,
            },
            {
              title: t('roles.standardUser'),
              value: aiAssistantAccessLevelEnum.User,
            },
            {
              title: t('roles.onlyNetworkAdmins'),
              value: aiAssistantAccessLevelEnum.Administrator,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('aiAssistantAccessLevel'),
      },
      {
        title: '',
        modelName: 'allowMobileApp',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.allowMobileApp ?? AllowMobileAppEnum.Off,
          title: t('networkSettings.allowMobileApp.title'),
          subTitle: t('networkSettings.allowMobileApp.description'),
          options: [
            {
              title: t('off'),
              value: AllowMobileAppEnum.Off,
            },
            {
              title: t('roles.adminOrHigher'),
              value: AllowMobileAppEnum.AdminOrHigher,
            },
            {
              title: t('everyone'),
              value: AllowMobileAppEnum.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('allowMobileApp'),
      },
      {
        title: '',
        modelName: 'searchSuggestions',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.searchSuggestions ?? AccessByRoleEnum.AdminOrHigher,
          title: t('networkSettings.searchSuggestions.title'),
          subTitle: t('networkSettings.searchSuggestions.description'),
          options: [
            {
              title: t('off'),
              value: AccessByRoleEnum.Off,
            },
            {
              title: t('roles.moderatorOrHigher'),
              value: AccessByRoleEnum.ModeratorOrHigher,
            },
            {
              title: t('roles.adminOrHigher'),
              value: AccessByRoleEnum.AdminOrHigher,
            },
            {
              title: t('everyone'),
              value: AccessByRoleEnum.AllUsers,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('searchSuggestions'),
      },
      {
        title: '',
        modelName: 'postObserveViewersAccessLevel',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value:
            currentNetworkSettings.value?.postObserveViewersAccessLevel ??
            PostObserveViewersAccessEnum.SuperAdministrator,
          title: t('networkSettings.roleSeePostViews.title'),
          subTitle: t('networkSettings.roleSeePostViews.description'),
          options: [
            {
              title: t('off'),
              value: PostObserveViewersAccessEnum.None,
            },
            {
              title: t('roles.moderatorOrHigher'),
              value: PostObserveViewersAccessEnum.Moderator,
            },
            {
              title: t('roles.adminOrHigher'),
              value: PostObserveViewersAccessEnum.Administrator,
            },
            {
              title: t('roles.superAdminOrHigher'),
              value: PostObserveViewersAccessEnum.SuperAdministrator,
            },
            {
              title: t('everyone'),
              value: PostObserveViewersAccessEnum.User,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('postObserveViewersAccessLevel'),
      },
      {
        title: '',
        modelName: 'digestTemplateEditor',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.digestTemplateEditor.title'),
          description: t('networkSettings.digestTemplateEditor.description'),
          checked: currentNetworkSettings.value?.digestTemplateEditor ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('digestTemplateEditor'),
      },
      {
        title: '',
        modelName: 'disableInvitations',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.disableInvitations.title'),
          description: t('networkSettings.disableInvitations.description'),
          checked: currentNetworkSettings.value?.disableInvitations ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('disableInvitations'),
      },
      {
        title: '',
        modelName: 'allowPostToFeed',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowPostToFeed.title'),
          description: t('networkSettings.allowPostToFeed.description'),
          checked: currentNetworkSettings.value?.allowPostToFeed ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('allowPostToFeed'),
      },
      {
        title: '',
        modelName: 'allowPostOnBehalf',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowPostOnBehalf.title'),
          description: t('networkSettings.allowPostOnBehalf.description'),
          checked: currentNetworkSettings.value?.allowPostOnBehalf ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('allowPostOnBehalf'),
      },
      {
        title: '',
        modelName: 'isEveryoneCanDeactivateUsers',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.isEveryoneCanDeactivateUsers.title'),
          description: t('networkSettings.isEveryoneCanDeactivateUsers.description'),
          checked: currentNetworkSettings.value?.isEveryoneCanDeactivateUsers ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('isEveryoneCanDeactivateUsers'),
      },
      {
        title: '',
        modelName: 'isAdvancedWikiesEditor',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.isAdvancedWikiesEditor.title'),
          description: t('networkSettings.isAdvancedWikiesEditor.description'),
          checked: currentNetworkSettings.value?.isAdvancedWikiesEditor ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('isAdvancedWikiesEditor'),
      },
      {
        title: '',
        modelName: 'sendFullMessageTextInNotif',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.sendFullMessageTextInNotif.title'),
          description: t('networkSettings.sendFullMessageTextInNotif.description'),
          checked: currentNetworkSettings.value?.sendFullMessageTextInNotif ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('sendFullMessageTextInNotif'),
      },
      {
        title: '',
        modelName: 'enableChat',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.enableChat.title'),
          description: t('networkSettings.enableChat.description'),
          checked: currentNetworkSettings.value?.enableChat ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('enableChat'),
      },
      {
        title: '',
        modelName: 'enableVideoChat',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.enableVideoChat.title'),
          description: t('networkSettings.enableVideoChat.description'),
          checked: currentNetworkSettings.value?.enableVideoChat ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('enableVideoChat'),
      },
      {
        title: '',
        modelName: 'removeTempChat',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.removeTempChat.title'),
          description: t('networkSettings.removeTempChat.description'),
          checked: currentNetworkSettings.value?.removeTempChat ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('removeTempChat'),
      },
      {
        title: '',
        modelName: 'allowMandantGroup',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowMandantGroup.title'),
          description: t('networkSettings.allowMandantGroup.description'),
          checked: currentNetworkSettings.value?.allowMandantGroup ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('allowMandantGroup'),
      },
      {
        title: '',
        modelName: 'switchNotificationsToPush',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.switchNotificationsToPush.title'),
          description: t('networkSettings.switchNotificationsToPush.description'),
          checked: currentNetworkSettings.value?.switchNotificationsToPush ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('switchNotificationsToPush'),
      },
      {
        title: '',
        modelName: 'resultFieldForIdeas',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.resultFieldForIdeas.title'),
          description: t('networkSettings.resultFieldForIdeas.description'),
          checked: currentNetworkSettings.value?.resultFieldForIdeas ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('resultFieldForIdeas'),
      },
      {
        title: '',
        modelName: 'showOnlineUsers',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.showOnlineUsers.title'),
          description: t('networkSettings.showOnlineUsers.description'),
          checked: currentNetworkSettings.value?.showOnlineUsers ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('showOnlineUsers'),
      },
      {
        title: '',
        modelName: 'onlyAdminsCanCreateTags',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.onlyAdminsCanCreateTags.title'),
          description: t('networkSettings.onlyAdminsCanCreateTags.description'),
          checked: currentNetworkSettings.value?.onlyAdminsCanCreateTags ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('onlyAdminsCanCreateTags'),
      },
      {
        title: '',
        modelName: 'everyoneCanCreateGroups',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.everyoneCanCreateGroups.title'),
          description: t('networkSettings.everyoneCanCreateGroups.description'),
          checked: currentNetworkSettings.value?.everyoneCanCreateGroups ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('everyoneCanCreateGroups'),
      },
      {
        title: '',
        modelName: 'onlyForAdminsBadgesManaging',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.onlyForAdminsBadgesManaging.title'),
          description: t('networkSettings.onlyForAdminsBadgesManaging.description'),
          checked: currentNetworkSettings.value?.onlyForAdminsBadgesManaging ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('onlyForAdminsBadgesManaging'),
      },
      {
        title: '',
        modelName: 'defaultEnableNotificationsOnJoin',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.defaultEnableNotificationsOnJoin.title'),
          description: t('networkSettings.defaultEnableNotificationsOnJoin.description'),
          checked: currentNetworkSettings.value?.defaultEnableNotificationsOnJoin ?? true,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('defaultEnableNotificationsOnJoin'),
      },
      {
        title: '',
        modelName: 'allowGroupsFineTuning',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowGroupsFineTuning.title'),
          description: t('networkSettings.allowGroupsFineTuning.description'),
          checked: currentNetworkSettings.value?.allowGroupsFineTuning ?? false,
        },
        button: null,
        size: 4,
        enabled: accessToSetting('allowGroupsFineTuning'),
      },
      {
        title: '',
        modelName: 'downloadPostAccessLevel',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.downloadPostAccessLevel ?? DownloadPostAccessLevel.None,
          title: t('networkSettings.downloadPostAccessLevel.title'),
          subTitle: t('networkSettings.downloadPostAccessLevel.description'),
          options: [
            {
              title: t('off'),
              value: DownloadPostAccessLevel.None,
            },
            {
              title: t('roles.onlyAdmins'),
              value: DownloadPostAccessLevel.Administrator,
            },
            {
              title: t('everyone'),
              value: DownloadPostAccessLevel.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('downloadPostAccessLevel'),
      },
      {
        title: '',
        modelName: 'sendArchivedPost',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.sendArchivedPost ?? UserAdminAccessLevel.None,
          title: t('networkSettings.sendArchivedPost.title'),
          subTitle: t('networkSettings.sendArchivedPost.description'),
          options: [
            {
              title: t('off'),
              value: UserAdminAccessLevel.None,
            },
            {
              title: t('roles.onlyAdmins'),
              value: UserAdminAccessLevel.Administrator,
            },
            {
              title: t('everyone'),
              value: UserAdminAccessLevel.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('sendArchivedPost'),
      },
      {
        title: '',
        modelName: 'userItemLinkExpiration',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.userItemLinkExpiration ?? UseritemLinkExpirationEnum.Never,
          title: t('networkSettings.useritemLinkExpiration.title'),
          subTitle: t('networkSettings.useritemLinkExpiration.description'),
          options: [
            {
              title: t('dates.never'),
              value: UseritemLinkExpirationEnum.Never,
            },
            {
              title: t('dates.hour', { n: 1 }),
              value: UseritemLinkExpirationEnum.OneHour,
            },
            {
              title: t('dates.hour', { n: 12 }),
              value: UseritemLinkExpirationEnum.TwelveHours,
            },
            {
              title: t('dates.day', { n: 1 }),
              value: UseritemLinkExpirationEnum.OneDay,
            },
            {
              title: t('dates.week', { n: 1 }),
              value: UseritemLinkExpirationEnum.OneWeek,
            },
            {
              title: t('dates.week', { n: 2 }),
              value: UseritemLinkExpirationEnum.TwoWeeks,
            },
            {
              title: t('dates.week', { n: 4 }),
              value: UseritemLinkExpirationEnum.FourWeeks,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('userItemLinkExpiration'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Users params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getUsersParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: '',
        modelName: 'editProfile',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.editProfile ?? ProfileEditEnum.All,
          title: t('networkSettings.editProfile.title'),
          subTitle: t('networkSettings.editProfile.description'),
          options: [
            {
              title: t('networkSettings.editProfile.off'),
              value: ProfileEditEnum.None,
            },
            {
              title: t('roles.onlyAdmins'),
              value: ProfileEditEnum.Administrators,
            },
            {
              title: t('networkSettings.editProfile.everyone'),
              value: ProfileEditEnum.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('editProfile'),
      },
      {
        title: '',
        modelName: 'publishUserItemAboutNewUser',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.publishUserItemAboutNewUser.title'),
          description: t('networkSettings.publishUserItemAboutNewUser.description'),
          checked: currentNetworkSettings.value?.publishUserItemAboutNewUser ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('publishUserItemAboutNewUser'),
      },
      {
        title: '',
        modelName: 'showUsersEmails',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.showUsersEmails.title'),
          description: t('networkSettings.showUsersEmails.description'),
          checked: currentNetworkSettings.value?.showUsersEmails ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('showUsersEmails'),
      },
      {
        title: '',
        modelName: 'showFollowLists',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.showFollowLists.title'),
          description: t('networkSettings.showFollowLists.description'),
          checked: currentNetworkSettings.value?.showFollowLists ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('showFollowLists'),
      },
      {
        title: '',
        modelName: 'fieldAddingEnabled',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.fieldAddingEnabled.title'),
          description: t('networkSettings.fieldAddingEnabled.description'),
          checked: currentNetworkSettings.value?.fieldAddingEnabled ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('fieldAddingEnabled'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Registration params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getRegistrationParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: '',
        modelName: 'registrationMode',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.registrationMode ?? RegistrationModeEnum.Domain,
          title: t('networkSettings.registrationMode.title'),
          subTitle: t('networkSettings.registrationMode.description'),
          options: [
            {
              title: t('auth.signUp.mode.byDomain'),
              value: RegistrationModeEnum.Domain,
            },
            {
              title: t('auth.signUp.mode.synchronisation'),
              value: RegistrationModeEnum.Sync,
            },
            {
              title: t('auth.signUp.mode.byPresentEmployeeData'),
              value: RegistrationModeEnum.UserParameters,
            },
            {
              title: t('auth.signUp.mode.azureAD'),
              value: RegistrationModeEnum.Oauth,
            },
            {
              title: t('everyone'),
              value: RegistrationModeEnum.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('registrationMode'),
      },
      {
        title: '',
        modelName: 'enableRequestsRegistrationToNetwork',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.enableRequestsRegistrationToNetwork.title'),
          description: t('networkSettings.enableRequestsRegistrationToNetwork.description'),
          checked: currentNetworkSettings.value?.enableRequestsRegistrationToNetwork ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('enableRequestsRegistrationToNetwork'),
      },
      {
        title: '',
        modelName: 'activation_SkipGroups',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.activation_SkipGroups.title'),
          description: t('networkSettings.activation_SkipGroups.description'),
          checked: currentNetworkSettings.value?.activation_SkipGroups ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('activation_SkipGroups'),
      },
      {
        title: '',
        modelName: 'activation_SkipInvites',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.activation_SkipInvites.title'),
          description: t('networkSettings.activation_SkipInvites.description'),
          checked: currentNetworkSettings.value?.activation_SkipInvites ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('activation_SkipInvites'),
      },
      {
        title: '',
        modelName: 'activation_SkipPhoto',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.activation_SkipPhoto.title'),
          description: t('networkSettings.activation_SkipPhoto.description'),
          checked: currentNetworkSettings.value?.activation_SkipPhoto ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('activation_SkipPhoto'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Network Type params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getNetworkTypeParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: '',
        modelName: 'isDefaultNetwork',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.isDefaultNetwork.title'),
          description: t('networkSettings.isDefaultNetwork.description'),
          checked: currentNetworkSettings.value?.isDefaultNetwork ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('isDefaultNetwork'),
      },
      {
        title: '',
        modelName: 'isOfficialNetwork',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.isOfficialNetwork.title'),
          description: t('networkSettings.isOfficialNetwork.description'),
          checked: currentNetworkSettings.value?.isOfficialNetwork ?? false,
        },
        button: null,
        size: 1,
        enabled: accessToSetting('isOfficialNetwork'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Invitation Settings params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getInvitationSettingsParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: '',
        modelName: 'allowExternalUsersToGroup',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.allowExternalUsersToGroup ?? AllowExternalUsersToGroupEnum.Off,
          title: t('networkSettings.allowExternalUsersToGroup.title'),
          subTitle: t('networkSettings.allowExternalUsersToGroup.description'),
          options: [
            {
              title: t('off'),
              value: AllowExternalUsersToGroupEnum.Off,
            },
            {
              title: t('roles.onlyNetworkAdmins'),
              value: AllowExternalUsersToGroupEnum.NetworkAdmins,
            },
            {
              title: t('roles.groupAndNetworkAdmins'),
              value: AllowExternalUsersToGroupEnum.GroupAdmins,
            },
            {
              title: t('roles.groupMembers'),
              value: AllowExternalUsersToGroupEnum.All,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('allowExternalUsersToGroup'),
      },
      {
        title: '',
        modelName: 'userExternalRoleId',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: {
          value: currentNetworkSettings.value?.userExternalRoleId ?? UserGroupRoleEnum.ExternalGroupUser,
          title: t('networkSettings.userExternalRoleId.title'),
          subTitle: t('networkSettings.userExternalRoleId.description'),
          options: [
            {
              title: t('roles.standardUser'),
              value: UserGroupRoleEnum.StandardUser,
            },
            {
              title: t('roles.likeAndComment'),
              value: UserGroupRoleEnum.CanLikeAndComment,
            },
            {
              title: t('roles.readAndLike'),
              value: UserGroupRoleEnum.CanOnlyReadAndLike,
            },
            {
              title: t('roles.externalUser'),
              value: UserGroupRoleEnum.ExternalGroupUser,
            },
            {
              title: t('roles.externalUserLikeAndComment'),
              value: UserGroupRoleEnum.ExternalUserLikeAndComment,
            },
            {
              title: t('roles.externalUserReadAndLike'),
              value: UserGroupRoleEnum.CanOnlyReadAndLike,
            },
          ],
        },
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('userExternalRoleId'),
      },
      {
        title: '',
        modelName: 'allowUserRoleFineTuning',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowUserRoleFineTuning.title'),
          description: t('networkSettings.allowUserRoleFineTuning.description'),
          checked: currentNetworkSettings.value?.allowUserRoleFineTuning ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('allowUserRoleFineTuning'),
      },
      {
        title: '',
        modelName: 'allowGroupAdminToSetDefaultRole',
        subTitle: null,
        icon: 'home',
        radio: null,
        input: null,
        select: null,
        toggle: {
          title: t('networkSettings.allowGroupAdminToSetDefaultRole.title'),
          description: t('networkSettings.allowGroupAdminToSetDefaultRole.description'),
          checked: currentNetworkSettings.value?.allowGroupAdminToSetDefaultRole ?? false,
        },
        button: null,
        size: 2,
        enabled: accessToSetting('allowGroupAdminToSetDefaultRole'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Mobile Apps params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getMobileAppsParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: t('networkSettings.appleAppID.title'),
        modelName: 'appleAppID',
        subTitle: t('networkSettings.appleAppID.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.appleAppID || '',
        select: null,
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('appleAppID'),
      },
      {
        title: t('networkSettings.googleAppID.title'),
        modelName: 'googleAppID',
        subTitle: t('networkSettings.googleAppID.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.googleAppID || '',
        select: null,
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('googleAppID'),
      },
      {
        title: t('networkSettings.appleMessengerID.title'),
        modelName: 'appleMessengerID',
        subTitle: t('networkSettings.appleMessengerID.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.appleMessengerID || '',
        select: null,
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('appleMessengerID'),
      },
      {
        title: t('networkSettings.googleMessengerID.title'),
        modelName: 'googleMessengerID',
        subTitle: t('networkSettings.googleMessengerID.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.googleMessengerID || '',
        select: null,
        toggle: null,
        button: null,
        size: 2,
        enabled: accessToSetting('googleMessengerID'),
      },
      {
        title: t('networkSettings.identityTitle.title'),
        modelName: 'identityTitle',
        subTitle: t('networkSettings.identityTitle.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.identityTitle || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('identityTitle'),
      },
    ];

    return items.filter((n) => n.enabled);
  };

  /**
   * Returns a block from “Branding params”
   *
   * @returns {AdminParamsModel[]}
   */
  const _getBrandingParams = (): AdminParamsModel[] => {
    const items: AdminParamsModel[] = [
      {
        title: t('networkSettings.linkIdentity.title'),
        modelName: 'linkIdentity',
        subTitle: t('networkSettings.linkIdentity.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.linkIdentity || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('linkIdentity'),
      },
      {
        title: t('networkSettings.linkAbout.title'),
        modelName: 'linkAbout',
        subTitle: t('networkSettings.linkAbout.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.linkAbout || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('linkAbout'),
      },
      {
        title: t('networkSettings.linkToSupport.title'),
        modelName: 'linkToSupport',
        subTitle: t('networkSettings.linkToSupport.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.linkToSupport || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('linkToSupport'),
      },
      {
        title: t('networkSettings.supportEmail.title'),
        modelName: 'supportEmail',
        subTitle: t('networkSettings.supportEmail.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.supportEmail || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('supportEmail'),
      },
      {
        title: t('networkSettings.linkUserAgreement.title'),
        modelName: 'linkUserAgreement',
        subTitle: t('networkSettings.linkUserAgreement.description'),
        icon: 'home',
        radio: null,
        input: currentNetworkSettings.value?.linkUserAgreement || '',
        select: null,
        toggle: null,
        button: null,
        size: 4,
        enabled: accessToSetting('linkUserAgreement'),
      },
    ];

    return items.filter((n) => n.enabled);
  };
  //#endregion

  //#region Public methods
  const getMenuItems = (): AppMenuItem[] => {
    const registrationEnabled = import.meta.env.VITE_ENABLE_ADMIN_REGISTRATION === 'true';
    const items: AppMenuItem[] = [
      {
        order: 0,
        name: AppMenuEnum.Admin,
        title: t('network.title'),
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
      },
      {
        order: 1,
        name: AppMenuEnum.AdminDesign,
        title: t('design.title'),
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_DESIGN },
      },
      {
        order: 2,
        name: AppMenuEnum.AdminEmailFooter,
        title: t('emailFooter.title'),
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_EMAIL_FOOTER },
      },
      {
        order: 3,
        name: AppMenuEnum.AdminNetworkSettings,
        title: t('networkSettings.networkSettings.title'),
        icon: 'home',
        enabled: currentUserRoleId.value >= UserRoleEnum.Administrator,
        submenu: [
          {
            order: 0,
            name: AppMenuEnum.AdminNetworkSettings,
            title: t('appMenu.settings'),
            icon: 'home',
            enabled: true,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.ADMIN_NETWORK_SETTINGS },
          },
          {
            order: 1,
            name: AppMenuEnum.AdminNetworkDomainList,
            title: t('domain.list'),
            icon: 'home',
            enabled: true,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST },
          },
          {
            order: 2,
            name: AppMenuEnum.AdminBranding,
            title: t('networkSettings.branding.title'),
            icon: 'home',
            enabled: currentUserRoleId.value >= UserRoleEnum.SuperAdministrator,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.ADMIN_BRANDING },
          },
          {
            order: 3,
            name: AppMenuEnum.AdminMobileApps,
            title: t('networkSettings.mobileApps.title'),
            icon: 'home',
            enabled: currentUserRoleId.value >= UserRoleEnum.SuperAdministrator,
            submenu: null,
            hidden: false,
            link: { name: ROUTES_NAME.ADMIN_MOBILE_APPS },
          },
        ].filter((n) => n.enabled),
        hidden: false,
        link: null,
      },
      /* {
        order: 4,
        name: AppMenuEnum.Admin,
        title: 'Usage rules',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_USAGE_RULES },
      },
      {
        order: 5,
        name: AppMenuEnum.Admin,
        title: 'Password settings',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_PASSWORD_SETTINGS },
      },
      {
        order: 6,
        name: AppMenuEnum.Admin,
        title: 'Applications',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_APPLICATIONS },
      },
      {
        order: 7,
        name: AppMenuEnum.Admin,
        title: 'Statistics',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_STATISTICS },
      },
      {
        order: 8,
        name: AppMenuEnum.Admin,
        title: 'Banner',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_BANNER },
      },
      {
        order: 9,
        name: AppMenuEnum.Admin,
        title: 'Tags',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_TAGS },
      }, */
      {
        order: 10,
        name: AppMenuEnum.Admin,
        title: t('networkSettings.users.title'),
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: null,
      },
      {
        order: 11,
        name: AppMenuEnum.Admin,
        title: t('networkSettings.userManagement.title'),
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_USER_MANAGEMENT },
      },
      {
        order: 12,
        name: AppMenuEnum.Admin,
        title: t('invites.inviteAndRegister'),
        icon: 'home',
        enabled: currentUserRoleId.value >= UserRoleEnum.Administrator && registrationEnabled,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_INVITE_USER, query: { mode: AdminInviteUserModeEnum.Invite } },
      },
      /* {
        order: 14,
        name: AppMenuEnum.Admin,
        title: 'Restore post',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_RESTORE_POST },
      },
      {
        order: 15,
        name: AppMenuEnum.Admin,
        title: 'Badges',
        icon: 'home',
        enabled: true,
        submenu: null,
        hidden: false,
        link: { name: ROUTES_NAME.ADMIN_BADGES },
      }, */
    ];

    return items.filter((n) => n.enabled);
  };

  const getAccordionGroup = (page: AppMenuEnum): AdminAccordionGroupModel => {
    const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(() => userStore.current?.roleId ?? 0);
    switch (page) {
      case AppMenuEnum.AdminNetworkSettings:
        return {
          accordionItems: [
            {
              title: t('networkSettings.networkSettings.title'),
              items: _getNetworkSettingsParams(),
              enabled: _getNetworkSettingsParams().length > 0,
              type: 'network',
            },
            {
              title: t('networkSettings.invitationSettings.title'),
              items: _getInvitationSettingsParams(),
              enabled: _getInvitationSettingsParams().length > 0,
              type: 'invitation',
            },
            {
              title: t('networkSettings.users.title'),
              items: _getUsersParams(),
              enabled: _getUsersParams().length > 0,
              type: 'users',
            },
            {
              title: t('networkSettings.registration.title'),
              items: _getRegistrationParams(),
              enabled: _getRegistrationParams().length > 0,
              type: 'registration',
            },
            {
              title: t('networkSettings.networkType.title'),
              items: _getNetworkTypeParams(),
              enabled: _getNetworkTypeParams().length > 0,
              type: 'networkType',
            },
          ].filter((n) => n.enabled),
          category: AppMenuEnum.AdminNetworkSettings,
        };

      case AppMenuEnum.AdminMobileApps:
        return {
          accordionItems: [
            {
              title: t('networkSettings.mobileApps.title'),
              items: _getMobileAppsParams(),
              enabled: currentUserRoleId.value >= UserRoleEnum.SuperAdministrator,
              type: 'mobileApps',
            },
          ].filter((n) => n.enabled),
          category: AppMenuEnum.AdminMobileApps,
        };

      case AppMenuEnum.AdminBranding:
        return {
          accordionItems: [
            {
              title: t('networkSettings.branding.title'),
              items: _getBrandingParams(),
              enabled: currentUserRoleId.value >= UserRoleEnum.SuperAdministrator,
              type: 'branding',
            },
          ].filter((n) => n.enabled),
          category: AppMenuEnum.AdminBranding,
        };

      default:
        return {
          accordionItems: [],
          category: AppMenuEnum.Admin,
        };
        break;
    }
  };

  const saveSettings = async (page: AppMenuEnum, type: NetworkSettingsTypeEnum) => {
    const { showSonnerToast } = useToasts();
    const userStore = useUserStore();
    const _prepareImagesForSave = (): NetworkDesignModel | null => {
      if (currentDesignSettings.value) {
        const newSettings = cloneDeep(currentDesignSettings.value);

        newSettings.desktopLogo = currentDesignSettings.value?.desktopLogo?.split('/').pop() || '';
        newSettings.faviconLogo = currentDesignSettings.value?.faviconLogo?.split('/').pop() || '';
        newSettings.logonBackgroundImage = currentDesignSettings.value?.logonBackgroundImage?.split('/').pop() || '';
        newSettings.headDefaultBackgroundFileName =
          currentDesignSettings.value?.headDefaultBackgroundFileName?.split('/').pop() || '';

        return newSettings;
      }
      return null;
    };

    type KeysOfType<T> = keyof T;

    const _networkDesignKeys: KeysOfType<NetworkDesignModel>[] = [
      'headOpacityImage',
      'headBackgroundType',
      'headDefaultBackgroundFileName',
      'headBackgroundColor',
      'headForeColor',
      'headBorderColor',
      'headBackgroundFileName',
      'faviconLogo',
      'logonBackgroundImage',
      'documentTemplateFilename',
      'desktopLogo',
    ];

    const _networkEmailFooterKeys: KeysOfType<NetworkEmailFooterModel>[] = ['emailFooter'];

    const _networkMobileAppsKeys: KeysOfType<NetworkMobileAppsModel>[] = [
      'appleAppID',
      'googleAppID',
      'appleMessengerID',
      'googleMessengerID',
      'identityTitle',
    ];

    const _networkBrandingsKeys: KeysOfType<NetworkBrandingModel>[] = [
      'linkIdentity',
      'linkAbout',
      'linkToSupport',
      'supportEmail',
      'linkUserAgreement',
    ];

    switch (page) {
      case AppMenuEnum.AdminNetworkSettings:
        {
          if (currentNetworkSettings.value) {
            const networkSettingsToSave = omit(currentNetworkSettings.value, [
              ..._networkDesignKeys,
              ..._networkEmailFooterKeys,
              ..._networkMobileAppsKeys,
              ..._networkBrandingsKeys,
            ]);

            const result = await adminStore.saveNetworkSettings(
              pickBy(networkSettingsToSave, (value, key) =>
                accessToSetting(key as keyof NetworkFullSettingsModel)
              ) as NetworkSettingsModel,
              type
            );

            if (result && currentNetworkSettings.value.homePageJson)
              userStore.updateCurrentHomePage(currentNetworkSettings.value.homePageJson);
            showSonnerToast(t('networkSettings.networkSettings.success'), true);
          } else {
            console.error('No network settings to save');
            showSonnerToast(t('networkSettings.networkSettings.error'), false);
          }
        }
        break;

      case AppMenuEnum.AdminMobileApps:
        {
          if (currentNetworkSettings.value) {
            if (currentNetworkSettings.value) {
              const networkSettingsToSave = pick(currentNetworkSettings.value, _networkMobileAppsKeys);

              await adminStore.saveMobileAppsSettings(
                pickBy(networkSettingsToSave, (value, key) =>
                  accessToSetting(key as keyof NetworkFullSettingsModel)
                ) as NetworkMobileAppsModel
              );
            } else {
              console.error('No mobile apps settings to save');
            }
          }
        }
        break;

      case AppMenuEnum.AdminBranding:
        {
          if (currentNetworkSettings.value) {
            if (currentNetworkSettings.value) {
              const networkSettingsToSave = pick(currentNetworkSettings.value, _networkBrandingsKeys);

              await adminStore.saveBrandingSettings(
                pickBy(networkSettingsToSave, (value, key) =>
                  accessToSetting(key as keyof NetworkFullSettingsModel)
                ) as NetworkBrandingModel
              );
            } else {
              console.error('No branding settings to save');
            }
          }
        }
        break;

      case AppMenuEnum.AdminDesign:
        if (currentDesignSettings.value) {
          const newSettings = pick(_prepareImagesForSave(), _networkDesignKeys) as NetworkDesignModel;
          if (newSettings) {
            await adminStore.saveStyle(newSettings);
          } else {
            console.error('No design settings to save');
          }
        }
        break;

      case AppMenuEnum.AdminEmailFooter:
        if (currentEmailFooterSettings.value) {
          await adminStore.saveEmailFooter(currentEmailFooterSettings.value);
        } else {
          console.error('No email footer settings to save');
        }
        break;
    }
  };

  const getSettings = async (page: AppMenuEnum): Promise<void> => {
    switch (page) {
      case AppMenuEnum.AdminNetworkSettings:
        {
          await adminStore.currentNetworkSettings();
        }
        break;

      case AppMenuEnum.AdminMobileApps:
        {
          await adminStore.mobileAppsSettings();
        }
        break;

      case AppMenuEnum.AdminBranding:
        {
          await adminStore.brandingSettings();
        }
        break;

      case AppMenuEnum.AdminDesign:
        {
          await adminStore.designSettings();
        }
        break;

      case AppMenuEnum.AdminEmailFooter:
        {
          await adminStore.emailFooterSettings();
        }
        break;

      case AppMenuEnum.AdminNetworkDomainList:
        {
          await adminStore.domains();
        }
        break;

      default:
        break;
    }
  };

  const handleNetworkSetting = async (
    setting: keyof NetworkFullSettingsModel | null,
    value: NetworkFullSettingsModel[keyof NetworkFullSettingsModel]
  ): Promise<void> => {
    if (!adminStore.networkSettings || setting === null) {
      return;
    } else {
      adminStore.$patch((state) => {
        (state.networkSettings as any)[setting] = value;
      });
    }
  };

  const getDomainTitleByType = (type: SelfInviteAllowedEnum): string => {
    const titles = {
      [SelfInviteAllowedEnum.RegisterInHomeNetwork]: t('domain.type.registerInHomeNetwork'),
      [SelfInviteAllowedEnum.WhiteListEmail]: t('domain.type.whiteListEmail'),
      [SelfInviteAllowedEnum.BlackListEmail]: t('domain.type.blackListEmail'),
    };
    return titles[type];
  };

  const getHomePageTitleByType = (type: HomePageEnum, title?: string): string => {
    const entityTitle = title ? `: ${title}` : '';
    const titles = {
      [HomePageEnum.Group]: t('groupPage.title') + entityTitle,
      [HomePageEnum.DefaultNewsFeed]: t('appMenu.feed') + entityTitle,
      [HomePageEnum.CustomPage]: t('customPage.title') + entityTitle,
      [HomePageEnum.Tag]: t('taskManagement.tags.title') + entityTitle,
    };
    return titles[type as keyof typeof titles] || titles[HomePageEnum.DefaultNewsFeed];
  };

  const getHomePageItems = (homePage: HomePageModel): FormSelectDataModel => {
    return {
      value: homePage.type,
      title: t('networkSettings.homePageJson.title'),
      options: [
        {
          title: getHomePageTitleByType(HomePageEnum.DefaultNewsFeed),
          value: HomePageEnum.DefaultNewsFeed,
        },
        {
          title: getHomePageTitleByType(HomePageEnum.Tag),
          value: HomePageEnum.Tag,
        },
        {
          title: getHomePageTitleByType(HomePageEnum.CustomPage),
          value: HomePageEnum.CustomPage,
        },
        {
          title: getHomePageTitleByType(HomePageEnum.Group),
          value: HomePageEnum.Group,
        },
      ],
    };
  };

  const getUserManagementMenuItems = (userId: number): TabCategories<AdminUserManagementMenuEnum>[] => {
    const icons = {
      people: peopleOutline,
      key: keyOutline,
      settings: settingsOutline,
      mailOpen: mailOpenOutline,
      open: openOutline,
    };
    const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);
    const currentUserRoleId: ComputedRef<UserRoleEnum> = computed(() => userStore.current?.roleId ?? 0);
    const user: ComputedRef<UserModel> = computed(() => userStore.getUserProfile(userId));
    const userRoleId: ComputedRef<UserRoleEnum> = computed(
      () => user.value.roleId || UserRoleEnum.ExternalGroupUserReadLike
    );

    return [
      {
        value: AdminUserManagementMenuEnum.OpenProfile,
        active: true,
        icon: icons.open,
        title: t('userPage.userPageTitle'),
      },
      {
        value: AdminUserManagementMenuEnum.EditRole,
        active: currentUserRoleId.value >= userRoleId.value,
        icon: icons.people,
        title: t('userPage.infoList.roles.changeRole'),
      },
      {
        value: AdminUserManagementMenuEnum.RestorePassword,
        active: user.value.email.length > 0 && currentUserId.value !== userId,
        icon: icons.mailOpen,
        title: t('auth.signUp.resetPassword'),
      },
      {
        value: AdminUserManagementMenuEnum.SetPassword,
        active: currentUserRoleId.value >= userRoleId.value && currentUserId.value !== userId,
        icon: icons.key,
        title: t('passwordChange.setPassword'),
      },
      /*{
        value: AdminUserManagementMenuEnum.EditAccess,
        active: currentUserId.value !== userId,
        icon: icons.settings,
        title: t('userPage.infoList.access.title'),
      },*/
    ].filter((item) => item.active);
  };

  const userManagementMenuAction = async (action: AdminUserManagementMenuEnum, user: UserModel) => {
    const { showSonnerToast } = useToasts();
    switch (action) {
      case AdminUserManagementMenuEnum.EditRole:
        await componentAdminUserRoleModal(user.id, user.roleId ?? UserRoleEnum.User);
        break;

      case AdminUserManagementMenuEnum.EditAccess:
        await componentAdminUserAccessModal(user.id);
        break;

      case AdminUserManagementMenuEnum.OpenProfile:
        await router.push({
          name: ROUTES_NAME.USER_BY_ID,
          params: { id: user.id },
        });
        break;

      case AdminUserManagementMenuEnum.SetPassword:
        {
          if (user?.rowId) {
            await componentPasswordChange(true, user.rowId);
          } else {
            console.error('User rowId is not found');
          }
        }
        break;

      case AdminUserManagementMenuEnum.RestorePassword:
        {
          const result = await userStore.passwordRestore(user.email, false);
          if (result) {
            showSonnerToast(t('passwordRestore.success'), true);
          } else {
            showSonnerToast(t('passwordRestore.userNotFound'), false);
          }
        }
        break;

      default:
        break;
    }
  };

  const getAdminPages = (): string[] => {
    return [
      ROUTES_NAME.ADMIN_DESIGN,
      ROUTES_NAME.ADMIN_EMAIL_FOOTER,
      ROUTES_NAME.ADMIN_NETWORK_SETTINGS,
      ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST,
      ROUTES_NAME.ADMIN_BRANDING,
      ROUTES_NAME.ADMIN_MOBILE_APPS,
      ROUTES_NAME.ADMIN_USAGE_RULES,
      ROUTES_NAME.ADMIN_PASSWORD_SETTINGS,
      ROUTES_NAME.ADMIN_APPLICATIONS,
      ROUTES_NAME.ADMIN_STATISTICS,
      ROUTES_NAME.ADMIN_BANNER,
      ROUTES_NAME.ADMIN_TAGS,
      ROUTES_NAME.ADMIN_USER_MANAGEMENT,
      ROUTES_NAME.ADMIN_INVITE_USER,
      ROUTES_NAME.ADMIN_RESTORE_POST,
      ROUTES_NAME.ADMIN_BADGES,
    ];
  };

  const getUserManagementTableHeader = () => {
    const usersHelper = useUsers();
    const _cellStyle = {
      'line-height': 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };

    const _statusRenderer = (params: { value: string; data: UserModel }) => {
      const color = params.data.isActive ? 'var(--ion-color-success)' : 'var(--ion-color-danger)';
      return `<div style="width: 10px; height: 10px; border-radius: 50%; background-color: ${color}"/>`;
    };

    const _fullNameRenderer = (params: { value: string; data: UserModel }) => {
      return `<span style="display:block; font-size: 0.9rem; padding-bottom: 0.2rem;">${params.value}</span><span style="display:block;padding-bottom: 0.2rem;font-size: 0.8rem">${usersHelper.getUserSubtitle(params.data.position, params.data.subDivisionTitle)}</span><span style="font-size: 0.8rem"> ${params.data.email}</span>`;
    };

    const _isHomeRenderer = (params: { value: string; data: UserModel }) =>
      params.data?.isHome
        ? `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20px" height="20px"><path d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212" fill="none" stroke="var(--ion-color-medium)" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69" fill="none" stroke="var(--ion-color-medium)" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg>`
        : '';

    return [
      {
        field: 'isActive',
        maxWidth: 20,
        editable: false,
        checkboxSelection: false,
        headerName: '',
        cellRenderer: (params: { value: string; data: UserModel }) => _statusRenderer(params),
        cellStyle: () => ({ ..._cellStyle, justifyContent: 'center' }),
      },
      {
        field: 'fullName',
        flex: 1,
        minWidth: 270,
        editable: false,
        checkboxSelection: false,
        headerName: t('userPage.infoList.firstName'),
        cellRenderer: (params: { value: string; data: UserModel }) => _fullNameRenderer(params),
        cellStyle: () => _cellStyle,
      },
      {
        field: 'isHome',
        maxWidth: 22,
        editable: false,
        checkboxSelection: false,
        headerName: '',
        cellRenderer: (params: { value: string; data: UserModel }) => _isHomeRenderer(params),
        cellStyle: () => ({ ..._cellStyle, justifyContent: 'center' }),
      },
      {
        field: 'roleId',
        flex: 1,
        minWidth: 250,
        editable: false,
        checkboxSelection: false,
        headerName: t('userPage.infoList.roles.title'),
        valueFormatter: (params: { value: UserRoleEnum }) => usersHelper.getUserRoleTitleByRoleId(params.value).title,
        cellStyle: () => _cellStyle,
      },
      {
        field: 'createdAt',
        editable: false,
        checkboxSelection: false,
        headerName: t('dates.registerDate'),
        valueFormatter: (params: { value: string }) => formatDateHelper(params.value, 'short'),
        cellStyle: () => _cellStyle,
      },
    ];
  };

  const accessToSetting = (
    modelName:
      | keyof NetworkSettingsModel
      | keyof NetworkDesignModel
      | keyof NetworkMobileAppsModel
      | keyof NetworkBrandingModel
      | keyof NetworkEmailFooterModel
      | null
  ): boolean => {
    const accessIfSuperAdmin = computed(() => currentUserRoleId.value >= UserRoleEnum.SuperAdministrator);

    const accessIfAdmin = computed(() => currentUserRoleId.value >= UserRoleEnum.Administrator);

    const accessIfSystem = computed(() => currentUserRoleId.value >= UserRoleEnum.System);

    switch (modelName) {
      case 'enableChat':
      case 'enableVideoChat':
      case 'removeTempChat':
      case 'allowMandantGroup':
      case 'allowGroupsFineTuning':
      case 'isDefaultNetwork':
      case 'allowUserRoleFineTuning':
      case 'isOfficialNetwork':
        return accessIfSystem.value;

      case 'docEditAccess':
      case 'searchSuggestions':
      case 'isEveryoneCanDeactivateUsers':
      case 'isAdvancedWikiesEditor':
      case 'downloadPostAccessLevel':
      case 'sendArchivedPost':
      case 'editProfile':
      case 'fieldAddingEnabled':
      case 'enableRequestsRegistrationToNetwork':
      case 'allowExternalUsersToGroup':
      case 'userExternalRoleId':
      case 'allowGroupAdminToSetDefaultRole':
        return accessIfSuperAdmin.value;

      case 'postObserveViewersAccessLevel':
        return accessIfAdmin.value;
      //NOTE: disabled by Jakov,
      case 'orgChartType':
      case 'switchNotificationsToPush':
        return false;

      default:
        return true;
    }
  };

  const getDomainsTableHeader = () => {
    const _cellStyle = {
      'line-height': 1,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };

    return [
      {
        field: 'emailSuffix',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('domain.emailSuffix'),
        cellStyle: () => _cellStyle,
      },
      {
        field: 'selfInviteAllowed',
        flex: 1,
        editable: false,
        checkboxSelection: false,
        headerName: t('domain.type.title'),
        valueFormatter: (params: { value: SelfInviteAllowedEnum }) => getDomainTitleByType(params.value),
        cellStyle: () => _cellStyle,
      },
      {
        field: 'sortOrder',
        flex: 1,
        editable: false,
        checkboxSelection: false,
        headerName: t('domain.order'),
        cellStyle: () => _cellStyle,
      },
    ];
  };

  const getRegisterUserTableHeader = () => {
    return [
      {
        field: 'tag',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('appMenu.topics'),
      },
      {
        field: 'description',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('description.title'),
      },
    ];
  };

  const getRegisterUserTableRows = (): { tag: string; description: string }[] => {
    return [
      {
        tag: '{NetworkTitle}',
        description: t('emailFooter.tags.companyName'),
      },
      {
        tag: '{FirstName}',
        description: t('userPage.infoList.firstName'),
      },
      {
        tag: '{LastName}',
        description: t('userPage.infoList.lastName'),
      },
      {
        tag: '{PasswordInfo}',
        description: t('passwordRestore.password'),
      },
      {
        tag: '{LinkToNetwork}',
        description: t('emailFooter.tags.linkToActivation'),
      },
    ];
  };

  const getEmailFooterTableHeader = (): any[] => {
    return [
      {
        field: 'tag',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('appMenu.topics'),
      },
      {
        field: 'description',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('description.title'),
      },
    ];
  };

  const getEmailFooterTableRows = (): { tag: string; description: string }[] => {
    return [
      {
        tag: '{CompanyName}',
        description: t('emailFooter.tags.companyName'),
      },
      {
        tag: '{NetworkLogo}',
        description: t('design.logoImage'),
      },
      {
        tag: '{SupportEmail}',
        description: t('networkSettings.supportEmail.title'),
      },
    ];
  };

  const getInviteUserTableHeader = (): any[] => {
    return [
      {
        field: 'tag',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('appMenu.topics'),
      },
      {
        field: 'description',
        flex: 2,
        editable: false,
        checkboxSelection: false,
        headerName: t('description.title'),
      },
    ];
  };

  const getInviteUserTableRows = (): { tag: string; description: string }[] => {
    return [
      {
        tag: '{CompanyName}',
        description: t('emailFooter.tags.companyName'),
      },
      {
        tag: '{LinkToActivation}',
        description: t('emailFooter.tags.linkToActivation'),
      },
      {
        tag: '{FromName}',
        description: t('emailFooter.tags.fromName'),
      },
    ];
  };

  const inviteUser = async (data: SendInvitationsModel): Promise<{ rows: InviteUserModel[]; cols: any } | null> => {
    const { showSonnerToast } = useToasts();

    if (data.emails.length === 0) return null;
    const result = await adminStore.inviteUsers(data);
    if (result.length > 0) {
      const cols = [
        {
          field: 'email',
          flex: 2,
          editable: false,
          checkboxSelection: false,
          headerName: t('preferences.email.email'),
        },
        {
          field: 'status',
          flex: 2,
          editable: false,
          checkboxSelection: false,
          headerName: t('description.title'),
        },
      ];
      return { rows: result, cols };
    } else {
      showSonnerToast(t('invites.status.error'), false);
    }
    return null;
  };

  const registerUser = async (data: AdminRegisterModel): Promise<{ rows: InviteUserModel[]; cols: any } | null> => {
    const result = await adminStore.registerUsers(data);
    if (result.length > 0) {
      const cols = [
        {
          field: 'email',
          flex: 2,
          editable: false,
          checkboxSelection: false,
          headerName: t('preferences.email.email'),
        },
        {
          field: 'mainAlias',
          flex: 2,
          editable: false,
          checkboxSelection: false,
          headerName: t('userPage.infoList.mainAlias'),
        },
        {
          field: 'status',
          flex: 2,
          editable: false,
          checkboxSelection: false,
          headerName: t('description.title'),
        },
      ];
      return { rows: result, cols };
    }
    return null;
  };

  const getRegisterUserTabs = (): TabCategories<AdminInviteUserModeEnum>[] => {
    return [
      {
        value: AdminInviteUserModeEnum.Invite,
        active: true,
        icon: '',
        title: t('invites.title'),
      },
      {
        value: AdminInviteUserModeEnum.Register,
        active: true,
        icon: '',
        title: t('auth.signUp.title'),
      },
    ];
  };

  const saveTemplate = async (
    type: NotificationTemplatesTypeEnum,
    data: NotificationTemplateShortModel
  ): Promise<void> => {
    const { showSonnerToast } = useToasts();
    const result = await adminStore.saveTemplate(type, data);
    if (result) {
      showSonnerToast(t('wiki.templates.save.success'), true);
    } else {
      showSonnerToast(t('wiki.templates.save.error'), false);
    }
  };

  //#endregion

  return {
    getMenuItems,
    getAccordionGroup,
    saveSettings,
    getSettings,
    handleNetworkSetting,
    getDomainTitleByType,
    getHomePageTitleByType,
    getHomePageItems,
    getUserManagementMenuItems,
    userManagementMenuAction,
    getAdminPages,
    getUserManagementTableHeader,
    getDomainsTableHeader,
    getRegisterUserTableHeader,
    getRegisterUserTableRows,
    getEmailFooterTableHeader,
    getEmailFooterTableRows,
    inviteUser,
    registerUser,
    getInviteUserTableHeader,
    getInviteUserTableRows,
    getRegisterUserTabs,
    saveTemplate,
  };
}
