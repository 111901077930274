<template>
  <template v-if="viewType === DocsViewTypeEnum.Table && isMDWidth">
    <app-row
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      class="table-row"
      :class="{ active: isActive }"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <app-col :size="browser === DocBrowserTypeEnum.ByGroup ? '7' : '5'">
        <ion-item lines="none">
          <div v-if="status === null || status !== FileStatusEnum.Loading" slot="start" class="icon">
            <icons-provider
              class="file-icon"
              :name="nameForIconsProvider(documentType, file?.type ?? '')"
              :icon-props="{
                width: '30',
                height: '30',
              }"
            />
            <ion-icon v-if="isOfficial" class="avatar-icon small" :icon="star" />
          </div>
          <icons-provider
            v-else
            :icon-props="{ width: '30', height: '30' }"
            class="spinner"
            :name="AppIconsEnum.CircleAnim"
          />
          <div class="text">
            <ion-label :title="name">{{ name }}</ion-label>
          </div>
          <ion-buttons v-if="status && status === FileStatusEnum.Loading" slot="end">
            <ion-button @click.stop="stopDownload()">
              <ion-icon slot="icon-only" :icon="closeOutline" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </app-col>
      <app-col v-if="browser !== DocBrowserTypeEnum.ByGroup" size="3">
        <div class="text">
          <ion-label :title="group" v-html="group" />
        </div>
      </app-col>
      <app-col size="2">
        <div class="text">
          <ion-label v-if="editor" :title="editor" v-html="editor" />
          <ion-label v-else-if="author" :title="author" v-html="author" />
        </div>
      </app-col>
      <app-col size="2">
        <div class="text">
          <ion-label v-if="formattedEditedAt" :title="formattedEditedAt" v-html="formattedEditedAt" />
          <ion-label v-else-if="formattedCreatedAt" :title="formattedCreatedAt" v-html="formattedCreatedAt" />
        </div>
      </app-col>
    </app-row>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Table && !isMDWidth">
    <ion-item
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      mode="md"
      lines="none"
      button
      class="mobile"
      :class="{ active: isActive }"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <icons-provider
        v-if="status === FileStatusEnum.Loading"
        slot="start"
        :icon-props="{ width: '40', height: '40' }"
        :name="AppIconsEnum.CircleAnim"
      />
      <div v-else-if="!isImage" slot="start" class="icon">
        <icons-provider
          class="file-icon"
          :name="nameForIconsProvider(documentType, file?.type ?? '')"
          :icon-props="{
            width: '40',
            height: '40',
          }"
        />
        <ion-icon v-if="isOfficial" class="avatar-icon small" :icon="star" />
      </div>
      <div v-if="isImage" class="file-image">
        <media-image :name="name" :image="file.image" cover center />
      </div>
      <ion-label>
        {{ name }}
        <p v-if="formattedEditedAt">
          {{ formattedEditedAt + ' / ' + group }}
        </p>
        <p v-else-if="formattedCreatedAt">
          {{ formattedCreatedAt + ' / ' + group }}
        </p>
      </ion-label>
      <ion-buttons v-if="status && status === FileStatusEnum.Loading" slot="end">
        <ion-button @click.stop="stopDownload()">
          <ion-icon slot="icon-only" :icon="closeOutline" />
        </ion-button>
      </ion-buttons>
    </ion-item>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Grid">
    <div
      v-if="isFiltered || filterType === null"
      v-long-press="openMenu"
      class="grid-item"
      :class="{ active: isActive }"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
      @click="openFile()"
    >
      <div v-if="isImage" class="item-image">
        <media-image :name="name" :image="file.image" center />
      </div>
      <div v-if="!isImage" class="item-icon">
        <div class="icon">
          <icons-provider
            class="file-icon"
            :name="nameForIconsProvider(documentType, file?.type ?? '')"
            :icon-props="{
              width: '70',
              height: '70',
            }"
          />
          <ion-icon v-if="isOfficial" class="avatar-icon default" :icon="star" />
        </div>
      </div>

      <div class="item-label">
        <ion-label>{{ name }}</ion-label>
      </div>
    </div>
  </template>

  <template v-if="viewType === DocsViewTypeEnum.Item">
    <ion-item
      v-if="documentType !== DocumentTypeEnum.ExternalLink && documentType !== DocumentTypeEnum.Folder"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <icons-provider
        v-if="status === null || status !== FileStatusEnum.Loading"
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, file?.type ?? '')"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <icons-provider v-else slot="start" :icon-props="{ width: '40', height: '40' }" :name="AppIconsEnum.CircleAnim" />
      <ion-label>
        {{ name }}
        <p>{{ size }}</p>
      </ion-label>
      <ion-buttons v-if="status && status === FileStatusEnum.Loading" slot="end">
        <ion-button @click.stop="stopDownload()">
          <ion-icon slot="icon-only" :icon="closeOutline" />
        </ion-button>
      </ion-buttons>
    </ion-item>

    <ion-item
      v-if="documentType === DocumentTypeEnum.Folder"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, file?.type ?? '')"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label
        >{{ name }}
        <p>
          {{ author + ', ' + formatDateHelper(createdAt, 'year') }}<span v-if="group">{{ ', ' + group }}</span>
        </p></ion-label
      >
    </ion-item>

    <ion-item
      v-if="documentType === DocumentTypeEnum.ExternalLink"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="openFile()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="nameForIconsProvider(documentType, file?.type ?? '')"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label>
        {{ name }}
        <p>{{ linkUrl }}</p>
      </ion-label>
    </ion-item>
  </template>
</template>

<script lang="ts" setup>
import { IonIcon, IonItem, IonLabel, IonButtons, IonButton } from '@ionic/vue';
import { format } from 'date-fns';
import { closeOutline, star } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref } from 'vue';

import { AppRow, AppCol, MediaImage, IconsProvider } from '@/components';
import { LongPress } from '@/directives';
import {
  AppIconsEnum,
  DocBrowserTypeEnum,
  DocsFilterTypeEnum,
  DocsViewTypeEnum,
  DocumentTypeEnum,
  FileStatusEnum,
} from '@/enums';
import { formatDateHelper, isNativeMobile, useFilesHybrid } from '@/helpers';
import { useAppStore, useDocStore } from '@/store';
import type { DocModel, FileModel } from '@/types';

// Props
const props = defineProps({
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
  documentType: {
    type: String as PropType<DocumentTypeEnum>,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  mimeType: {
    type: String || null,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  author: {
    type: String,
    required: true,
  },
  editor: {
    type: String,
    required: true,
  },
  group: {
    type: String,
    required: true,
  },
  createdAt: {
    type: String,
    required: true,
  },
  editedAt: {
    type: String,
    required: true,
  },
  size: {
    type: String || null,
    default: null,
  },
  file: {
    type: (Object as PropType<FileModel>) || null,
    default: null,
  },
  icon: {
    type: String,
    required: true,
  },
  status: {
    type: (String as PropType<FileStatusEnum>) || null,
    default: null,
  },
  linkUrl: {
    type: String || null,
    default: null,
  },
  fileMenuIsDisabled: {
    type: Boolean,
    default: false,
  },
  isOfficial: {
    type: Boolean,
    default: false,
  },
});

// Helpers
const vLongPress = LongPress;

// Store
const appStore = useAppStore();
const docStore = useDocStore();

// Refs
const icons = ref(Object.values(AppIconsEnum));

// Computed
const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
const filterBy: ComputedRef<string | null> = computed(() => docStore.filterBy ?? null);
const filterType: ComputedRef<DocsFilterTypeEnum | null> = computed(() => docStore.filterType ?? null);
const browser: ComputedRef<DocBrowserTypeEnum | null> = computed(() => docStore.browserType);
const selectedDocs: ComputedRef<DocModel[]> = computed(() => docStore.selectedDocs);
const formattedCreatedAt: ComputedRef<string> = computed(() => formatDateHelper(props.createdAt, 'short'));
const formattedEditedAt: ComputedRef<string> = computed(() => formatDateHelper(props.editedAt, 'short'));

const isActive: ComputedRef<boolean> = computed(() =>
  selectedDocs.value.some((n) => n.data.id === props.id && props.documentType === n.documentType)
);

const isImage = computed(() => {
  return (
    props.file?.mimeType.startsWith('image') && !useFilesHybrid().unsupportedImageFormats.includes(props.file.type)
  );
});

const isFiltered: ComputedRef<boolean> = computed(() => {
  const filterByAuthor = filterBy.value === props.author && filterType.value === DocsFilterTypeEnum.Author;

  const filterByGroup = filterBy.value === props.group && filterType.value === DocsFilterTypeEnum.Group;

  const filterByDate =
    filterBy.value === format(new Date(props.editedAt || props.createdAt), 'dd.MM.yyyy') &&
    filterType.value === DocsFilterTypeEnum.Date;

  const filterByType = filterBy.value === props.documentType && filterType.value === DocsFilterTypeEnum.Type;

  return filterByAuthor || filterByGroup || filterByDate || filterByType;
});

// Methods
const openFile = async () => {
  emit('onItemClick');
};

const openMenu = async (ev: Event) => {
  if (!props.fileMenuIsDisabled) {
    emit('onMenuOpen', ev);
  }
};

const nameForIconsProvider = (documentType: DocumentTypeEnum, type: string) => {
  const map: Record<DocumentTypeEnum, AppIconsEnum | string> = Object.freeze({
    [DocumentTypeEnum.Wiki]: AppIconsEnum.Wiki,
    [DocumentTypeEnum.Folder]: AppIconsEnum.FolderYellowClose,
    [DocumentTypeEnum.ExternalLink]: AppIconsEnum.Symlink,
    [DocumentTypeEnum.File]: (type && icons.value.find((icon) => icon.includes(type))) || AppIconsEnum.Document,
    [DocumentTypeEnum.FileVersion]: AppIconsEnum.Document,
  });

  return map[documentType];
};

const stopDownload = async () => {
  emit('onDownloadStop');
};

// Emits
const emit = defineEmits(['onItemClick', 'onMenuOpen', 'onDownloadStop']);
</script>

<style scoped lang="scss">
.table-row {
  min-height: 48px;
  border-bottom: 1px solid var(--ion-color-custom-element-lighter);
  align-items: center;

  ion-item {
    --padding-start: 0;
    --background: transparent;
    --inner-padding-end: 0;
  }

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: var(--ion-color-custom-element-lighter);

    ion-item {
      --background: transparent;
    }
  }

  .app-col {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      ion-item {
        --padding-start: #{app-padding(lg)};
      }
    }

    &:not(:first-child) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

div.icon {
  position: relative;
}

div.text {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem !important;
}

.avatar-icon {
  content: '';
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  background-color: rgba(var(--ion-color-light-rgb-custom), 1);

  &.default {
    width: 18px;
    height: 18px;
    right: -10px;
    bottom: 6px;
    padding: 2px;
    border: 2px solid var(--ion-color-light-background-contrast);
  }
  &.small {
    width: 12px;
    height: 12px;
    right: -8px;
    bottom: 0;
    padding: 1px;
    border: 1px solid var(--ion-color-light-background-contrast);
  }
}

ion-icon {
  color: var(--ion-color-warning);
}

.table-row .icon {
  margin-right: 1rem;
  font-size: 30px;
}
.mobile .icon {
  margin-right: 1rem;
  font-size: 40px;
}
.custom .file-icon {
  margin-right: 1rem;
  font-size: 40px;
}
.table-row .spinner {
  margin-right: 1rem;
}

.grid-item {
  height: 100%;
  background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.active {
    background-color: var(--ion-color-custom-element-lighter);
  }
  .item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    .icon {
      font-size: 70px;
      color: var(--ion-color-medium);
    }
  }

  .item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    overflow: hidden;
  }
  .item-label {
    position: relative;
    height: 2rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2rem;
    padding-inline: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }
}

ion-item.custom {
  --background: transparent;
  width: 100%;
}
ion-item.mobile.active {
  --background: var(--ion-color-custom-element-lighter);
}
ion-item.mobile .file-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 1rem;
}

@include respond-to-min-width(2xl) {
  .table-row .app-col div.text {
    font-size: 1rem !important;
  }
  .grid-item {
    .item-label {
      font-size: 1rem;
    }
  }
}
</style>
