import type { OverlayEventDetail } from '@ionic/core';
import { alertController } from '@ionic/core';
import { lockClosed, star, shieldCheckmark, globeOutline } from 'ionicons/icons';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  DataViewMode,
  AllowExternalUsersToGroupEnum,
  AppCardsActionEnum,
  GroupInviteModeEnum,
  GroupsAccessEnum,
  GroupsTypeEnum,
  UserRoleEnum,
} from '@/enums';
import { useErrors, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useGroupsStore, useNetworkStore, useUserStore } from '@/store';
import type { AppActionButton, CompaniesListType2, GroupModel } from '@/types';

type IUseGroups = {
  getInviteModesToGroup(groupData: GroupModel): { name: string; value: GroupInviteModeEnum }[];
  getInviteModeName(mode: GroupInviteModeEnum): string;
  getMainGroupAction(
    groupData: GroupModel,
    mode: DataViewMode
  ): {
    left: AppActionButton;
    right: AppActionButton;
  };
  onJoinGroup(id: number): Promise<void>;
  onLeaveGroup(id: number): Promise<void>;
  onSendRequest(id: number): Promise<void>;
  getGroupStats(groupData: GroupModel): string;
  userIsJoined(groupData: GroupModel): boolean;
  getGroupInfoAccess(groupId: number): boolean;
  isGroupAdmin(groupId: number): boolean;
  getGroupType(groupId: number): string;
  getGroupIcon(groupId: number): string | null;
  getGroupIconByData(isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string;
  /**
   * Checks if input group id is in the list of groups for permanent pinning
   * @param {number} groupId - The group id to check
   * @returns {boolean} True if the group id is in the list of groups for permanent pinning, false otherwise
   *
   * @see src/store/menu.pinia.ts - permanentlyPinGroup
   * @see src/store/menu.pinia.ts - getGroupsPermanentlyPinned
   * @see src/components/Common/AppLeftMenu.vue - groupsPermanentlyPinned
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1836
   */
  checkForPermanentPin(groupId: number): boolean;
};

export const useGroups = (): IUseGroups => {
  const { showSonnerToast } = useToasts();

  const currentCompanyId: ComputedRef<string> = computed(() => useAppStore().companyRowId);

  const icons = {
    lock: lockClosed,
    star: star,
    shield: shieldCheckmark,
    globe: globeOutline,
  };

  const companiesList: ComputedRef<CompaniesListType2> = computed(() => {
    if (!import.meta.env.VITE_COMPANIES_LIST_WITH_PERMANENTLY_PINNED_GROUPS) {
      console.warn('The env variable VITE_COMPANIES_LIST_WITH_PERMANENTLY_PINNED_GROUPS is not defined');
      return {};
    }

    try {
      return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_PERMANENTLY_PINNED_GROUPS);
    } catch (error) {
      console.error('Error while parsing the VITE_COMPANIES_LIST_WITH_PERMANENTLY_PINNED_GROUPS env variable:', error);
      return {};
    }
  });

  // Возвращает способы приглашения в группу, зависят от ролей
  const getInviteModesToGroup = (groupData: GroupModel): { name: string; value: GroupInviteModeEnum }[] => {
    const userStore = useUserStore();
    const networkStore = useNetworkStore();

    const allowExternalUsersToGroup: AllowExternalUsersToGroupEnum =
      networkStore.settings?.allowExternalUsersToGroup ?? AllowExternalUsersToGroupEnum.Off;

    const currentUserId: number = userStore.current?.id ?? 0;

    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const currentUserIsAdmin: boolean = userStore.current ? currentUserRoleId >= UserRoleEnum.Administrator : false;

    const currentUserIsGroupAdmin: boolean = groupData.adminIds.includes(currentUserId);

    const modes = [];

    // Можно добавить в группу если юзер админ группы
    const caseOne = currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа сети и одновременно админа группы
    const caseTwo =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.NetworkAdmins &&
      currentUserIsGroupAdmin &&
      currentUserIsAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью админа группы
    const caseThree =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.GroupAdmins && currentUserIsGroupAdmin;

    // Можно пригласить внешних в группу - если разрешено в настройках сети приглашать внешних пользователей от лица с ролью от 30
    const caseFour =
      allowExternalUsersToGroup === AllowExternalUsersToGroupEnum.All && currentUserRoleId >= UserRoleEnum.User;

    if (caseOne) {
      modes.push(
        {
          name: getInviteModeName(GroupInviteModeEnum.AddById),
          value: GroupInviteModeEnum.AddById,
        },
        {
          name: getInviteModeName(GroupInviteModeEnum.AddByEmail),
          value: GroupInviteModeEnum.AddByEmail,
        }
      );
    }

    if (caseTwo || caseThree || caseFour) {
      modes.push({
        name: getInviteModeName(GroupInviteModeEnum.AddExternal),
        value: GroupInviteModeEnum.AddExternal,
      });
    }

    return modes;
  };

  // Возвращает строку способа приглашения в группу
  const getInviteModeName = (mode: GroupInviteModeEnum): string => {
    const { t } = useI18n();
    switch (mode) {
      case GroupInviteModeEnum.AddById:
        return t('invites.byNetwork');
      case GroupInviteModeEnum.AddByEmail:
        return t('invites.byEmail');
      case GroupInviteModeEnum.AddExternal:
        return t('invites.external');
    }
    return '';
  };

  const onJoinGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      showSonnerToast(t('groupPage.youAreJoined'), true);
    } else {
      handleError(true, undefined, t('groupPage.notJoined'));
    }
  };

  const onLeaveGroup = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(id);

    const result = group.stats.members <= 1 ? await leaveConfirm(group, true) : await leaveConfirm(group, false);
    if (result) {
      if (await groupStore.onLeave(group.id, group.stats.members <= 1)) {
        showSonnerToast(t('groupPage.youAreLeft', { group: group.title }), true);
      } else {
        handleError(true, undefined, t('groupPage.notLeft', { group: group.title }));
      }
    }
  };

  const onSendRequest = async (id: number): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const groupStore = useGroupsStore();

    if (await groupStore.onJoin(id)) {
      showSonnerToast(t('groupPage.requestIsSend'), true);
    } else {
      handleError(true, undefined, t('groupPage.requestNotSend'));
    }
  };

  const leaveConfirm = async (groupData: GroupModel, lastUser: boolean): Promise<boolean> => {
    const userStore = useUserStore();
    const { t } = useI18n();
    const currentUserRoleId: number = userStore.current?.roleId ?? 0;

    const alert = await alertController.create({
      message:
        lastUser && currentUserRoleId >= 30
          ? t('groupPage.leaveConfirmIsLast')
          : t('groupPage.leaveConfirm', { group: groupData.title }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          role: 'confirm',
        },
      ],
    });
    await alert.present();

    return alert.onDidDismiss().then(async (result: OverlayEventDetail<boolean>) => {
      return result.role === 'confirm';
    });
  };

  const userIsJoined = (groupData: GroupModel): boolean => {
    return groupData.accessType >= GroupsAccessEnum.Member;
  };

  const getMainGroupAction = (
    groupData: GroupModel,
    mode: DataViewMode
  ): { left: AppActionButton; right: AppActionButton } => {
    const { t } = useI18n();
    const userIsSendRequest = (): boolean => groupData.accessType === GroupsAccessEnum.SendRequest;
    const isJoined = userIsJoined(groupData);

    const defaultAction: AppActionButton = {
      title: '',
      type: 'main',
      action: AppCardsActionEnum.None,
      icon: '',
      showIcon: false,
      showTooltip: false,
      showTitle: false,
    };

    const actionData: { left: AppActionButton; right: AppActionButton } = {
      left: { ...defaultAction },
      right: { ...defaultAction, showTitle: true },
    };

    if (groupData.type !== GroupsTypeEnum.Public && !isJoined) {
      if (userIsSendRequest()) {
        actionData.right = {
          ...defaultAction,
          title: t('groupPage.requestIsSend'),
          type: 'secondary',
          action: AppCardsActionEnum.OpenRequested,
          icon: 'user-time',
          showTitle: true,
        };
      } else {
        actionData.right = {
          ...defaultAction,
          title: t('groupPage.sendRequest'),
          action: AppCardsActionEnum.GroupSendRequest,
          icon: 'user-check',
          showTitle: true,
        };
      }
    } else if (isJoined) {
      actionData.left = {
        ...defaultAction,
        title: t('groupPage.leave'),
        type: 'secondary',
        action: AppCardsActionEnum.GroupLeave,
        icon: 'user-delete-cross',
        showTitle: mode === DataViewMode.Grid,
      };
      actionData.right = {
        ...defaultAction,
        title: t('open'),
        action: AppCardsActionEnum.Open,
        showTitle: mode === DataViewMode.Grid,
      };
    } else {
      actionData.right = {
        ...defaultAction,
        title: t('groupPage.join'),
        action: AppCardsActionEnum.GroupJoin,
        icon: 'user-check',
        showTitle: true,
      };
    }

    return actionData;
  };

  const getGroupStats = (groupData: GroupModel): string => {
    const { t } = useI18n();
    const title = [];
    if (groupData.stats.members !== 0) {
      title.push(t('appLists.members', groupData.stats.members));
    }
    if (groupData.stats.messages !== 0) {
      title.push(t('appLists.posts', groupData.stats.messages));
    }
    return title.join(' &#8729; ');
  };

  const getGroupInfoAccess = (groupId: number) => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    return group.showInformation;
  };

  const isGroupAdmin = (groupId: number) => {
    const userStore = useUserStore();
    const groupStore = useGroupsStore();
    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const currentUserId = userStore.getId ?? null;

    const groupData = groupStore.getGroupById(Number(groupId));

    return (
      (groupData.accessType === GroupsAccessEnum.Admin && groupData.adminIds.includes(currentUserId)) ||
      currentUserRoleId >= UserRoleEnum.SuperAdministrator
    );
  };

  const getGroupType = (groupId: number): string => {
    const { t } = useI18n();
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);

    const groupTypes = [
      group.type === GroupsTypeEnum.PrivateVisible && t('groupPage.privateVisible'),
      group.type === GroupsTypeEnum.PrivateHidden && t('groupPage.privateHidden'),
      group.isMandant && t('groupPage.mandant'),
      group.isOfficial && t('groupPage.official'),
    ].filter(Boolean);

    return groupTypes.join(', ');
  };

  const getGroupIcon = (groupId: number): string | null => {
    const groupStore = useGroupsStore();
    const group = groupStore.getGroupById(groupId);
    if (group.isMandant) return icons.globe;
    if (group.isOfficial) return icons.star;
    if (group.type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (group.type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return null;
  };

  const getGroupIconByData = (isMandant: boolean, isOfficial: boolean, type: GroupsTypeEnum): string => {
    if (isMandant) return icons.globe;
    if (isOfficial) return icons.star;
    if (type === GroupsTypeEnum.PrivateHidden) return icons.shield;
    if (type === GroupsTypeEnum.PrivateVisible) return icons.lock;
    return '';
  };

  const checkForPermanentPin = (groupId: number): boolean => {
    /**
     * For testing purposes, I've added dev group id - `1833` to the list of groups for permanent pinning
     * Target group id - `8195` is only available in production
     * @todo - remove `1833` from the list of groups for permanent pinning
     * @see https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1836
     */

    const entries: [string, { id: string; groupId: number }][] = Object.entries(companiesList.value);
    const foundEntry = entries.find(([, value]) => {
      return value.id === currentCompanyId.value && value.groupId === groupId;
    });

    return !!foundEntry;
  };

  return {
    getInviteModesToGroup,
    getInviteModeName,
    getMainGroupAction,
    onJoinGroup,
    onLeaveGroup,
    onSendRequest,
    getGroupStats,
    userIsJoined,
    getGroupInfoAccess,
    isGroupAdmin,
    getGroupType,
    getGroupIcon,
    getGroupIconByData,
    checkForPermanentPin,
  };
};
