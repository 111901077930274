<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" :width="width" :height="height">
    <g>
      <polygon fill="#12376B" points="44,27 4,27 1,23 5,19 43,19 47,23 "></polygon>
      <path
        fill="#E6E6E6"
        d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z"
      ></path>
      <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z"></path>
      <path fill="#27549B" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z"></path>
      <path
        fill="#FFFFFF"
        d="M15.549,31.907c0,1.311-0.361,2.32-1.083,3.029C13.744,35.646,12.73,36,11.424,36H8.883v-7.853h2.718 c1.261,0,2.233,0.322,2.919,0.967S15.549,30.689,15.549,31.907z M13.346,31.982c0-0.72-0.142-1.253-0.427-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S13.346,32.752,13.346,31.982z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M24.303,32.062c0,1.322-0.324,2.326-0.972,3.014s-1.597,1.031-2.847,1.031c-1.232,0-2.176-0.346-2.834-1.037 c-0.656-0.69-0.984-1.697-0.984-3.018c0-1.308,0.326-2.306,0.98-2.995c0.652-0.689,1.603-1.034,2.849-1.034 c1.249,0,2.196,0.343,2.841,1.026S24.303,30.738,24.303,32.062z M18.9,32.062c0,1.519,0.527,2.277,1.584,2.277 c0.537,0,0.936-0.184,1.195-0.553s0.389-0.943,0.389-1.725c0-0.783-0.131-1.363-0.395-1.737c-0.264-0.374-0.656-0.562-1.179-0.562 C19.432,29.764,18.9,30.53,18.9,32.062z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M29.218,29.775c-0.505,0-0.899,0.206-1.182,0.62c-0.282,0.413-0.425,0.983-0.425,1.71 c0,1.512,0.575,2.268,1.725,2.268c0.348,0,0.684-0.049,1.01-0.146s0.653-0.213,0.982-0.349v1.793 c-0.654,0.291-1.396,0.436-2.223,0.436c-1.186,0-2.094-0.344-2.727-1.031c-0.632-0.688-0.947-1.682-0.947-2.98 c0-0.813,0.152-1.527,0.459-2.144s0.746-1.09,1.32-1.421c0.576-0.331,1.252-0.496,2.028-0.496c0.849,0,1.659,0.184,2.433,0.553 l-0.649,1.67c-0.29-0.136-0.58-0.25-0.87-0.344C29.862,29.821,29.551,29.775,29.218,29.775z"
      ></path>
      <path
        fill="#FFFFFF"
        d="M39.934,36h-2.455l-1.531-2.455L34.434,36h-2.401l2.604-4.018l-2.449-3.835h2.354l1.418,2.428l1.363-2.428 h2.423l-2.487,4.001L39.934,36z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: '100%',
    },
    height: {
      type: [Number, String],
      default: '100%',
    },
    strokewidth: {
      type: [Number, String],
      default: 1,
    },
    fill: {
      type: String,
      default: 'currentColor',
    },
    secondaryfill: { type: String, default: '' },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    _secondaryfill: function () {
      return this.secondaryfill || this.fill;
    },
  },
};
</script>
