import axios from '@/services/axios';
import type { ResponseErrorModel, ResponseSuccessModel, ResponseNotificationModel } from '@/types';

export class NotificationApiService {
  /*
  NOTE: Moved to src/services/account.service.ts as register and unRegister
  async register(
    params: DeviceModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/notifications/registerDevice', params);
  }
  async unRegister(
    params: DeviceModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/notifications/unRegisterDevice', params);
  }
  */
  async getNotifications(): Promise<ResponseNotificationModel | ResponseErrorModel> {
    return axios.get('/notifications/getLast?count=50');
  }
  async getUnreadNotifications(): Promise<ResponseNotificationModel | ResponseErrorModel> {
    return axios.get('/notifications/getUnread?count=50');
  }
  async markAsRead(ids: number[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/notifications/markAsRead', ids);
  }
  async markAsUnread(ids: number[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/notifications/markAsUnread', ids);
  }
  async markAllAsRead(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/notifications/markAllAsRead?lastNotificationId=${id}`);
  }
  async markAllAsUnread(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/notifications/markAllAsUnread?lastNotificationId=${id}`);
  }
}
