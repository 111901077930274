<template>
  <div class="link" @click.stop="goToLink(linkData.url)">
    <div class="link-image">
      <media-image v-if="linkData.urlImage" :url="linkData.urlImage" />
      <icons-provider
        v-else
        slot="icon-only"
        :icon-props="{ width: '24', height: '24', fill: 'var(--ion-color-medium)' }"
        :name="AppIconsEnum.Link"
      />
    </div>
    <div class="link-body">
      <div class="header mb-md">
        <div class="title">
          <ion-text class="fw-500">{{ linkData.title }}</ion-text>
          <ion-text class="url">{{ linkData.url }}</ion-text>
        </div>
        <div class="controls">
          <ion-button fill="clear" size="small" color="medium" @click.stop="goToLink(linkData.url)">
            <icons-provider
              slot="icon-only"
              :icon-props="{ width: '16', height: '16' }"
              :name="AppIconsEnum.ExternalLink"
            />
          </ion-button>
        </div>
      </div>
      <div class="description">
        <ion-text v-if="linkData.description">{{ linkData.description }}</ion-text>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonButton } from '@ionic/vue';
import type { PropType } from 'vue';

import { IconsProvider, MediaImage } from '@/components';
import { AppIconsEnum } from '@/enums';
import { openLink } from '@/helpers';
import type { PostAttachedLinkModel } from '@/types';

defineProps({
  linkData: {
    type: Object as PropType<PostAttachedLinkModel>,
    required: true,
  },
});

const goToLink = async (link: string) => {
  await openLink(link);
};
</script>
<style scoped lang="scss">
$link-size: calc(app-padding(xl) * 2);
.link {
  display: flex;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: app-radius(sm);
  gap: app-padding(md);
  padding: app-padding(md);
  align-items: center;
  font-size: 0.9rem;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &-image {
    width: $link-size;
    min-width: $link-size;
    max-width: $link-size;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-body {
    .header {
      display: flex;
      justify-content: space-between;
      gap: app-padding(sm);
      .title {
        display: flex;
        flex-direction: column;
        .url {
          font-size: 0.8rem;
          color: var(--ion-color-medium);
        }
      }
      .controls {
        ion-button {
          @include resetStyleFromIonicButton;
        }
      }
    }
    .description ion-text {
      font-size: 0.8rem;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      color: var(--ion-color-medium);
    }
  }
}
</style>
