<template>
  <div :class="['wrapper', { 'single-image': isSingleImage }]" @click.stop>
    <div v-if="withClose" class="close">
      <ion-icon :icon="icons.close" @click="$emit('onAction')"></ion-icon>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { closeOutline } from 'ionicons/icons';

defineProps({
  isSingleImage: {
    type: Boolean,
    default: false,
  },
  withClose: {
    type: Boolean,
    default: true,
  },
});
defineEmits(['onAction']);

const icons = { close: closeOutline };
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  height: 80px;
  width: 80px;
  background: rgb(164, 174, 184);
  background: linear-gradient(283deg, rgba(164, 174, 184, 1) 0%, rgba(183, 190, 202, 1) 100%);
  border-radius: app-radius(md);
  color: var(--ion-color-light);
  font-weight: 500;
  text-align: center;
  &.single-image {
    height: 6rem;
    width: 100%;
  }
}
.wrapper .close {
  position: absolute;
  top: -8px;
  right: -8px;
  font-size: 20px;
  color: var(--ion-color-light);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--ion-color-medium);
  cursor: pointer;
  z-index: 3;
}
.wrapper .close:hover {
  background-color: var(--ion-color-medium-shade);
}
</style>
