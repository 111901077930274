<template>
  <div class="custom-calendar">
    <div v-if="isLoading" class="custom-calendar-loader">
      <icons-provider
        :icon-props="{ width: '64', height: '64', strokewidth: 1.5 }"
        :name="AppIconsEnum.CircleAnim"
        fill="var(--ion-color-medium)"
      />
    </div>
    <vue-cal
      v-if="!calendarIsSmall"
      :id="`vuecal-${customUniqueId}`"
      v-model:active-view="activeView"
      v-tooltip.bottom="{
        content: $t('calendar.action'),
        theme: 'info-tooltip',
        delay: {
          show: 5000,
          hide: 0,
        },
        disabled: isAnyMobile,
      }"
      events-count-on-year-view
      events-on-month-view="true"
      :events="type === CalendarTypeEnum.CustomPage ? customPageEvents : events"
      :time-step="30"
      :disable-views="['years']"
      :on-event-click="openEvent"
      :drag-to-create-event="false"
      :dblclick-to-navigate="isNativeMobile || isWebMobile ? false : true"
      :locale="language"
      :editable-events="{ drag: true }"
      :transitions="false"
      cell-contextmenu
      resize-x
      :style="{
        height: type === CalendarTypeEnum.CustomPage ? '600px' : '100%',
      }"
      @cell-contextmenu="dayClickedEvent($event)"
      @cell-click="dayTouchedEvent($event)"
      @view-change="updatePeriod($event)"
      @event-drop="moveEvent($event)"
      @ready="calendarInit($event)"
    >
      <template #no-event>
        <div></div>
      </template>
      <template #event="{ event }">
        <calendar-event-template :event="event" />
      </template>
      <template #title="{ title }">
        <div class="custom-title" @click.stop>
          <ion-item
            button
            :disabled="isLoading"
            mode="md"
            lines="none"
            class="source-select"
            @click="openSourcePopover($event)"
          >
            {{ title + ' - ' + sourceText }}
            <ion-icon v-if="!isXSWidth" slot="end" size="small" :icon="icons.caret" />
          </ion-item>
        </div>
      </template>
    </vue-cal>

    <vue-cal
      v-else-if="calendarIsSmall"
      :id="`vuecal-${customUniqueId}`"
      v-model:active-view="activeView"
      v-tooltip.bottom="{
        content: $t('calendar.action'),
        theme: 'info-tooltip',
        delay: {
          show: 5000,
          hide: 0,
        },
        disabled: isAnyMobile,
      }"
      xsmall
      :events-count-on-year-view="false"
      hide-view-selector
      :locale="language"
      :disable-views="['years', 'year', 'week']"
      :time-step="30"
      :on-event-click="openEvent"
      :events="type === CalendarTypeEnum.CustomPage ? customPageEvents : events"
      cell-contextmenu
      :style="{ height: smallCalendarHeight + 'px' }"
      :class="[{ 'dash-counter': smallCalendarHeight <= 350 }]"
      @ready="calendarInit($event)"
      @view-change="updatePeriod($event)"
      @cell-contextmenu="dayClickedEvent($event)"
      @cell-click="dayTouchedEvent($event)"
    >
      <template #event="{ event }">
        <calendar-event-template :event="event" />
      </template>
    </vue-cal>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon, IonItem } from '@ionic/vue';
import { caretDownOutline } from 'ionicons/icons';
import { uniqueId } from 'lodash';
import type { ComputedRef, PropType } from 'vue';
import { ref, computed } from 'vue';
import VueCal from 'vue-cal';
import { useRouter } from 'vue-router';

import { CalendarEventTemplate, IconsProvider } from '@/components';
import type { WidgetCalendarPeriodEnum } from '@/enums';
import {
  CalendarCellActionEnum,
  CalendarTypeEnum,
  EventCalendarPeriodEnum,
  PostTypeActionEnum,
  CalendarViewModeEnum,
  ActionAccessEnum,
  CustomPageWidgetsPositionEnum,
  EventCalendarSourceEnum,
  GroupsFilterEnum,
  AppIconsEnum,
} from '@/enums';
import {
  DateHelper,
  componentPostCreateMobile,
  calendarCellMenu,
  calendarEventListPopover,
  isNativeMobile,
  isWebMobile,
  componentGroupSelect,
  calendarSourcePopover,
  isAnyMobile,
  calendarSourceSheet,
  calendarCellMenuSheet,
  useToasts,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useEventStore, useAppStore, usePostStore, useGroupsStore } from '@/store';
import type {
  EventModel,
  CalendarEventModel,
  RequestEventEditModel,
  RequestEventsByPeriodModel,
  GroupModel,
} from '@/types';

const props = defineProps({
  type: {
    type: String as PropType<CalendarTypeEnum>,
    required: true,
  },
  source: {
    type: Number as PropType<EventCalendarSourceEnum>,
    required: true,
  },
  period: {
    type: Number as PropType<EventCalendarPeriodEnum | WidgetCalendarPeriodEnum>,
    required: true,
  },
  groupId: {
    type: Number,
    default: 0,
  },
  widgetPosition: {
    type: String as PropType<CustomPageWidgetsPositionEnum>,
    default: CustomPageWidgetsPositionEnum.MainWidgets,
  },
  height: {
    type: null as unknown as PropType<number | null>,
    default: 300,
  },
});

const icons = {
  caret: caretDownOutline,
};

const router = useRouter();

const appStore = useAppStore();
const eventStore = useEventStore();
const postStore = usePostStore();
const groupStore = useGroupsStore();
const { t } = useI18n();
const { showSonnerToast } = useToasts();

const selectedSource = ref<EventCalendarSourceEnum>(props.source);
const selectedGroup = ref<GroupModel | undefined>(undefined);
const selectedEvent = ref<any>({});
const customPageEvents = ref<CalendarEventModel[]>([]);
const editLoading = ref<boolean>(false);
const customUniqueId = ref<any>(uniqueId());

const isXSWidth: ComputedRef<boolean> = computed(() => appStore.isXSWidth);

const events: ComputedRef<CalendarEventModel[]> = computed(() => {
  return getFormattedEvents(eventStore.getEvents);
});

const isLoading: ComputedRef<boolean> = computed(() => eventStore.isLoading || editLoading.value);

const calendarIsSmall: ComputedRef<boolean> = computed(() =>
  props.widgetPosition === CustomPageWidgetsPositionEnum.RightSidebarWidgets ||
  props.widgetPosition === CustomPageWidgetsPositionEnum.LeftSidebarWidgets
    ? true
    : false
);

const smallCalendarHeight: ComputedRef<number> = computed(() =>
  props.height ? (props.height < 300 ? 300 : props.height) : 300
);

const language: ComputedRef<any> = computed(() => appStore.locale);

const config: ComputedRef<any> = computed(() => {
  return {
    eventDialog: {
      isDisabled: true,
    },
    dayIntervals: { displayClickableInterval: true },
    defaultMode:
      props.period === EventCalendarPeriodEnum.Month ? CalendarViewModeEnum.Month : CalendarViewModeEnum.Week,
    isSilent: false,
    locale: language.value,
    showCurrentTime: true,
    style: {
      colorSchemes: {
        default: {
          color: '#ffffff',
          backgroundColor: 'var(--ion-color-primary)',
        },
      },
    },
  };
});

const sourceText: ComputedRef<string> = computed(() => {
  switch (selectedSource.value) {
    case EventCalendarSourceEnum.All:
      return t('feed.event.allEvents');
    case EventCalendarSourceEnum.My:
      return t('feed.event.myEvents');
    case EventCalendarSourceEnum.Groups:
      return selectedGroup.value !== undefined ? selectedGroup.value.title : t('feed.event.groupEvents');
  }
  return '';
});

const activeView = ref<any>(calendarIsSmall.value ? CalendarViewModeEnum.Month : config.value.defaultMode);

const getFormattedEvents = (eventsForFormat: EventModel[]) => {
  const calendarEvents = [] as CalendarEventModel[];
  if (eventsForFormat.length > 0) {
    eventsForFormat.forEach((element: EventModel) => {
      const event = {} as CalendarEventModel;
      event.id = element.id;
      if (element.eventData != null) {
        event.title = element.eventData.title;
        event.start = DateHelper.formatDateWithTimeReverse(element.eventData.datetime);
        // Если продолжительность равна 0 - то стандартно ставим 30 - для отображения в календаре в режиме дня/недели
        event.end = DateHelper.formatDateWithTimeAndAddMinutes(
          element.eventData.datetime,
          element.eventData.durationMinutes !== 0 ? element.eventData.durationMinutes : 30
        );
      }
      // need prop ':editable-events'
      event.draggable = element.access.includes(ActionAccessEnum.Edit);
      event.class = element.eventData?.isBlocker ? 'is-blocker' : '';
      calendarEvents.push(event);
    });
  }
  return calendarEvents;
};

const getEventsByPeriod = async (
  start: Date | string,
  end: Date | string,
  source?: EventCalendarSourceEnum,
  group?: GroupModel | undefined
) => {
  const periodData = {
    source: source !== undefined ? source : selectedSource.value,
    period: EventCalendarPeriodEnum.Period,
    start: DateHelper.getDateInUtc(new Date(start)),
    end: DateHelper.getDateInUtc(new Date(end)),
    groupId: group !== undefined ? group.id : selectedGroup.value?.id,
  } as RequestEventsByPeriodModel;
  eventStore.$patch({
    calendarPeriod: {
      start: periodData.start,
      end: periodData.end,
    },
  });

  const eventsData = await eventStore.eventsByPeriod(periodData);
  if (props.type === CalendarTypeEnum.CustomPage) {
    if (eventsData !== undefined) {
      customPageEvents.value = getFormattedEvents(eventsData);
    }
  }
};

const openEvent = async (eventData: any, e: MouseEvent | TouchEvent | any) => {
  if (e?.button !== 0 && !isNativeMobile) {
    return;
  }
  selectedEvent.value = eventData;
  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: eventData.id },
  });
};

// Смена дат
const updatePeriod = async (ev: any) => {
  await getEventsByPeriod(ev.startDate, ev.endDate, selectedSource.value, selectedGroup.value);

  // Scroll to closest time
  const currentDate = new Date();

  if (ev.view === CalendarViewModeEnum.Week) {
    return scrollToCurrentTime(currentDate);
  }

  if (ev.view === CalendarViewModeEnum.Day) {
    const eventsToScroll = props.type === CalendarTypeEnum.CustomPage ? customPageEvents.value : events.value;

    const eventToScroll = eventsToScroll.find((e) => new Date(e.start).getDay() === new Date(ev.startDate).getDay());

    return scrollToCurrentTime(eventToScroll ? new Date(eventToScroll.start) : currentDate);
  }
};

// Перемещение
const moveEvent = async (ev: any) => {
  editLoading.value = true;
  const zeroUTC = DateHelper.getUtcZero(ev.newDate);

  const result = await postStore.getPostFromId(ev.event.id);
  if (result) {
    const newEvent = {
      title: result?.eventData?.title,
      text: result?.bodyHtml,
      //TODO: return for #1548 date: DateHelper.formatDateToISO(new Date(zeroUTC)),
      date: DateHelper.formatDateWithTime(new Date(zeroUTC).toISOString()),
      duration: result?.eventData?.durationMinutes,
      place: result?.eventData?.location,
    } as RequestEventEditModel;

    if (await postStore.eventEdit(result.id, newEvent)) {
      showSonnerToast(t('feed.conversationPostMenu.edit.postEdited'), true);
      editLoading.value = false;
    } else {
      showSonnerToast(t('feed.conversationPostMenu.edit.postNotEdited'), false);
      editLoading.value = false;
    }
  }
};

// пкм по ячейке
const dayClickedEvent = async (ev: any) => {
  if (!isNativeMobile && !isWebMobile) {
    await openCellMenu(ev);
  } else {
    return;
  }
};

// тач по ячейке
const dayTouchedEvent = async (date: string) => {
  if (isNativeMobile || isWebMobile) {
    await openCellMenu({ e: undefined, date: date });
  } else {
    return;
  }
};

//Открыть меню в каленадре
const openCellMenu = async (ev: any) => {
  const result = isAnyMobile
    ? await calendarCellMenuSheet(activeView.value, calendarIsSmall.value)
    : await calendarCellMenu(ev.e, activeView.value, calendarIsSmall.value);
  switch (result.data) {
    case CalendarCellActionEnum.CreateEvent:
      {
        await componentPostCreateMobile(PostTypeActionEnum.CalendarEvent, ev.date.toISOString());
      }
      break;

    case CalendarCellActionEnum.ShowEvents:
      {
        const filteredEvents = events.value.filter((element) => {
          return DateHelper.isDateWithinInterval(
            new Date(ev.date),
            new Date(element.start),
            new Date(element.end),
            true
          );
        });
        if (filteredEvents.length > 0) {
          await calendarEventListPopover(ev.e, filteredEvents);
        }
      }
      break;

    case CalendarCellActionEnum.ToDate:
      {
        if (calendarIsSmall.value) {
          switch (activeView.value) {
            case CalendarViewModeEnum.Month:
              activeView.value = CalendarViewModeEnum.Day;
              break;
          }
        } else {
          switch (activeView.value) {
            case CalendarViewModeEnum.Year:
              activeView.value = CalendarViewModeEnum.Month;
              break;
            case CalendarViewModeEnum.Month:
              activeView.value = CalendarViewModeEnum.Week;
              break;
            case CalendarViewModeEnum.Week:
              activeView.value = CalendarViewModeEnum.Day;
              break;
          }
        }
      }
      break;

    case CalendarCellActionEnum.Back:
      {
        if (calendarIsSmall.value) {
          switch (activeView.value) {
            case CalendarViewModeEnum.Day:
              activeView.value = CalendarViewModeEnum.Month;
              break;
          }
        } else {
          switch (activeView.value) {
            case CalendarViewModeEnum.Month:
              activeView.value = CalendarViewModeEnum.Year;
              break;
            case CalendarViewModeEnum.Week:
              activeView.value = CalendarViewModeEnum.Month;
              break;
            case CalendarViewModeEnum.Day:
              activeView.value = CalendarViewModeEnum.Week;
              break;
          }
        }
      }
      break;

    default:
      return;
  }
};

const calendarInit = async (ev: any) => {
  const periodData = {} as RequestEventsByPeriodModel;
  periodData.period = EventCalendarPeriodEnum.Period;
  periodData.start = new Date(ev.startDate).toISOString();
  periodData.end = new Date(ev.endDate).toISOString();
  eventStore.$patch({
    calendarPeriod: {
      start: periodData.start,
      end: periodData.end,
    },
  });
  selectedGroup.value = props.groupId ? groupStore.getGroupById(props.groupId) : undefined;

  switch (props.type) {
    case CalendarTypeEnum.CalendarPage:
      {
        periodData.source = EventCalendarSourceEnum.All;
        await eventStore.eventsByPeriod(periodData);
      }
      break;

    case CalendarTypeEnum.CustomPage:
      {
        periodData.source = selectedSource.value;
        periodData.groupId = selectedGroup.value?.id;
        const eventsData = await eventStore.eventsByPeriod(periodData);
        if (eventsData !== undefined) {
          customPageEvents.value = getFormattedEvents(eventsData);
        }
      }
      break;
  }
};

const scrollToCurrentTime = (firstItemDate: Date) => {
  const calendar = document.querySelector(`#vuecal-${customUniqueId.value} .vuecal__bg`);
  const hours = firstItemDate.getHours() + firstItemDate.getMinutes() / 60;
  if (calendar !== null) {
    calendar.scrollTo({ top: hours * 80, behavior: 'smooth' });
  }
};

const openSourcePopover = async (ev: Event) => {
  const result = isAnyMobile ? await calendarSourceSheet() : await calendarSourcePopover(ev);
  if (result?.data) {
    changeSource(result?.data);
  }
};

const changeSource = async (source: EventCalendarSourceEnum) => {
  selectedSource.value = source;
  if (eventStore.calendarPeriod) {
    switch (source) {
      case EventCalendarSourceEnum.Groups:
        {
          const result = await componentGroupSelect(GroupsFilterEnum.All, false);
          if (result.data) {
            selectedGroup.value = result.data;
            getEventsByPeriod(
              eventStore.calendarPeriod.start,
              eventStore.calendarPeriod.end,
              EventCalendarSourceEnum.Groups,
              result.data
            );
          } else {
            selectedSource.value = EventCalendarSourceEnum.All;
          }
        }
        break;

      case EventCalendarSourceEnum.All:
        getEventsByPeriod(
          eventStore.calendarPeriod?.start,
          eventStore.calendarPeriod?.end,
          EventCalendarSourceEnum.All
        );
        break;

      case EventCalendarSourceEnum.My:
        getEventsByPeriod(eventStore.calendarPeriod?.start, eventStore.calendarPeriod?.end, EventCalendarSourceEnum.My);
        break;

      default:
        break;
    }
  }
};
</script>

<style scoped lang="scss">
.custom-calendar {
  position: relative;
  height: 100%;
}
.custom-calendar-loader {
  position: absolute;
  background: rgba(var(--ion-color-light-background-contrast-rgb), 0.4);
  width: 100%;
  height: 100%;
  border-radius: app-radius(md);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar .custom-title ion-item.source-select {
  max-width: 400px;
  font-size: 0.9rem;
  --background: var(--ion-color-light-background-contrast);
  --color: medium;
  white-space: nowrap;
}
</style>
