import type { DocModel, FileModel, UserModel, WikiModel, FolderModel } from '@/types';

/** Checks if the provided data is a FileModel */
export function isFileGuard(data: unknown): data is FileModel {
  return data !== null && typeof data === 'object' && typeof (data as FileModel).fileId === 'number';
}

/** Checks if the provided data is a WikiModel */
export function isWikiGuard(data: unknown): data is WikiModel {
  return data !== null && typeof data === 'object' && typeof (data as WikiModel).wikiContent === 'object';
}

/** Checks if the provided data is a FolderModel */
export function isFolderGuard(data: unknown): data is FolderModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    !isFileGuard(data) &&
    !isWikiGuard(data) &&
    typeof (data as FolderModel).id === 'number'
  );
}

/** Checks if the provided data is a DocModel */
export function isDocGuard(data: unknown): data is DocModel {
  return data !== null && typeof data === 'object' && typeof (data as DocModel).documentType === 'string';
}

/** Checks if the provided data is a UserModel */
export function isUserGuard(data: unknown): data is UserModel {
  return data !== null && typeof data === 'object' && typeof (data as UserModel).firstName === 'string';
}

/** the provided data is a DocModel[] */
export function isDocArrayGuard(data: unknown): data is DocModel[] {
  return Array.isArray(data) && data.length > 0 && data.every((item) => isDocGuard(item));
}

/** Checks if the provided data is a Blob */
export function isBlob(data: unknown): data is Blob {
  return (
    data instanceof Blob || (typeof Blob !== 'undefined' && Object.prototype.toString.call(data) === '[object Blob]')
  );
}
