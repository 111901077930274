import type { Component } from 'vue';
import { markRaw } from 'vue';

import DocIcon from '../icons/docx.vue';
import PptIcon from '../icons/pptx.vue';
import XlsxIcon from '../icons/xlsx.vue';

import { logErr, logWarn } from './logger';

import type { AppIconsEnum } from '@/enums';

export type IconComponent2 = {
  default: Component;
};

type IUseIcons = {
  /** Use it to get an icon Component by its name */
  getIcon: (iconName: AppIconsEnum) => Promise<Component | null>;
  /** Use it to get an array of all available cached icons. */
  getIconFileNames: () => AppIconsEnum[];
};

let instance: IUseIcons | null = null;

export function useIconsOptimized(): IUseIcons {
  if (instance) return instance;

  //#region Private variables
  const _docExtensions = new Set([
    'docx',
    'doc',
    'docm',
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]);
  const _excelExtensions = new Set(['xlsx', 'xls', 'xlsm', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet']);
  const _pptExtensions = new Set([
    'pptx',
    'ppt',
    'pptm',
    'vnd.openxmlformats-officedocument.presentationml.presentation',
  ]);

  /**
   * Glob import for icons
   *
   * @example { '../icons/home.vue': () => import('../icons/home.vue') }
   */
  const _iconsObject = import.meta.glob('../icons/**/*.vue') as Record<string, () => Promise<IconComponent2>>;

  /**
   * Map of icon paths to their import functions
   *
   * @example { '../icons/home.vue': () => import('../icons/home.vue') }
   */
  const _icons = new Map<string, () => Promise<IconComponent2>>();

  /**
   * Map of icon names to their respective paths
   *
   * @example { 'home': '../icons/home.vue' }
   */
  const _iconMap = new Map<string, string>();
  for (const path in _iconsObject) {
    if (Object.prototype.hasOwnProperty.call(_iconsObject, path)) {
      const importFunction = _iconsObject[path];
      _icons.set(path, importFunction);

      const fileName = path.split('/').pop()?.replace('.vue', '').toLowerCase();
      if (fileName) {
        _iconMap.set(fileName, path);
      } else {
        console.error('Error parsing icon file name:', path);
        _iconMap.set(path, path); // Fallback to path as key if fileName is undefined
      }
    }
  }

  /**
   * Cache for loaded icons
   *
   * @example { 'home': <HomeIcon /> }
   */
  const _loadedIcons = new Map<string, Component>();

  /**
   * Pre-import office icons using static imports
   *
   * @is { doc: DocIcon, xlsx: XlsxIcon, ppt: PptIcon }
   */
  const _officeIcons = {
    doc: markRaw(DocIcon),
    xlsx: markRaw(XlsxIcon),
    ppt: markRaw(PptIcon),
  };
  //#endregion

  //#region Private methods
  const _isDoc = (ext: string): boolean => _docExtensions.has(ext.toLowerCase());
  const _isExcel = (ext: string): boolean => _excelExtensions.has(ext.toLowerCase());
  const _isPpt = (ext: string): boolean => _pptExtensions.has(ext.toLowerCase());

  const _isOffice = (ext: string): boolean => _isDoc(ext) || _isExcel(ext) || _isPpt(ext);
  //#endregion

  //#region Public methods
  const getIcon = async (iconName: AppIconsEnum): Promise<Component | null> => {
    if (!iconName) {
      logWarn('No icon name provided.');
      return null;
    }

    const name = iconName.toLowerCase();

    if (_loadedIcons.has(name)) {
      return _loadedIcons.get(name)!;
    }

    let selectedIcon: Component | null = null;

    if (_isOffice(name)) {
      if (_isDoc(name)) {
        selectedIcon = _officeIcons.doc;
      } else if (_isExcel(name)) {
        selectedIcon = _officeIcons.xlsx;
      } else if (_isPpt(name)) {
        selectedIcon = _officeIcons.ppt;
      }
      _loadedIcons.set(name, selectedIcon!);
      return selectedIcon;
    }

    const iconPath = _iconMap.get(name);
    if (iconPath) {
      try {
        const iconImportFunction = _icons.get(iconPath);
        if (iconImportFunction) {
          const iconModule = await iconImportFunction();
          selectedIcon = markRaw(iconModule.default);
          _loadedIcons.set(name, selectedIcon);
        } else {
          logWarn(`No import function found for icon at path: ${iconPath}`);
          selectedIcon = null;
        }
      } catch (e) {
        logErr('Error loading icon:', e);
        selectedIcon = null;
      }
    } else {
      logWarn(`Icon file not found for the given name: ${iconName}`);
      selectedIcon = null;
    }

    return selectedIcon;
  };

  const getIconFileNames = (): AppIconsEnum[] => {
    return Array.from(_iconMap.keys()) as AppIconsEnum[];
  };
  //#endregion

  instance = {
    getIcon,
    getIconFileNames,
  };

  return instance;
}

export const resetInstanceOptimized = (): void => {
  instance = null;
};
