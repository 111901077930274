<template>
  <div
    v-if="withHeader"
    :class="['hoverable', { danger: DateHelper.isDateInPast(date) }]"
    @click.stop="openDateMenu($event)"
  >
    <ion-label>
      {{ formatDateHelper(date, 'year') }}
    </ion-label>
  </div>

  <div
    v-else
    v-tooltip.bottom="{
      content: $t('appPopoverMenu.taskManagementMenu.dueDate') + ': ' + formatDateHelper(date, 'year'),
      theme: 'info-tooltip',
      disabled: isAnyMobile,
    }"
    :class="['card-date', { danger: DateHelper.isDateInPast(date) }]"
  >
    <icons-provider
      :icon-props="{
        width: '14',
        height: '14',
        fill: DateHelper.isDateInPast(date) ? 'var(--ion-color-danger)' : 'var(--ion-color-medium)',
      }"
      :name="AppIconsEnum.Calendar"
      style="margin-right: calc(var(--app-sm-padding) / 2)"
    />
    <ion-label>{{ formatDateHelper(date, 'year') }}</ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum, TaskManagementTaskModalMenuItemActionEnum } from '@/enums';
import { DateHelper, formatDateHelper, isAnyMobile, useTaskManagement } from '@/helpers';
import type { TaskManagementTaskModel } from '@/types';

const props = defineProps({
  withHeader: {
    type: Boolean,
    required: true,
  },
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
});

const taskManagementHelper = useTaskManagement();

const date: ComputedRef<string> = computed(() => props.taskData.dateDue ?? '');

const openDateMenu = async (ev: Event) => {
  await taskManagementHelper.menuSelectionHandling(
    ev,
    TaskManagementTaskModalMenuItemActionEnum.Date,
    props.taskData,
    props.taskData.projectId
  );
};
</script>

<style scoped lang="scss">
.card-date {
  display: flex;
  align-items: center;
  ion-icon {
    margin-right: calc(app-padding(lg) / 4);
  }
}
.hoverable {
  ion-label {
    overflow: hidden;
    white-space: nowrap;
  }
  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
}
.danger {
  color: var(--ion-color-danger) !important;
}
</style>
