<template>
  <ion-app ref="pageRef">
    <app-toast />
    <keep-alive v-if="!isLoadingI18n">
      <component :is="layout" />
    </keep-alive>
    <app-loading v-else></app-loading>
  </ion-app>
</template>

<script lang="ts" setup>
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { Preferences } from '@capacitor/preferences';
import { SplashScreen } from '@capacitor/splash-screen';
import { IonApp, isPlatform, toastController, modalController, popoverController, alertController } from '@ionic/vue';
import { HubConnectionState } from '@microsoft/signalr';
import { useHeadSafe } from '@unhead/vue';
import { useIntervalFn, useWindowSize, watchDebounced } from '@vueuse/core';
import { informationCircle } from 'ionicons/icons';
import { isEqual } from 'lodash';
import type { ComponentPublicInstance, ShallowRef, ComputedRef } from 'vue';
import { computed, onMounted, onUnmounted, provide, ref, watch } from 'vue';
import type { ComposerTranslation } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

import { logInfo } from './helpers/logger';

import { AppToast, AppLoading } from '@/components';
import { AppAlertTypeEnum, AppLoadingTypeEnum, PostsModeEnum, RequirementsEnum } from '@/enums';
import {
  isNativeMobile,
  useWebSockets,
  useTheme,
  useUserFlow,
  updateApp,
  feedTypeHelper,
  isWebMobile,
  clearStorage,
  componentTaskManagementTaskModal,
  changeFavicon,
  useLayout,
  useLoading,
  useRequirements,
  useAiAssistant,
  useNotifications,
  useErrors,
  useToasts,
} from '@/helpers';
import { useI18n, loadLocaleMessages } from '@/i18n';
import { AppLayoutDefault, AppLayoutMenu } from '@/layouts';
import { ROUTES_NAME } from '@/router';
import { getAppVersion } from '@/services';
import {
  useAppStore,
  useFileStore,
  useMeetStore,
  useMessengerStore,
  useNetworkStore,
  usePostStore,
  useMenuStore,
  useBadgesStore,
  useAiAssistantStore,
} from '@/store';

//#region Stores
const appStore = useAppStore();
const fileStore = useFileStore();
const networkStore = useNetworkStore();
const meetStore = useMeetStore();
const messengerStore = useMessengerStore();
const postStore = usePostStore();
const menuStore = useMenuStore();
const badgeStore = useBadgesStore();
const aiAssistantStore = useAiAssistantStore();
//#endregion

//#region Router
const route = useRoute();
const router = useRouter();
//#endregion

//#region Helpers
const i18n = useI18n();
const aiAssistantHelper = useAiAssistant();
const { t } = useI18n();
const { width: innerWidth } = useWindowSize();
const { handleError } = useErrors();
const { showSonnerToast } = useToasts();
provide('i18nT', t as ComposerTranslation);
//#endregion

//#region Refs
const pageRef = ref<ComponentPublicInstance | null>(null);
const isLoadingI18n = ref(!isNativeMobile);
const keyboardIsShow = ref<boolean>(false);
//#endregion

//#region Computed
const keyboard: ComputedRef<boolean> = computed(() => keyboardIsShow.value);
const postsToMarkAsRead: ComputedRef<number[]> = computed(() => postStore.postsToMarkAsRead);
const postsMode: ComputedRef<PostsModeEnum | null> = computed(() => postStore.postsMode);
const badgesForFetching: ComputedRef<number[]> = computed(() => badgeStore.badgesRequests.forFetching);
const layout: ShallowRef = computed(() => {
  switch (route.meta.layout) {
    case 'auth':
      return AppLayoutDefault;
    default:
      return AppLayoutMenu;
  }
});
const isLoading: ComputedRef<boolean> = computed(
  () =>
    appStore.isLoading ||
    networkStore.isLoading ||
    messengerStore.isLoading ||
    (isPlatform('desktop') && menuStore.isLoading)
);
const localLanguage: ComputedRef<string> = computed(() => appStore.locale);
const isWaitingForCompleteLogin: ComputedRef<boolean> = computed(() => appStore.isWaitingForCompleteLogin);
const isSettingNetwork: ComputedRef<boolean> = computed(() => networkStore.settingNetwork);
//#endregion

//#region Handlers
const handleAuth = async (): Promise<boolean> => {
  logInfo('Checking if auth is required...'); //! DEBUG

  const routesWithNoAuth = [ROUTES_NAME.HOME, ROUTES_NAME.LOGIN, ROUTES_NAME.REGISTRATION, ROUTES_NAME.ACTIVATION];
  const currentRouteName = route.name as string;

  if (!routesWithNoAuth.includes(currentRouteName)) {
    logInfo('Current route requires auth. Trying to get a new token...'); //! DEBUG
    await appStore.token(true);

    if (!appStore.isAuth()) {
      logInfo('Failed to get a new token'); //! DEBUG
      return false;
    }

    logInfo('Successfully got a new token'); //! DEBUG
  }

  return true;
};

const handleWheelClick = (e: MouseEvent): void => {
  if (e.button === 1) {
    // logInfo('Middle button clicked.'); //! DEBUG
    appStore.$patch((state) => {
      state.isMiddleButtonClicked = true;
    });
    e.preventDefault();
    e.stopPropagation(); // Stop the event from propagating further
    const leftClickEvent = new MouseEvent('click', {
      bubbles: true,
      button: 0,
      buttons: 1,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      clientX: e.clientX,
      clientY: e.clientY,
      relatedTarget: e.relatedTarget,
    });

    if (!e.target) {
      console.error('Error during wheel click handling: no target found.', e);
      return;
    }

    e.target.dispatchEvent(leftClickEvent);
    // logInfo('Simulating left button click instead.'); //! DEBUG
  }
};

const handleClearStorage = async (version: string): Promise<void> => {
  await useLoading().create(i18n.t('loading'));

  try {
    await Promise.race([
      clearStorage(),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 30000)),
    ]);

    if (import.meta.env.DEV) {
      showSonnerToast(i18n.t('successfulAppVersionUpdate', { version }), true);
    }
  } catch (error: any) {
    handleError(true, error, i18n.t('failedAppVersionUpdate', { version }));
  } finally {
    await useLoading().dismiss();
  }
};

const handleVersionChange = async (): Promise<void> => {
  const cachedVersion = appStore.appVersion;
  const cachedBuild = appStore.appBuild;
  logInfo(`Current version: ${cachedVersion}`);
  logInfo(`Current build: ${cachedBuild}`);

  try {
    /** @note Electron platform handling */
    if (isPlatform('electron')) {
      const urlNewVersion = await getAppVersion();
      if (urlNewVersion.length > 0 && confirm(urlNewVersion)) {
        const toast = await toastController.create({
          duration: 0,
          message: `<a href="${urlNewVersion}" target="_blank">Download</a>`,
          icon: informationCircle,
        });
        await toast.present();
      }
      return;
    }

    /** @note Native mobile platform handling */
    if (isNativeMobile) {
      const localVersion = await Preferences.get({ key: 'appVersion' });
      if (!localVersion.value) {
        await Preferences.set({ key: 'appVersion', value: cachedVersion });
      } else if (localVersion.value !== cachedVersion) {
        logInfo('App version changed, resetting stores...');
        await router.push({ name: ROUTES_NAME.FEED });
        await handleClearStorage(cachedVersion);
        await Preferences.set({ key: 'appVersion', value: cachedVersion });
      }
      return;
    }

    /** @note Fetch meta.json for the latest version and build info */
    const response = await fetch('/meta.json', { cache: 'no-store' });
    if (!response.ok) {
      console.error('Failed to fetch meta.json:', response.statusText);
      return;
    }

    const { version: fetchedVersion, build: fetchedBuild } = await response.json();

    /** @note Web platform handling */
    if (fetchedVersion !== cachedVersion || fetchedBuild !== cachedBuild) {
      logInfo('App version or build changed, resetting stores and clearing caches...');

      /** @note Clear local storage with additional handling */
      await handleClearStorage(fetchedVersion);

      /** @note Clear caches */
      logInfo('Trying to clear caches...');
      if ('caches' in window) {
        const cacheNames = await caches.keys();
        logInfo(`Caches: ${cacheNames}`);

        for (const name of cacheNames) {
          logInfo(`Deleting cache: ${name}`);
          await caches.delete(name);
        }
        logInfo('All caches cleared');
      } else {
        logInfo('No caches found');
      }

      /** @note Reload the page to load new assets */
      window.location.reload();
    }

    /** @note Update app version and build in store */
    appStore.$patch((state) => {
      state.appVersion = fetchedVersion;
      state.appBuild = fetchedBuild;
    });
  } catch (error) {
    console.error('Error during version check and update handling:', error);
  }
};
//#endregion

//#region Init Functions
const initTheme = async (): Promise<void> => {
  const userTheme = appStore.getLocalTheme;
  useTheme().initTheme(userTheme);

  const currentSettings = networkStore.settings;
  const color = useTheme().getAppColor(currentSettings?.headBackgroundColor);
  await useTheme().setTheme(color);

  if (appStore.isAuth()) {
    const settings = await networkStore.getSettings();
    if (settings !== undefined) {
      if (!isEqual(currentSettings, settings)) {
        const color = useTheme().getAppColor(settings?.headBackgroundColor);
        await useTheme().setTheme(color);
      }
      changeFavicon();
    }
  }
};

/** @note Set the lang attribute of the html element */
useHeadSafe({
  htmlAttrs: {
    lang: localLanguage,
  },
});

const initLocales = async (): Promise<void> => {
  await loadLocaleMessages(i18n, localLanguage.value, undefined);

  if (appStore.isAuth()) {
    const companyResources = await appStore.getCompanyResources();
    const currentNetworkId = useNetworkStore().getNetwork.id;
    await loadLocaleMessages(i18n, localLanguage.value, companyResources || {}, currentNetworkId);
  }
};

const initNativeMobileInterface = async (): Promise<void> => {
  const localInterface = appStore.getLocalInterfaceSize;
  await useTheme().initNativeMobileInterface(localInterface);
};

const initNativeMobileKeyboardListeners = async (): Promise<void> => {
  if (Keyboard) {
    Keyboard?.addListener('keyboardDidShow', () => {
      keyboardIsShow.value = true;
      appStore.setKeyboardShown(true);
    });

    Keyboard?.addListener('keyboardDidHide', () => {
      keyboardIsShow.value = false;
      appStore.setKeyboardShown(false);
    });
  }
};
//#endregion

//#region Global Listeners & Watchers
App.addListener('appStateChange', async ({ isActive }) => {
  logInfo(`App state changed. Is active?' ${isActive}`);
  logInfo(`SignalR status: ${appStore.signalRConnectionStatus}`);
  try {
    if (!isActive) {
      if (isNativeMobile) await useWebSockets().stopWebSockets();
      return;
    }

    if (!appStore.isAuth()) {
      if (!(await handleAuth())) {
        throw new Error('Failed to get a new token during app mount');
      }
    }

    if (appStore.isAuth()) {
      if (appStore.signalRConnectionStatus == HubConnectionState.Disconnected) {
        await useWebSockets().startWebSockets();
      }

      if (isNativeMobile) {
        await useNotifications().setBadgeCount();
        await updateApp();
      }

      await messengerStore.updateUnreadCounter();
    }
  } catch (e) {
    console.error(e);
  }
});

watch(keyboard, () => {
  appStore.$patch({
    appBottomMenu: (isWebMobile || isNativeMobile) && !keyboardIsShow.value,
  });
});

/** @note Watching the innerWidth, if the innerWidth changes, then we need to update the innerWidth in the layout helper */
watch(
  innerWidth,
  () => {
    useLayout().updateInnerWidth(innerWidth.value);
  },
  { immediate: true }
);

/** @note Watching the route, if the route changes, then we need to close all the windows */
watch(route, async () => {
  modalController.getTop().then((modal) => {
    return modal && modal?.id !== 'groupManage' ? modalController.dismiss() : null;
  });
  popoverController.getTop().then((popover) => {
    return popover ? popoverController.dismiss() : null;
  });
  alertController.getTop().then((alert) => {
    return alert && alert?.id !== AppAlertTypeEnum.UpdateApp ? alertController.dismiss() : null;
  });
});

/** @note Watching the loading state, if the loading state changes, then we need to show or hide the loading */
watch(isLoading, async () => {
  if (isWaitingForCompleteLogin.value) return;

  if (!isLoading.value) {
    await useLoading().dismiss(AppLoadingTypeEnum.OtherLoading);
  } else {
    if (route.name === ROUTES_NAME.LOGIN) {
      await useLoading().create(i18n.t('loading'), AppLoadingTypeEnum.OtherLoading);
    }
  }
});

/** @note Watching the network settings, if not login/registration/activation page, then we need to dismiss (false) / create (true) the loader */
watch(isSettingNetwork, async () => {
  if (
    route.name === ROUTES_NAME.LOGIN ||
    route.name === ROUTES_NAME.REGISTRATION ||
    route.name === ROUTES_NAME.ACTIVATION
  )
    return;

  if (!isSettingNetwork.value) {
    await useLoading().dismiss(AppLoadingTypeEnum.NetworkSetting);
  } else {
    await useLoading().create(i18n.t('network.loading'), AppLoadingTypeEnum.NetworkSetting);
  }
});

/** @note Watching the change of the posts mode, if the mode changes, then we need to update the feed */
watch(postsMode, async (currentMode, lastMode) => {
  if (lastMode !== currentMode) {
    if (route.name === ROUTES_NAME.FEED) {
      if (postsMode.value === PostsModeEnum.Feed) {
        //Commented out the Bitoobit changes
        //if (posts.value?.length === 0) await feedTypeHelper(route.name);
        await feedTypeHelper(route.name);
      }
    }
  }
});

/** @note Watching the query parameters, if the showTaskId and projectId parameters are present, then we need to open the task modal */
const queryParams = computed(() => route.query);
watch(
  queryParams,
  async (newValue) => {
    const { showTaskId, projectId } = newValue;
    if (showTaskId && projectId) {
      const modal = await componentTaskManagementTaskModal(Number(showTaskId), Number(projectId));

      await modal.onDidDismiss();

      await router.replace({ query: undefined });
    }
  },
  { deep: true, immediate: true }
);

/** @note Watching the array of posts to mark as read, if the array is not empty, then we need to send a request to the server to mark the posts as read */
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0 && appStore.isAuth()) {
      await postStore.markAsRead(false, postsToMarkAsRead.value);
    }
  },
  { debounce: 5000, maxWait: 120000 }
);

/** @note Watching the array of posts to mark as read, if the array is not empty, then we need to mark the posts as read locally */
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0) {
      postStore.markAsReadLocally(postsToMarkAsRead.value);
    }
  },
  { debounce: 1500, maxWait: 2000 }
);

/** @note Watching the array of badges to be requested from the server, if the array is not empty, then we need to send a request to the server to get the badges */
watchDebounced(
  badgesForFetching,
  async () => {
    if (badgesForFetching.value.length > 0) {
      badgeStore.$patch((state) => {
        state.badgesRequests.activeRequests = [...state.badgesRequests.activeRequests, ...badgesForFetching.value];
      });

      await badgeStore.badgesByIds(badgesForFetching.value);

      badgeStore.$patch((state) => {
        state.badgesRequests.forFetching = [];
        state.badgesRequests.activeRequests = [];
      });
    }
  },
  { debounce: 2000, maxWait: 3000 }
);
//#endregion

//#region Global Timers
useIntervalFn(async () => {
  await useRequirements().check(RequirementsEnum.All);
}, 900000); /** @note once every 15 minutest */
//#endregion

//#region App Mount Functions (Lifecycle)
const authorizedMount = async (): Promise<void> => {
  try {
    const notificationsHelper = useNotifications();
    const userFlowHelper = useUserFlow();

    if (pageRef.value !== null && route.path === '/') {
      await notificationsHelper.initPushNotifications();
      await userFlowHelper.setupApp();
    } else {
      await Promise.all([
        messengerStore.updateUnreadCounter(),
        useWebSockets().initWebSockets(appStore.getWebSocketModel),
      ]);
    }

    await notificationsHelper.initLocalNotifications();
    await useRequirements().check(RequirementsEnum.All);

    userFlowHelper.clearCustomMenuTimer();
    userFlowHelper.setCustomMenuTimer();
  } catch (e) {
    console.error('Error on handleAuthorizedMount', e);
  }
};

const webMount = async (): Promise<void> => {
  try {
    messengerStore.$patch((state) => {
      state.isActive = true;
    });

    isLoadingI18n.value = false;
  } catch (e) {
    console.error('Error on handleWebMount', e);
  }
};

const nativeMobileMount = async (): Promise<void> => {
  try {
    await fileStore.init();
    await initNativeMobileInterface();
    await initNativeMobileKeyboardListeners();

    messengerStore.$patch((state) => {
      state.isActive = true;
    });

    await SplashScreen.hide();
    await updateApp();
  } catch (e) {
    console.error('Error on handleNativeMobileMount', e);
  }
};

/** @todo const electronMount = async (): Promise<void> => {}; */

const mount = async (): Promise<void> => {
  try {
    useLayout().updateInnerWidth(innerWidth.value);
    await initLocales();
    await initTheme();

    if (isNativeMobile) {
      await nativeMobileMount();
    } else {
      await webMount();
    }

    /** @note If user is NOT authenticated AND current route needs auth => try to reauthenticate */
    if (!appStore.isAuth()) {
      if (!(await handleAuth())) {
        throw new Error('Failed to reauthenticate during app mount');
      }
    }

    if (appStore.isAuth()) {
      await authorizedMount();
    }

    if (isWebMobile || isNativeMobile) {
      appStore.$patch((state) => {
        state.appBottomMenu = !keyboardIsShow.value;
      });
    }

    await handleVersionChange();
  } catch (e) {
    console.error('Error on handleMount', e);
  }
};

const unmount = async (): Promise<void> => {
  try {
    const currentAssistant = aiAssistantStore.assistant;
    if (currentAssistant) {
      await aiAssistantHelper.deleteAssistant(currentAssistant);
    }

    await useWebSockets().stopWebSockets();

    App.removeAllListeners();
    window.removeEventListener('mouseup', handleWheelClick);
  } catch (e) {
    console.error('Error on handleUnmount', e);
  }
};

meetStore.$reset();

let updateCheckInterval: number | undefined;
onMounted(async () => {
  try {
    if (!isNativeMobile) {
      window.addEventListener('mouseup', handleWheelClick);
      updateCheckInterval = window.setInterval(handleVersionChange, 60000);
    }

    await mount();
  } catch (e) {
    console.error('Error on onMountedApp', e);
  }
});

onUnmounted(async () => {
  try {
    if (!isNativeMobile && updateCheckInterval) {
      window.clearInterval(updateCheckInterval);
    }

    await unmount();
  } catch (e) {
    console.error('Error on onUnmountedApp', e);
  }
});
//#endregion
</script>
