import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseTopicModel,
  ResponseTopicsModel,
  RequestCreateTopicModel,
  TopicColorModel,
} from '@/types';

export class TopicApiService {
  async topicsAutocomplete(searchText: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.post(`/tags/autocomplete`, { searchText: searchText });
  }

  async search(text: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/tags/all?search=${text}`);
  }

  async getTopicsAll(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/tags/all`);
  }

  async getTopicsFollowing(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/tags/following`);
  }

  async getTopicsRecommended(): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(`/tags/recommended`);
  }

  async getTopicByText(text: string): Promise<ResponseTopicModel | ResponseErrorModel> {
    return axios.get(`/tags/byText?text=${text}`);
  }

  async loadMore(url: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.get(url);
  }

  async deleteTopic(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/tags/delete?id=${id}`);
  }

  async editTopic(
    id: number,
    title: string,
    color: TopicColorModel | null,
    description: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/tags/${id}`, { title, color, description });
  }

  async createTopic(topicData: RequestCreateTopicModel): Promise<ResponseTopicModel | ResponseErrorModel> {
    return axios.post(`/tags/create`, topicData);
  }

  async autocomplete(searchText: string): Promise<ResponseTopicsModel | ResponseErrorModel> {
    return axios.post('/tags/autocomplete/', { searchText });
  }
}
