import { clamp, random, shuffle } from 'lodash';

import { useAppStore } from '@/store';

export class ColorGenerator {
  static COLORS: number[][] = [
    [236, 255, 232],
    [224, 241, 235],
    [212, 226, 237],
    [199, 212, 240],
    [187, 197, 242],
    [204, 232, 219],
    [193, 212, 227],
    [190, 180, 214],
    [250, 218, 226],
    [248, 179, 202],
    [204, 151, 193],
  ];

  public static getColor(str: string): string {
    const colors = useAppStore().appColors;

    return colors[Math.abs(ColorGenerator.toNumber(str)) % colors.length];
  }

  public static generateRandomShades(): void {
    const appStore = useAppStore();
    const colors = useAppStore().appColors;

    if (colors.length) return;

    const shades: string[] = [];

    this.COLORS.forEach((element) => {
      for (let i = 0; i < 10; i++) {
        const r = clamp(random(element[0] - 25, element[0] + 15), 0, 255);
        const g = clamp(random(element[1] - 25, element[1] + 15), 0, 255);
        const b = clamp(random(element[2] - 25, element[2] + 15), 0, 255);
        shades.push(`rgb(${r},${g},${b})`);
      }
    });

    appStore.$patch({
      appColors: shuffle(shades),
    });
  }

  private static toNumber(str: string | undefined): number {
    if (str === undefined) {
      return 0;
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i); // Returns an integer between 0 and 65535 representing the UTF-16 code unit at the given index.
      hash = (hash << 5) - hash + char; // Creating a hash with Bitwise Left Shift (<<) - hash + char;
      hash |= 0; // Ensuring the hash is a 32-bit integer (bitwise OR with 0).
    }

    return hash;
  }
}
