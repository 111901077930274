<template>
  <div class="topics">
    <ion-badge v-for="topic in post.topics.data" :key="topic.label" mode="md" @click.stop="openTag(topic.label)">{{
      topic.label
    }}</ion-badge>
  </div>
</template>

<script lang="ts" setup>
import { IonBadge } from '@ionic/vue';
import type { PropType } from 'vue';
import { useRouter } from 'vue-router';

import { useLoading } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useTopicStore } from '@/store';
import type { PostModel } from '@/types';

const { t } = useI18n();
const topicStore = useTopicStore();

defineProps({
  post: {
    type: Object as PropType<PostModel>,
    required: true,
  },
});
const router = useRouter();

const openTag = async (text: string) => {
  let id = null as number | null;

  const topic = topicStore.getTopicByText(text);
  if (topic !== null) {
    id = topic.id;
  } else {
    await useLoading().create(t('loading'));
    const topicData = await topicStore.topicByText(text);
    if (topicData !== undefined) id = topicData.id;
    await useLoading().dismiss();
  }
  if (id !== null)
    await router.push({
      name: ROUTES_NAME.TOPIC_BY_ID,
      params: { id: id },
    });
};
</script>
<style scoped lang="scss">
.topics {
  margin-top: 1rem;
}
ion-badge {
  border: 1px solid var(--ion-color-custom-element-darker);
  background: unset;
  color: var(--ion-color-dark);
}
ion-badge:not(:last-child) {
  margin-right: 0.5rem;
}
ion-badge:hover {
  cursor: pointer;
  opacity: 0.7;
}
</style>
