import { cloneDeep, orderBy, sumBy, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { ChainTypeEnum, MessageDeliveryStatusEnum, MessageTypeEnum } from '@/enums';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { useChatStore } from '@/store';
import type {
  ErrorMessageModel,
  LastMessageModel,
  MessageChainModel,
  ResponseChainsModel,
  ResponseErrorModel,
  ResponseUnreadModel,
  ResponseSuccessModel,
  ResponseChainModel,
  ResponseMessagesModel,
} from '@/types';

interface MessengerState extends EntityState<MessageChainModel> {
  isInit: boolean;
  isActive: boolean;
  hasLoadMoreUrl: string | null;
  disableLoadMore: boolean;
  searchText: string;
}

export const useMessengerStore = defineStore({
  id: 'messenger',
  state: (): MessengerState => ({
    data: [],
    errors: [],
    isLoading: false,
    isInit: false,
    isActive: true,
    hasLoadMoreUrl: null,
    disableLoadMore: false,
    searchText: '',
  }),
  getters: {
    /*
     * Simple getters - no arguments
     */
    getUnreadMessagesCount: (state): number => sumBy(state.data, (n) => n.unreadMessagesCount),
    getLoadMoreUrl: (state): string => {
      return state.hasLoadMoreUrl || '';
    },
    getArchiveChatsTitles: (state): string => {
      const archiveChats = state.data.filter((c) => c.chainType === ChainTypeEnum.Archive);
      return archiveChats
        .slice(0, 3)
        .map((c) => c.title)
        .join(', ')
        .concat(archiveChats.length > 3 ? '...' : '');
    },
    getLastMessageId: (state): number | null => {
      const maxId = Math.max(...state.data.map((n) => n.lastMessage.id));
      return isFinite(maxId) ? maxId : null;
    },
    isEmptyList: (state): boolean => {
      return state.data.length === 0;
    },
    /*
     * Complex getters - with arguments
     */
    getBySearchText:
      (state) =>
      (text: string): MessageChainModel[] => {
        text = text.toLowerCase().trim();
        return text.length > 0
          ? state.data.filter((n: MessageChainModel) => n.title.toLowerCase().includes(text))
          : state.data;
      },
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getByType:
      (state) =>
      (type: ChainTypeEnum): MessageChainModel[] =>
        orderBy(
          state.data.filter((c: MessageChainModel) => c.chainType === type),
          ['lastMessage.createdAt'],
          ['desc']
        ),
    getChainById:
      (state) =>
      (chainId: number): MessageChainModel | undefined => {
        return state.data.find((n) => n.chainId === chainId);
      },
  },
  actions: {
    /*
     * Simple actions - setters | sync
     */
    setDisableLoadMore(state: boolean) {
      this.disableLoadMore = state;
    },
    resetUnreadMessagesCount(chainId: number) {
      const index = this.data.findIndex((n) => n.chainId === chainId);
      if (this.data[index]) {
        this.data[index].unreadMessagesCount = 0;
      }
    },
    updateMessengerData(a: MessageChainModel[], b: MessageChainModel[]) {
      // Merge and sort the new data with the existing data
      this.data = sortByLastMessageDate(mergeById(a, b));
    },

    /*
     * Complex actions - async
     */
    async searchByText(text: string) {
      this.isLoading = true;

      // Get the filtered results based on the type and search text
      let results = this.getBySearchText(text);

      // If no results are found and there are more pages to load, trigger loading more data
      if (results.length === 0 && this.hasLoadMoreUrl) {
        results = await this.progressiveSearch(text);
      }

      this.isLoading = false;
      return results;
    },
    async progressiveSearch(text: string): Promise<MessageChainModel[]> {
      let allResults: MessageChainModel[] = [];

      // Load more data while the loadMoreUrl is available
      while (this.hasLoadMoreUrl) {
        const success = await this.loadMore(this.hasLoadMoreUrl);

        if (!success) {
          break; // Stop if loadMore fails
        }

        // Re-check results after loading more data
        const newResults = this.getBySearchText(text);

        if (newResults.length > 0) {
          allResults = newResults;
          break; // Stop loading more if results are found
        }
      }

      return allResults;
    },
    async getChains(): Promise<void> {
      this.$reset();
      this.isLoading = true;
      const response = await $api.messenger.getChains();

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = model.data;

        this.data = sortByLastMessageDate(this.data);

        this.hasLoadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async loadMore(url: string): Promise<boolean> {
      const response = await $api.messenger.getByUrl(url);

      if (response.statusCode === 200) {
        const model = response as ResponseChainsModel;

        this.data = sortByLastMessageDate(mergeById(this.data, model.data));
        this.hasLoadMoreUrl = model.loadMoreUrl;
        this.errors = [];

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async chainById(chainId: number): Promise<void> {
      const response = await $api.messenger.getChainById(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseChainModel;

        const chain = model.data;
        const index = this.data.findIndex((n) => n.chainId === chain.chainId);
        if (~index) {
          this.data[index] = cloneDeep(chain);
        } else {
          this.data.unshift(chain);
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return;
    },
    async chainByUserId(userId: number): Promise<void> {
      const response = await $api.messenger.getByUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;
        const chainId = model.data[0].chainId;
        await this.chainById(chainId);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },
    async updateUnreadCounter() {
      const chatStore = useChatStore();

      const response = await $api.messenger.getUnread(this.getLastMessageId);
      if (response.statusCode === 200) {
        const model = response as ResponseUnreadModel;

        model.data.forEach((m) => {
          const index = this.data.findIndex(({ chainId }) => chainId === m.chainId);
          if (~index) {
            this.updateLastMessage(m, m.status !== MessageDeliveryStatusEnum.Delivered);
          }
        });

        // If user have an active chain and last message is not the same as in the store
        if (chatStore.chain) {
          const currentChainId = chatStore.chain.chainId;
          if (!currentChainId) return;

          const currentChain = chatStore.chats[currentChainId];
          if (!currentChain) return;

          const currentChainMessages = currentChain.data;
          const lastMessageInCurrentChain = currentChainMessages.at(-1);

          if (lastMessageInCurrentChain?.id !== this.getChainById(currentChainId)?.lastMessage.id) {
            await chatStore.updateChain(chatStore.chain, true);
          }
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    updateLastMessage(message: LastMessageModel, isNew: boolean) {
      const index = this.data.findIndex(({ chainId }) => chainId === message.chainId);

      if (~index) {
        this.data[index].lastMessage = message;
        const chatStore = useChatStore();

        chatStore.setUpdate();
        if (isNew && message.type !== MessageTypeEnum.Init && message.type !== MessageTypeEnum.CreateGroup) {
          this.data[index].unreadMessagesCount++;
        }
      }

      this.errors = [];
    },
    async changeChainType(id: number, type: ChainTypeEnum): Promise<boolean> {
      const chatStore = useChatStore();
      let response: ResponseSuccessModel | ResponseErrorModel | null = null;
      if (type === ChainTypeEnum.Active) {
        response = await $api.messenger.moveToActive(id);
      }
      if (type === ChainTypeEnum.Archive) {
        response = await $api.messenger.moveToArchive(id);
      }

      if (response?.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === id);
        this.data[index].chainType = type;
        if (chatStore.chain?.chainId === id) {
          chatStore.chain.chainType = type;
        }
        return true;
      }

      if (response?.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async muteChainChange(chainId: number, mute: boolean): Promise<boolean> {
      const chatStore = useChatStore();
      const response = await $api.messenger.muteChainChange(chainId, mute);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].muted = mute;
        if (chatStore.chain?.chainId === chainId) {
          chatStore.chain.muted = mute;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.deleteChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data.splice(index, 1);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async exitChain(chainId: number): Promise<boolean> {
      const response = await $api.messenger.exitChain(chainId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].isInChain = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async renameChain(chainId: number, chainTitle: string): Promise<boolean> {
      const response = await $api.messenger.renameChain(chainId, chainTitle);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.chainId === chainId);
        this.data[index].title = chainTitle;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
  },
  persist: true,
});

/**
 * Merges two arrays of MessageChainModel by chainId.
 * Gives priority to the objects from the second array (new data).
 *
 * @param a The first array of MessageChainModel (existing data)
 * @param b The second array of MessageChainModel (new data)
 * @returns A merged array of MessageChainModel
 */
const mergeById = (a: MessageChainModel[], b: MessageChainModel[]) => {
  // Merge two arrays by chainId, prioritizing new data (b)
  return unionBy(b, a, 'chainId');
};

/**
 * Sorts an array of MessageChainModel by the lastMessage's createdAt date.
 * The most recent messages will appear first.
 *
 * @param chains The array of MessageChainModel to be sorted
 * @returns A sorted array by lastMessage.createdAt in descending order
 */
const sortByLastMessageDate = (chains: MessageChainModel[]) => {
  return chains.sort((chainA, chainB) => {
    const dateA = new Date(chainA.lastMessage.createdAt).getTime();
    const dateB = new Date(chainB.lastMessage.createdAt).getTime();
    return dateB - dateA; // Sort in descending order
  });
};
