<template>
  <div class="file-block">
    <ion-icon v-if="size !== 0" class="icon" :icon="icons.document" />
    <icons-provider
      v-else
      class="file-icon"
      :name="type"
      :icon-props="{
        width: '40',
        height: '40',
      }"
    />
    <div class="name">
      {{ name }}
    </div>
    <div v-if="size !== 0" class="size">
      <span>{{ validateSize() }}</span>
    </div>

    <div v-if="isOfficeFile" class="size">
      <span>
        {{ props.type }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { documentOutline } from 'ionicons/icons';
import { ref } from 'vue';

import { IconsProvider } from '@/components';
import { useOfficeHelper } from '@/helpers';

const officeHelper = useOfficeHelper();

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  size: {
    type: [Number, String],
    default: 0,
  },
  type: {
    type: String,
    default: '',
  },
});

const isOfficeFile = ref(officeHelper.isOfficeFile(props.type || ''));
const icons = { document: documentOutline };

const validateSize = () => {
  if (props.size) {
    const propsSize = Number(props.size);
    let size;
    size = propsSize / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      size = size.toFixed(1);
      return size + ' MB';
    } else {
      size = size.toFixed(1);
      return size + ' KB';
    }
  }
  return '';
};
</script>

<style scoped lang="scss">
.name {
  text-align: center;
  width: 72px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.size {
  font-size: 0.8rem;
  color: var(--ion-color-medium);
}

.file-block {
  user-select: none;
}

.file-icon {
  font-size: 40px;
}
</style>
