import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';

/**
 * Generates a stable and unique ID based on the content.
 * Combines SHA-256 hash of the content with a UUID to ensure uniqueness.
 *
 * @param content - The text content to hash.
 * @returns A unique, stable ID string.
 */
export const generateUnique = (content: string): string => {
  const hash = content ? sha256(content) : '';
  const uuid = uuidv4();
  return `${hash}-${uuid}`;
};
