import { cloneDeep, filter, find, includes, indexOf, orderBy, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { TopicsSortTypeEnum, TopicsFilterEnum, DataViewMode } from '@/enums';
import { defaultTopicsIds } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  ResponseTopicModel,
  ResponseTopicsModel,
  TopicModel,
  TopicsIdsModel,
  TopicsModel,
  RequestCreateTopicModel,
  TopicColorModel,
} from '@/types';

interface TopicState extends EntityState<TopicModel> {
  currentTopic: TopicModel | null;
  topicsIds: TopicsIdsModel;
  topicsFilter: TopicsFilterEnum;
  topicsView: DataViewMode;
  topicsSort: TopicsSortTypeEnum;
  keepSearchQuery: boolean;
}

export const useTopicStore = defineStore({
  id: 'topic',
  state: (): TopicState => ({
    data: [],
    errors: [],
    topicsIds: cloneDeep(defaultTopicsIds),
    isLoading: false,
    topicsFilter: TopicsFilterEnum.Popular,
    topicsView: DataViewMode.List,
    currentTopic: null,
    topicsSort: TopicsSortTypeEnum.ByFeed,
    keepSearchQuery: false,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getTopicById:
      (state) =>
      (id: number): TopicModel | undefined =>
        find(state.data, (tag) => tag.id === id),
    getTopicsByIds:
      (state) =>
      (tagsIds: number[]): TopicModel[] | [] =>
        filter(state.data, (topic) => tagsIds.includes(topic.id)),
    getTopicByText:
      (state) =>
      (text: string): TopicModel | null => {
        const index = state.data.findIndex((topic: TopicModel) => topic.label === text);

        if (~index) {
          return state.data[index];
        }
        return null;
      },
    getTopics(): TopicModel[] {
      switch (this.topicsFilter) {
        case TopicsFilterEnum.Popular: {
          return this.getTopicsAll().data;
        }
        case TopicsFilterEnum.Following: {
          return this.getTopicsFollowing().data;
        }
        case TopicsFilterEnum.Recommended: {
          return this.getTopicsRecommended().data;
        }
        case TopicsFilterEnum.Search:
          return this.getTopicsSearch().data;

        case TopicsFilterEnum.SearchPage:
          return this.getTopicsSearchPage().data;
      }
    },

    getTopicsSearchPage: (state) => (): TopicsModel => {
      const result = { data: [], loadMoreUrl: null } as TopicsModel;
      const data = orderBy(state.data, ({ id }) => indexOf(state.topicsIds.searchPage.ids, id));
      result.data = filter(data, ({ id }) => includes(state.topicsIds.searchPage.ids, id));
      result.loadMoreUrl = state.topicsIds.searchPage.loadMoreUrl;
      return result;
    },

    getTopicsAll: (state) => (): TopicsModel => {
      const result = { data: [], loadMoreUrl: null } as TopicsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.topicsIds.topicsPage.all.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.topicsIds.topicsPage.all.ids, obj.id));
      result.loadMoreUrl = state.topicsIds.topicsPage.all.loadMoreUrl;
      return result;
    },

    getTopicsFollowing: (state) => (): TopicsModel => {
      const result = { data: [], loadMoreUrl: null } as TopicsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.topicsIds.topicsPage.following.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.topicsIds.topicsPage.following.ids, obj.id));
      result.loadMoreUrl = state.topicsIds.topicsPage.following.loadMoreUrl;
      return result;
    },

    getTopicsRecommended: (state) => (): TopicsModel => {
      const result = { data: [], loadMoreUrl: null } as TopicsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.topicsIds.topicsPage.recommended.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.topicsIds.topicsPage.recommended.ids, obj.id));
      result.loadMoreUrl = state.topicsIds.topicsPage.recommended.loadMoreUrl;
      return result;
    },

    getTopicsSearch: (state) => (): TopicsModel => {
      const result = { data: [], loadMoreUrl: null } as TopicsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.topicsIds.topicsPage.search.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.topicsIds.topicsPage.search.ids, obj.id));
      result.loadMoreUrl = state.topicsIds.topicsPage.search.loadMoreUrl;
      return result;
    },
  },
  actions: {
    async topicsAutocomplete(searchText: string): Promise<TopicModel[] | []> {
      this.errors = [];
      const response = await $api.topic.topicsAutocomplete(searchText);

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;
        this.data = mergeById(this.data, model.data);
        this.topicsIds.topicsPage.search.ids = model.data.map((n) => n.id);
        this.topicsIds.topicsPage.search.loadMoreUrl = model.loadMoreUrl;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
    async searchTopics(text: string): Promise<TopicModel[] | []> {
      this.errors = [];
      const response = await $api.topic.search(text);

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;
        this.data = mergeById(this.data, model.data);
        this.topicsIds.topicsPage.search.ids = model.data.map((n) => n.id);
        if (model.loadMoreUrl) {
          const paginationQuery = model.loadMoreUrl?.slice(model.loadMoreUrl?.indexOf('page'));
          this.topicsIds.topicsPage.search.loadMoreUrl = `/topics/all?search=${text}&${paginationQuery}`;
        } else {
          this.topicsIds.topicsPage.search.loadMoreUrl = null;
        }
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
    topicsFromSearch(topics: TopicModel[], loadMoreUrl: string | null = null): void {
      if (topics.length) {
        this.topicsIds.searchPage.ids = topics.map(({ id }) => id);
        this.data = mergeById(this.data, topics);
        this.topicsIds.searchPage.loadMoreUrl = loadMoreUrl;
      } else {
        this.topicsIds.searchPage.ids = [];
        this.topicsIds.searchPage.loadMoreUrl = null;
      }
    },
    async topicsAll(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.getTopicsAll();

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;
        this.data = mergeById(this.data, model.data);
        this.topicsIds.topicsPage.all.ids = model.data.map((n) => n.id);
        this.topicsIds.topicsPage.all.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },

    async topicsFollowing(): Promise<void> {
      this.errors = [];
      const response = await $api.topic.getTopicsFollowing();

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;
        this.data = mergeById(this.data, model.data);
        this.topicsIds.topicsPage.following.ids = model.data.map((n) => n.id);
        this.topicsIds.topicsPage.following.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },

    async topicsRecommended(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.getTopicsRecommended();

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;

        this.data = mergeById(this.data, model.data);
        this.topicsIds.topicsPage.recommended.ids = model.data.map((n) => n.id);
        this.topicsIds.topicsPage.recommended.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },

    async topicByText(text: string): Promise<TopicModel | undefined> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.getTopicByText(text);

      if (response.statusCode === 200) {
        const model = response as ResponseTopicModel;
        this.data = mergeById(this.data, [model.data]);
        this.isLoading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },

    async loadMore(url: string): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.loadMore(url);

      if (response.statusCode === 200) {
        const model = response as ResponseTopicsModel;
        this.data = mergeById(this.data, model.data);
        updateTopicsAfterLoadedMore(this.topicsFilter, model);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },

    async deleteTopic(id: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.deleteTopic(id);

      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === id);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },

    async editTopic(id: number, title: string, color: TopicColorModel | null, description: string): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.editTopic(id, title, color, description);

      if (response.statusCode === 200) {
        const editedTag = this.data.find((n) => n.id === id);
        if (editedTag) {
          editedTag.title = title;
          editedTag.color = color;
        }
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },

    async createNewTopic(topicData: RequestCreateTopicModel) {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.topic.createTopic(topicData);

      if (response.statusCode === 200) {
        const model = response as ResponseTopicModel;
        this.data.push(model.data);
        this.topicsIds.topicsPage.all.ids.push(model.data.id);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },

    resetSearchedTopics() {
      (this.topicsIds.topicsPage.search.ids = []), (this.topicsIds.topicsPage.search.loadMoreUrl = null);
    },

    async loadTopics() {
      switch (this.topicsFilter) {
        case TopicsFilterEnum.Popular:
          await this.topicsAll();
          break;

        case TopicsFilterEnum.Following:
          await this.topicsFollowing();
          break;

        case TopicsFilterEnum.Recommended:
          await this.topicsRecommended();
          break;
      }
    },
  },

  persist: true,
});

const mergeById = (a: TopicModel[], b: TopicModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const updateTopicsAfterLoadedMore = (topicsFilter: TopicsFilterEnum, model: TopicsModel) => {
  const topicStore = useTopicStore();
  switch (topicsFilter) {
    case TopicsFilterEnum.Popular:
      {
        topicStore.topicsIds.topicsPage.all.ids = [
          ...topicStore.topicsIds.topicsPage.all.ids,
          ...model.data.map((n) => n.id),
        ];
        topicStore.topicsIds.topicsPage.all.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case TopicsFilterEnum.Following:
      {
        topicStore.topicsIds.topicsPage.following.ids = [
          ...topicStore.topicsIds.topicsPage.following.ids,
          ...model.data.map((n) => n.id),
        ];
        topicStore.topicsIds.topicsPage.following.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case TopicsFilterEnum.Recommended:
      {
        topicStore.topicsIds.topicsPage.recommended.ids = [
          ...topicStore.topicsIds.topicsPage.recommended.ids,
          ...model.data.map((n) => n.id),
        ];
        topicStore.topicsIds.topicsPage.recommended.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case TopicsFilterEnum.Search:
      {
        topicStore.topicsIds.topicsPage.search.ids = [
          ...topicStore.topicsIds.topicsPage.search.ids,
          ...model.data.map((n) => n.id),
        ];
        if (model.loadMoreUrl) {
          const paginationQuery = model.loadMoreUrl?.slice(model.loadMoreUrl?.indexOf('page'));
          const currentLoadMoreUrl = topicStore.topicsIds.topicsPage.search.loadMoreUrl;
          topicStore.topicsIds.topicsPage.search.loadMoreUrl = currentLoadMoreUrl
            ? currentLoadMoreUrl.slice(0, currentLoadMoreUrl.indexOf('page')) + paginationQuery
            : null;
        } else {
          topicStore.topicsIds.topicsPage.search.loadMoreUrl = null;
        }
      }
      break;
  }
};
