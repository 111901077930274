import axios from '@/services/axios';
import type {
  RequestCallToUserModel,
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseTokenModel,
  RequestAnswerToUserModel,
  ResponseCallToUserModel,
  ResponseParticipantsModel,
  ResponseAnswerModel,
  ResponseActiveCallsModel,
  ResponseModel,
  RequestInviteModel,
} from '@/types';

export class MeetApiService {
  async getToken(name: string, roomId: string): Promise<ResponseTokenModel | ResponseErrorModel> {
    return axios.post('/meet/token', {
      name,
      room: roomId,
    });
  }
  async getActiveParticipants(chainId: number): Promise<ResponseParticipantsModel | ResponseErrorModel> {
    return axios.get(`/meet/participants/${chainId}`);
  }
  async getActiveCalls(): Promise<ResponseActiveCallsModel | ResponseErrorModel> {
    return axios.get(`/meet/calls`);
  }
  async callUser(data: RequestCallToUserModel): Promise<ResponseCallToUserModel | ResponseErrorModel> {
    return axios.post('/meet/call', data);
  }
  async answerUser(data: RequestAnswerToUserModel): Promise<ResponseAnswerModel | ResponseErrorModel> {
    return axios.post('/meet/result', data);
  }
  async reject(chainId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/cancel', {
      chainId,
    });
  }
  async invite(data: RequestInviteModel): Promise<ResponseModel<string> | ResponseErrorModel> {
    return axios.post('/meet/invite', data);
  }
}
