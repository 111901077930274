<template>
  <div class="task">
    <task-management-task-modal-content
      v-if="task && taskManagementEnabled"
      :is-post="true"
      :preview-mode="true"
      :task-data="task"
    />
    <div v-else-if="!taskManagementEnabled" class="task-plug" @click.stop>
      <ion-icon :icon="icons.eye" />
      <ion-text>{{ $t('taskManagement.disabled') }}</ion-text>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonText, IonIcon } from '@ionic/vue';
import { eyeOffOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { onMounted, computed } from 'vue';

import { TaskManagementTaskModalContent } from '@/components';
import type { FeedFlagEnum } from '@/enums';
import { useTaskManagement } from '@/helpers';
import { useProjectsStore } from '@/store';
import type { TaskManagementTaskModel } from '@/types';

const props = defineProps({
  postId: {
    type: Number,
    required: true,
  },
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
  previewMode: {
    type: Boolean,
    default: false,
  },
});

const icons = {
  eye: eyeOffOutline,
};

const projectsStore = useProjectsStore();
const taskManagementHelper = useTaskManagement();
const taskManagementEnabled: ComputedRef<boolean> = computed(() => taskManagementHelper.getAccessToTaskManagement());
const task: ComputedRef<TaskManagementTaskModel | undefined> = computed(
  () => projectsStore.getTaskById(props.taskData.id) ?? undefined
);
onMounted(() => {
  taskManagementEnabled.value ? projectsStore.addTaskToStore(props.taskData) : false;
});
</script>
<style scoped lang="scss">
.task {
  margin-top: 1rem;
}
.task-plug {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: app-radius(md);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  background: var(--ion-color-custom-element-lighter);
  padding: app-padding(lg);
  color: var(--ion-color-medium);
  ion-icon {
    font-size: 2.5rem;
    margin-bottom: app-padding(lg);
  }
  ion-text {
    text-align: center;
  }
}
</style>
