type IUseImages = {
  /** Use it to clear ALL cached images */
  clearImages: () => void;
  /** Use it to get the count and size of cached images */
  getStoredImagesInfo: () => { count: number; size: number };
  /**
   * ???
   *
   * @param canvas ???
   * @param image ???
   * @todo Add type
   */
  updateImageAfterCropping: (canvas: any, image: Blob) => Promise<File>;
  /**
   * Use it to fetch an image with a unique key and a function that fetches the image Blob
   * Function can hit cache | pending promise | fetch new promise
   * To avoid multiple fetches of the same image, Deferred (promise) pattern is used as well as Higher-Order Functions (passing a function as an argument)
   * It then caches the image and returns it's URL
   *
   * @param key Unique key for the image request
   * @param fetchFunction Function that fetches the image Blob
   * @returns URL of the image
   */
  fetchImage: (key: string, fetchFunction: () => Promise<Blob>) => Promise<string>;
};

let instance: IUseImages | null = null;

export function useImages(): IUseImages {
  if (instance) return instance;

  //#region Variables
  const cache = new Map<string, Blob>();
  const pendingRequests = new Map<string, Promise<Blob>>();
  //#endregion

  //#region Public methods
  const clearImages = (): void => {
    // logInfo(`ImagesHelper: clearImages()`); //! DEBUG
    cache.clear();
    pendingRequests.clear();
  };

  const getStoredImagesInfo = (): { count: number; size: number } => {
    // logInfo(`ImagesHelper: getStoredImagesInfo()`); //! DEBUG
    const count = cache.size;
    const size = Array.from(cache.values()).reduce((total, blob) => total + blob.size, 0);
    return { count, size };
  };

  const updateImageAfterCropping = (canvas: any, image: Blob): Promise<File> => {
    // logInfo(`ImagesHelper: updateImageAfterCropping()`); //! DEBUG
    return new Promise((resolve, reject) => {
      const imageType = image.type;
      const imageExtension = imageType.split('/')[1];

      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          const filename = `image.${imageExtension}`;
          const changedImage = new File([blob], filename, { type: imageType });
          resolve(changedImage);
        } else {
          reject(new Error('Failed to create Blob from canvas'));
        }
      }, imageType);
    });
  };

  const fetchImage = async (key: string, fetchFunction: () => Promise<Blob>): Promise<string> => {
    // Check if image is in cache
    if (cache.has(key)) {
      // logInfo(`ImagesHelper: fetchImage(${key}) - cache hit`); //! DEBUG
      return URL.createObjectURL(cache.get(key)!);
    }

    // Check if key is in pending requests
    if (pendingRequests.has(key)) {
      // logInfo(`ImagesHelper: fetchImage(${key}) - pending hit`); //! DEBUG
      const pendingBlob = await pendingRequests.get(key)!;
      return URL.createObjectURL(pendingBlob);
    }

    // Otherwise, initiate a new fetch request and store the Promise in pendingRequests
    const fetchPromise = fetchFunction()
      .then((blob) => {
        // logInfo(`ImagesHelper: fetchImage(${key}) - new fetch success`); //! DEBUG
        cache.set(key, blob); // Cache the fetched blob
        pendingRequests.delete(key); // Clean up pending request entry
        return blob;
      })
      .catch((error) => {
        console.error(`Failed to fetch image for key: ${key}`, error);
        // logInfo(`ImagesHelper: fetchImage(${key}) - new fetch error`); //! DEBUG
        pendingRequests.delete(key); // Clean up on failure
        throw error;
      });

    // Store the fetchPromise in pendingRequests to deduplicate
    pendingRequests.set(key, fetchPromise);

    const blob = await fetchPromise;
    return URL.createObjectURL(blob);
  };
  //#endregion

  instance = {
    clearImages,
    getStoredImagesInfo,
    updateImageAfterCropping,
    fetchImage,
  };

  return instance;
}
