<template>
  <div class="description">
    <div class="description-editor mt-lg">
      <div
        v-if="(description === null || description?.length === 0) && !isEditing"
        :class="['plug', { 'post-creation': taskId === 0 }]"
        @click.stop="isEditing = true"
      >
        {{ $t('description.detailed') }}
      </div>
      <div
        v-if="description && description?.length > 0 && !isEditing"
        v-tooltip.bottom="{
          content: $t('clickToEdit'),
          theme: 'info-tooltip',
          disabled: isAnyMobile,
        }"
        :class="['text', { 'post-creation': taskId === 0 }]"
        @click.stop="withControls ? (isEditing = !isEditing) : null"
        v-html="descriptionToShow"
      />
      <template v-if="isEditing">
        <app-rich-text-editor
          :value="description"
          :height="isPost ? 500 : 200"
          :group-id="groupId"
          :with-border-radius="false"
          class="with-shadow"
          @update:value="onChangeRichText"
        />
      </template>
      <div v-if="withControls" class="description--button-container">
        <!-- <ion-button
          v-if="!isEditing && descriptionToShow.length > 0"
          size="small"
          mode="md"
          fill="solid"
          class="edit-btn"
          @click.stop="isEditing = !isEditing"
        >
          <span>{{ $t('description.edit') }}</span>
        </ion-button> -->
        <ion-button v-if="isEditing" mode="md" fill="solid" color="success" @click.stop="updateTaskDescription()">
          {{ $t('save') }}
        </ion-button>
        <ion-button v-if="isEditing" mode="md" fill="solid" class="cancel-btn" @click.stop="cancelEditing()">
          {{ $t('cancel') }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, watch, onMounted, ref } from 'vue';

import { AppRichTextEditor } from '@/components';
import { isAnyMobile, useRichTextEditor } from '@/helpers';
import { useProjectsStore } from '@/store';

// Props
const props = defineProps({
  taskId: {
    type: Number,
    default: 0,
  },
  description: {
    type: null as unknown as PropType<string | null>,
    required: true,
  },
  isPost: {
    type: Boolean,
    required: true,
  },
  withControls: {
    type: Boolean,
    default: true,
  },
});

// Store
const projectsStore = useProjectsStore();

// Helpers
const richTextEditorHelper = useRichTextEditor();

// Refs
const isEditing = ref<boolean>(!props.withControls);
const description = ref<string>(props.description ?? '');
const descriptionToShow = ref<string>('');
const descriptionProp: ComputedRef<string> = computed(() => props.description ?? '');

// Computed
const groupId: ComputedRef<number | null> = computed(() => projectsStore.getGroupIdByTaskId(props.taskId));

// Methods
const onChangeRichText = async (val: string) => {
  description.value = val;

  if (!props.withControls) await updateTaskDescription();
};

const updateTaskDescription = async () => {
  if (props.taskId > 0) {
    await projectsStore.updateTaskDescription(props.taskId, richTextEditorHelper.preSubmit(description.value));
  } else {
    emit('onDescriptionChange', richTextEditorHelper.preSubmit(description.value));
  }

  descriptionToShow.value = await richTextEditorHelper.preprocessBody(description.value);

  if (props.withControls) isEditing.value = false;
};

const cancelEditing = () => {
  description.value = descriptionProp.value;
  isEditing.value = false;
};

const getDescription = async () => {
  descriptionToShow.value = props.description ? await richTextEditorHelper.preprocessBody(props.description) : '';
};

// Watchers
watch(descriptionProp, async () => {
  await getDescription();
});

// Lifecycle
onMounted(async () => {
  await getDescription();
});

// Emits
const emit = defineEmits(['onDescriptionChange']);
</script>

<style scoped lang="scss">
.description {
  &--button-container {
    margin-top: app-padding(md);
    .cancel-btn {
      --background: var(--ion-color-custom-element-lighter);
      --color: var(--ion-color-medium);
    }
  }
  .with-shadow {
    -webkit-box-shadow: 0px 5px 10px -5px rgba(var(--ion-color-dark-rgb), 0.1);
    -moz-box-shadow: 0px 5px 10px -5px rgba(var(--ion-color-dark-rgb), 0.1);
    box-shadow: 0px 5px 10px -5px rgba(var(--ion-color-dark-rgb), 0.1);
  }
}
.description-editor {
  color: var(--ion-color-dark);

  .plug {
    box-sizing: border-box;
    margin-top: app-padding(md);
    width: 100%;
    background: var(--ion-color-light);
    color: var(--ion-color-medium);
    height: 4rem;
    padding: app-padding(md);
    border-radius: app-radius(md);
    font-size: 0.9rem;
    &.post-creation {
      background: var(--ion-color-custom-element-lighter);
      color: var(--ion-color-medium);
    }
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  .text {
    font-size: 14px;
    letter-spacing: -0.005em;
    line-height: 1.7;
    &.post-creation {
      margin-top: app-padding(md);
      border: 2px solid var(--ion-color-custom-element-lighter);
      border-radius: app-radius(md);
      padding: app-padding(md);
    }
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  ion-button {
    margin: 0;
    margin-top: app-padding(md);
    --box-shadow: none;
    text-transform: unset;
    --border-radius: #{app-radius(md)};
  }

  ion-button:not(:first-child) {
    margin-left: app-padding(md);
  }
}
</style>
