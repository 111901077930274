<template>
  <div class="group-created">
    <feed-text :text-data="groupCreatedData" :feed-flag="feedFlag" />
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { FeedText } from '@/components';
import type { FeedFlagEnum } from '@/enums';

const props = defineProps({
  groupCreatedData: {
    type: String,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
});

const groupCreatedData: ComputedRef<string> = computed(() => props.groupCreatedData);
</script>
<style scoped lang="scss">
.group-created {
  margin-top: 1rem;
}
</style>
