import { ThemeAppEnum } from '@/enums';

export class LightOrDark {
  public static getColor(color: any): ThemeAppEnum {
    if (!color || color === null) {
      return ThemeAppEnum.Light;
    }

    let r, g, b;
    let convertedColor = color;

    if (convertedColor.match(/^rgb/)) {
      convertedColor = convertedColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = convertedColor[1];
      g = convertedColor[2];
      b = convertedColor[3];
    } else {
      convertedColor = +('0x' + convertedColor.slice(1).replace(convertedColor.length < 5 && /./g, '$&$&'));
      r = convertedColor >>> 16;
      g = (convertedColor >>> 8) & 255;
      b = convertedColor & 255;
    }

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
    return hsp > 134 ? ThemeAppEnum.Light : ThemeAppEnum.Dark;
  }
}
