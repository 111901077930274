<template>
  <ion-avatar>
    <!-- Loading state -->
    <ion-skeleton-text v-if="!hasAvatar && !isTextAvatar" :animated="true" />

    <!-- Image or Text -->
    <template v-if="avatar">
      <img
        v-tooltip.bottom="{
          content: fio,
          theme: 'info-tooltip',
          disabled: isAnyMobile || !withTitle,
        }"
        :src="avatar"
        :alt="fio"
      />
    </template>

    <template v-else-if="isTextAvatar">
      <div
        v-tooltip.bottom="{
          content: fio,
          theme: 'info-tooltip',
          disabled: isAnyMobile || !withTitle,
        }"
        class="u-text-avatar fw-500"
        :class="{ 'dark-text': darkText }"
        :style="{ background: randomColor, color: textColor }"
      >
        {{ letters }}
      </div>
    </template>

    <!-- Status Indicator -->
    <div
      v-if="withOnlineStatus && statusColor"
      :class="[statusSize, 'status', { blinking: iAmConnectingOrReconnecting }]"
      :style="{ backgroundColor: `${statusColor} !important` }"
    />
  </ion-avatar>
</template>

<script lang="ts" setup>
import { IonAvatar, IonSkeletonText } from '@ionic/vue';
import { HubConnectionState } from '@microsoft/signalr';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref, watch, onMounted } from 'vue';

import { ColorGenerator, getLetters, useFilesHybrid, getTextColor, isAnyMobile } from '@/helpers';
import { useAppStore, useUserStore } from '@/store';

// Props
const props = defineProps({
  userId: {
    type: Number,
    default: 0,
  },
  fio: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  withOnlineStatus: {
    type: Boolean,
    default: false,
  },
  withTitle: {
    type: Boolean,
    default: false,
  },
  darkText: {
    type: Boolean,
    default: false,
  },
  /** @description Status indicator size */
  statusSize: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    default: 'small',
  },
});

// Store
const appStore = useAppStore();
const userStore = useUserStore();

// Refs
const avatar = ref<string>('');
const isTextAvatar = ref<boolean>(false);

// Computed
const hasAvatar: ComputedRef<boolean> = computed(() => avatar.value !== '');
const letters: ComputedRef<string> = computed(() => getLetters(props.fio));
const randomColor: ComputedRef<string> = computed(() => ColorGenerator.getColor(letters.value));
const textColor: ComputedRef<string> = computed(() => getTextColor(randomColor.value));
const isCurrentUser: ComputedRef<boolean> = computed(() => props.userId === userStore.current?.id);
const connectionStatus: ComputedRef<HubConnectionState> = computed(() => appStore.signalRConnectionStatus);

const isConnected: ComputedRef<boolean> = computed(() =>
  isCurrentUser.value
    ? connectionStatus.value === HubConnectionState.Connected
    : appStore.onlineUsers.includes(props.userId)
);

/*
const isDisconnected: ComputedRef<boolean> = computed(() =>
  isCurrentUser.value
    ? connectionStatus.value === HubConnectionState.Disconnected
    : !appStore.onlineUsers.includes(props.userId)
);
*/

const iAmConnectingOrReconnecting: ComputedRef<boolean> = computed(
  () =>
    isCurrentUser.value &&
    (connectionStatus.value === HubConnectionState.Connecting ||
      connectionStatus.value === HubConnectionState.Reconnecting)
);

const iAmDisconnected: ComputedRef<boolean> = computed(
  () => isCurrentUser.value && connectionStatus.value === HubConnectionState.Disconnected
);

const statusColor: ComputedRef<string> = computed(() => {
  if (isConnected.value) return '#4caf50'; // Green for connected
  if (iAmDisconnected.value) return '#ff4961'; // Red for disconnected
  if (iAmConnectingOrReconnecting.value) return '#ffeb3b'; // Yellow for connecting or reconnecting
  return '';
});

// Methods
const updateAvatar = async () => {
  if (!props.url) {
    isTextAvatar.value = true;
    avatar.value = '';
    return;
  }

  try {
    const file = await useFilesHybrid().readFile(props.url, undefined, false);

    avatar.value = file ? file : '';
    isTextAvatar.value = !file;
  } catch (e) {
    console.error(`Error loading avatar from ${props.url}:`, e);
    isTextAvatar.value = true;
    avatar.value = '';
  }
};

// Watchers
watch(() => props.url, updateAvatar);

// Lifecycle
onMounted(async () => {
  await updateAvatar();
});
</script>

<style scoped lang="scss">
ion-avatar {
  position: relative;

  .u-text-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .dark-text {
    color: var(--ion-color-warning-contrast);
  }

  ion-skeleton-text {
    position: absolute;
    z-index: 1;
  }

  .status {
    &.small {
      @include smallOnlineStatus;
    }
    &.medium {
      @include mediumOnlineStatus;
    }
    &.large {
      @include largeOnlineStatus;
    }

    &.blinking {
      animation: blink 1.5s linear infinite;
    }
  }

  @keyframes blink {
    0%,
    50%,
    100% {
      opacity: 1;
    }
    25%,
    75% {
      opacity: 0.2;
    }
  }
}
</style>
