<template>
  <ion-item lines="none" @click.stop="openFile()">
    <div slot="start" class="file-block">
      <template v-if="status !== FileStatusEnum.Loading">
        <icons-provider
          class="file-icon"
          :name="fileObject.type"
          :icon-props="{
            width: '40',
            height: '40',
          }"
        />
        <ion-button
          v-if="fileObject.isOutdated"
          v-tooltip="t('files.fileVersionViewing')"
          class="outdated"
          size="small"
        >
          {{ t('wiki.editOptions.outdated') }}
        </ion-button>
      </template>
      <template v-else>
        <ion-buttons>
          <ion-button>
            <icons-provider
              slot="icon-only"
              :icon-props="{ width: '22', height: '22' }"
              :name="AppIconsEnum.CircleAnim"
            />
          </ion-button>
        </ion-buttons>
      </template>
    </div>

    <ion-label>
      <p class="file-name">{{ fileObject.name }}</p>
      <p v-if="fileObject.text !== ''" class="file-text">
        {{ fileObject.text }}
      </p>
    </ion-label>

    <ion-button v-if="status !== FileStatusEnum.Loading" size="default" fill="clear" @click.stop="downloadFile()">
      <ion-icon slot="icon-only" :icon="icons.download" />
    </ion-button>
    <ion-button
      v-if="status === FileStatusEnum.Loading"
      size="default"
      fill="clear"
      @click.stop="stopDownload(fileObject.key)"
    >
      <ion-icon slot="icon-only" :icon="icons.close" />
    </ion-button>
  </ion-item>
</template>

<script lang="ts" setup>
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel } from '@ionic/vue';
import { closeOutline, documentOutline, downloadOutline } from 'ionicons/icons';
import type { PropType, Ref } from 'vue';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { IconsProvider } from '@/components';
import { FileStatusEnum, AppIconsEnum } from '@/enums';
import { DateHelper, useFilesHybrid, useToasts, useFileActions } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useDocStore } from '@/store';
import type { FileModel } from '@/types';

// Props
const props = defineProps({
  file: {
    type: Object as PropType<FileModel>,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
    default: false,
  },
  postCreatedAt: {
    type: String,
    required: true,
  },
});

// Icons
const icons = {
  document: documentOutline,
  close: closeOutline,
  download: downloadOutline,
};

// Other
const { t } = useI18n();
const { showSonnerToast } = useToasts();
const router = useRouter();
const route = useRoute();

// Refs
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);
const fileObject = ref({
  key: '',
  name: '',
  text: '',
  icon: icons.document,
  type: '',
  isOutdated: false,
});

// Methods
const validateSize = (value: number) => {
  if (value) {
    let size;
    size = value / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      size = size.toFixed(1);
      return size + ' MB';
    } else {
      size = size.toFixed(1);
      return size + ' KB';
    }
  }
  return '';
};

const openFile = async () => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }
  const isPreviewAvailable = await useFileActions().isPreviewAvailable(props.file);
  if (!isPreviewAvailable) {
    status.value = FileStatusEnum.Loading;
    emit('onLoading', true, props.file.key);
    status.value = await useFilesHybrid().openFile(props.file);
    if (status.value === FileStatusEnum.Error) {
      showSonnerToast(t('errorResponse'), false);
    }
  } else {
    const requestDate = DateHelper.addSecondsToDateInISO(new Date(props.postCreatedAt).toISOString(), 5);
    const currentFileVersion = await useDocStore().getHistoricalFileByDate(props.file.id, requestDate);
    useDocStore().$patch((state) => {
      state.currentFileVersion = currentFileVersion || null;
    });
    const found = useDocStore().availableFileVersions.find((file) => file.id === currentFileVersion?.id);
    if (!found && currentFileVersion) {
      useDocStore().$patch((state) => {
        state.availableFileVersions.push(currentFileVersion);
      });
    }

    //NOTE: #1658 If file is audio, open it directly
    if (useFilesHybrid().fileIsAudio(props.file)) {
      useFilesHybrid().openFile(props.file);
      return;
    }

    if (route.name !== ROUTES_NAME.FILE_BY_ID) {
      await router.push({
        name: ROUTES_NAME.FILE_BY_ID,
        params: { id: props.file.id },
      });
    }
  }
};

const downloadFile = async () => {
  if (status.value === FileStatusEnum.Loading) {
    return;
  }

  status.value = FileStatusEnum.Loading;
  emit('onLoading', true, props.file.key);
  status.value = await useFilesHybrid().downloadFile(props.file);

  showSonnerToast(t('files.successDownloaded'), status.value === FileStatusEnum.Success);
};

const stopDownload = async (id: string) => {
  status.value = FileStatusEnum.Success;
  emit('onLoading', false, id);
  showSonnerToast(t('files.downloadStopped'), true);
};

// Lifecycle
onMounted(() => {
  fileObject.value.isOutdated = new Date(props.file.editedAt).getTime() > new Date(props.postCreatedAt).getTime();
  fileObject.value.name = props.file.name + '.' + props.file.type;
  fileObject.value.text = validateSize(props.file.size).toString();
  fileObject.value.type = props.file.type;
  fileObject.value.key = props.file.key;
});

// Emits
const emit = defineEmits(['onLoading']);
</script>

<style scoped lang="scss">
.file-block {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 44px;
  margin-inline-end: app-padding(lg);

  .file-icon {
    font-size: 40px;
  }

  .outdated {
    @include resetStyleFromIonicButton;
    text-transform: unset;
    font-size: 0.5rem;
    width: 40px;
    --color: var(--ion-color-custom);
    --border-radius: 4px;
    --background: var(--ion-color-notification-badge);
  }

  ion-badge {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

ion-item {
  --background: transparent;
  --inner-padding-end: 0;
  --padding-start: 0;
  margin-bottom: 0.5rem;
  border: 1px solid var(--ion-color-light-custom);
  border-radius: 4px;
  padding: 0.5rem;
  padding-right: 0;
}
ion-item:last-child {
  margin: 0;
}
ion-item ion-button {
  margin-left: auto;
  margin-right: 0;
}
ion-item:hover {
  cursor: pointer;
  opacity: 0.7;
}
ion-button ion-icon {
  color: var(--ion-color-medium);
}
.file-name {
  font-size: 1rem;
  color: var(--ion-color-dark);
}
.file-text {
  white-space: normal;
}
</style>
