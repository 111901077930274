<template>
  <component :is="selectedIcon" v-if="selectedIcon !== null" v-bind="computedIconProps" />
  <ion-icon v-else :icon="alertCircleOutline" />
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { alertCircleOutline } from 'ionicons/icons';
import { computed, ref, watch } from 'vue';
import type { Component, PropType } from 'vue';

import type { AppIconsEnum } from '@/enums';
import { useIconsOptimized } from '@/helpers';
import type { IconPropsModel } from '@/types';

// Props
const props = defineProps({
  name: {
    type: String as PropType<AppIconsEnum | string>,
    required: true,
  },
  iconProps: {
    type: Object as PropType<IconPropsModel>,
    default: () => ({}),
  },
});

// Refs
const selectedIcon = ref<Component | null>(null);

// Computed
const computedIconProps = computed(() => {
  return {
    ...props.iconProps,
  };
});

// Watchers
watch(
  () => props.name,
  async (newName) => {
    if (typeof newName === 'string') {
      const iconName = newName.toLowerCase() as AppIconsEnum;
      selectedIcon.value = await useIconsOptimized().getIcon(iconName);
    } else {
      console.warn('name prop is not a string:', newName);
      selectedIcon.value = null;
    }
  },
  { immediate: true }
);
</script>
