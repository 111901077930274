import { cloneDeep, find, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { useNetworkStore } from './network.pinia';

import { NetworkSettingsTypeEnum, NotificationTemplatesTypeEnum } from '@/enums';
import { changeFavicon } from '@/helpers';
import { defaultNetworkDesign, defaultNetworkSettings, defaultNetworkTemplates } from '@/models';
import { $api } from '@/services';
import type {
  AdminRegisterModel,
  DomainModel,
  DomainUpdateModel,
  ErrorMessageModel,
  NetworkBrandingModel,
  NetworkDesignModel,
  NetworkFullSettingsModel,
  NetworkMobileAppsModel,
  NetworkSettingsModel,
  NotificationTemplateShortModel,
  ResponseDomain,
  ResponseDomains,
  ResponseErrorModel,
  ResponseInviteUserModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkMobileAppsModel,
  ResponseNotificationTemplate,
  SendInvitationsModel,
} from '@/types';

export type AdminState = {
  errors: ErrorMessageModel[];
  isLoading: boolean;
  networkSettings: NetworkFullSettingsModel;
  domainList: { data: DomainModel[]; loadMoreUrl: string | null };
  design: NetworkDesignModel;
  templates: Partial<Record<NotificationTemplatesTypeEnum, NotificationTemplateShortModel>>;
};

export const useAdminStore = defineStore({
  id: 'admin',
  state: (): AdminState => ({
    errors: [],
    isLoading: false,
    networkSettings: cloneDeep(defaultNetworkSettings),
    domainList: { data: [], loadMoreUrl: null },
    design: cloneDeep(defaultNetworkDesign),
    templates: cloneDeep(defaultNetworkTemplates),
  }),
  getters: {
    getDomainList: (state: AdminState) => state.domainList,
    getDesign: (state: AdminState): NetworkDesignModel => state.design,
    getTemplate:
      (state: AdminState): ((type: NotificationTemplatesTypeEnum) => NotificationTemplateShortModel) =>
      (type: NotificationTemplatesTypeEnum) =>
        state.templates[type] || { theme: null, body: null },
  },
  actions: {
    async currentNetworkSettings(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.network.networkSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        this.networkSettings = cloneDeep(model.data);
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async mobileAppsSettings(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.network.mobileAppsSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkMobileAppsModel;
        this.networkSettings = {
          ...this.networkSettings,
          ...cloneDeep(model.data),
        };
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async brandingSettings(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.network.brandingSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkBrandingModel;
        this.networkSettings = {
          ...this.networkSettings,
          ...cloneDeep(model.data),
        };
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async saveNetworkSettings(data: NetworkSettingsModel, type: NetworkSettingsTypeEnum): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.network.saveNetworkSettings(data, type);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.Branding]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveBrandingSettings(data: NetworkBrandingModel): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.network.saveNetworkSettings(data, NetworkSettingsTypeEnum.Branding);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.MobileApps]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveMobileAppsSettings(data: NetworkMobileAppsModel): Promise<boolean> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.network.saveNetworkSettings(data, NetworkSettingsTypeEnum.MobileApps);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;
        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async domains(text?: string): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.getDomains(text);
      if (response.statusCode === 200) {
        const model = response as ResponseDomains;
        this.domainList.data = cloneDeep(model.data);
        this.domainList.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async domainsLoadMore(url: string): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.domainsLoadMore(url);
      if (response.statusCode === 200) {
        const model = response as ResponseDomains;
        this.domainList.data = mergeDomainsByEmailSuffix(this.domainList.data, model.data);
        this.domainList.loadMoreUrl = model.loadMoreUrl;
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async createDomain(data: DomainModel): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.createDomain(data);
      if (response.statusCode === 200) {
        const model = response as ResponseDomain;
        this.domainList.data.push(model.data);
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async saveDomain(data: DomainUpdateModel): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.saveDomain(data);

      if (response.statusCode === 200) {
        const model = response as ResponseDomain;
        const index = this.domainList.data.findIndex((n) => n.emailSuffix === data.oldEmailSuffix);
        if (~index) this.domainList.data[index] = model.data;
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async deleteDomain(data: DomainModel): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.deleteDomain(data);
      if (response.statusCode === 200) {
        remove(this.domainList.data, { emailSuffix: data.emailSuffix });
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async emailFooterSettings(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.getTemplate(NotificationTemplatesTypeEnum.EmailFooter);
      if (response.statusCode === 200) {
        const model = response as ResponseNotificationTemplate;
        this.templates[NotificationTemplatesTypeEnum.EmailFooter] = { theme: model.data.theme, body: model.data.body };
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async saveEmailFooter(emailFooter: string | null): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.saveTemplate(NotificationTemplatesTypeEnum.EmailFooter, emailFooter);
      if (response.statusCode === 200) {
        const model = response as ResponseNotificationTemplate;
        this.templates[NotificationTemplatesTypeEnum.EmailFooter] = { theme: model.data.theme, body: model.data.body };

        const networkStore = useNetworkStore();
        networkStore.$patch((state) => {
          if (state.settings) {
            state.settings.emailFooter = model.data.body;
          }
        });
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async designSettings(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.network.designSettings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkDesignModel;
        this.design = cloneDeep(model.data);
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    /**
     * TODO: Should be removed in favor of saveNetworkSettings that accepts [type: NetworkSettingsTypeEnum.Style]
     * @obsolete
     * @see saveNetworkSettings
     */
    async saveStyle(data: NetworkDesignModel): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.network.saveNetworkSettings(data, NetworkSettingsTypeEnum.Style);
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkDesignModel;
        this.design = cloneDeep(model.data);

        const networkStore = useNetworkStore();
        networkStore.$patch({
          settings: {
            ...networkStore.settings,
            ...model.data,
          },
        });

        changeFavicon();

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async inviteUsers(data: SendInvitationsModel): Promise<{ email: string; status: string }[]> {
      this.isLoading = true;
      this.errors = [];
      const response = await $api.user.inviteUser(data);
      if (response.statusCode === 200) {
        const model = response as ResponseInviteUserModel;
        this.isLoading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return [];
    },
    async registerUsers(data: AdminRegisterModel): Promise<{ email: string; status: string }[]> {
      this.isLoading = true;
      const response = await $api.user.registerUser(data);
      if (response.statusCode === 200) {
        const model = response as ResponseInviteUserModel;
        this.isLoading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return [];
    },
    async inviteUserTemplate(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.getTemplate(NotificationTemplatesTypeEnum.InvitePerson);
      if (response.statusCode === 200) {
        const model = response as ResponseNotificationTemplate;
        this.templates[NotificationTemplatesTypeEnum.InvitePerson] = { theme: model.data.theme, body: model.data.body };
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async registerUserTemplate(): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.getTemplate(NotificationTemplatesTypeEnum.RegisterPerson);
      if (response.statusCode === 200) {
        const model = response as ResponseNotificationTemplate;
        this.templates[NotificationTemplatesTypeEnum.RegisterPerson] = {
          theme: model.data.theme,
          body: model.data.body,
        };
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async saveTemplate(type: NotificationTemplatesTypeEnum, data: NotificationTemplateShortModel): Promise<boolean> {
      this.isLoading = true;
      const response = await $api.admin.saveTemplate(type, data.body, data.theme);
      if (response.statusCode === 200) {
        const model = response as ResponseNotificationTemplate;
        this.setTemplate(type, { theme: model.data.theme, body: model.data.body });
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    setTemplate(type: NotificationTemplatesTypeEnum, value: NotificationTemplateShortModel) {
      this.templates[type] = value;
    },
  },
  persist: true,
});

const mergeDomainsByEmailSuffix = (a: DomainModel[], b: DomainModel[]) => {
  return unionBy(a, b, 'emailSuffix').map((obj) => {
    const match = find(b, { emailSuffix: obj.emailSuffix });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
