import type { AppMenuLanguagesEnum, SendKeyEnum, ThemeAppEnum } from '@/enums';
import { $api, axios } from '@/services';
import type {
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponsePasswordRestoreModel,
  PasswordSaveModel,
  ResponsePasswordPolicyModel,
  PhoneConfirmModel,
  ResponsePhoneChangeModel,
  ResponseUserAvatarModel,
  ResponseFileModel,
  ResponseShortUsersModel,
  ResponseGroupsShortModel,
  RegistrationSendCodeToEmailResponseModel,
  RegistrationActivateResponseModel,
  RegistrationDataModel,
  ResponseRegistrationCoreTokenModel,
  UserSubscribeSettingsRequestModel,
  ResponseUserModel,
  DeviceModel,
  ResponseRulesModel,
} from '@/types';

export class AccountApiService {
  async passwordSettings(): Promise<ResponsePasswordPolicyModel | ResponseErrorModel> {
    return axios.get('/account/passwordSettings');
  }
  async passwordPolicy(hash: string): Promise<ResponsePasswordPolicyModel | ResponseErrorModel> {
    return axios.get('/account/passwordPolicy?hash=' + hash);
  }
  async passwordRestore(email: string): Promise<ResponsePasswordRestoreModel | ResponseErrorModel> {
    return axios.post('/account/passwordRestore', {
      email: email,
    });
  }
  async passwordSave(data: PasswordSaveModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/passwordSave', {
      email: data.email,
      hash: data.hash,
      password: data.password,
    });
  }
  async passwordUpdate(data: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/passwordUpdate', {
      password: data,
    });
  }
  async phoneChange(phone: string): Promise<ResponsePhoneChangeModel | ResponseErrorModel> {
    return axios.post('/account/phone', { phone: phone });
  }
  async phoneConfirm(data: PhoneConfirmModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put('/account/phone', data);
  }
  async phoneDelete(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/account/phone');
  }
  async getUsageRules(): Promise<ResponseRulesModel | ResponseErrorModel> {
    return axios.get('/account/usageRules');
  }
  async acceptRules(rulesId?: number | null): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return rulesId ? axios.post(`/account/usageRules/${rulesId}`) : axios.post('/account/usageRules');
  }
  async setTheme(theme: ThemeAppEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/theme', { themeStyle: theme });
  }
  async setSendMode(mode: SendKeyEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/sendMode', { sendMode: mode });
  }
  async setLanguage(language: AppMenuLanguagesEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/language', { lang: language });
  }
  async pagesBackground(enabled: boolean): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/pagesBackground', {
      pagesBackground: enabled,
    });
  }
  async updateNotifications(data: UserSubscribeSettingsRequestModel): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.post('/account/settings/notifications', data);
  }
  async updateGroupNotifications(groupId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/account/settings/groupNotifications/${groupId}`);
  }
  async appSound(enabled: boolean): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/sound', { sound: enabled });
  }
  async setPostOnBehalfOption(enabled: boolean): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/postOnBehalf', {
      canPostOnBehalf: enabled,
    });
  }
  async setAddToGroupOption(enabled: boolean): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/settings/addToGroup', {
      enable: enabled,
    });
  }
  async registrationSendCodeToEmail(
    email: string
  ): Promise<RegistrationSendCodeToEmailResponseModel | ResponseErrorModel> {
    return axios.post('/account/invite', { email });
  }
  async activateRegistration(
    id: number,
    hash: string
  ): Promise<RegistrationActivateResponseModel | ResponseErrorModel> {
    return axios.get(`/account/invite/${id}?hash=${hash}`);
  }
  async registration(data: RegistrationDataModel): Promise<ResponseRegistrationCoreTokenModel | ResponseErrorModel> {
    return axios.post('/account/registration/byForm', data);
  }
  async registrationUpdateAvatar(image: File): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post('/account/avatar', {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async activeUsers(): Promise<ResponseShortUsersModel | ResponseErrorModel> {
    return axios.get('/account/invite/users');
  }
  async mainGroups(): Promise<ResponseGroupsShortModel | ResponseErrorModel> {
    return axios.get('/account/invite/groups');
  }
  async register(params: DeviceModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/registerDevice', params);
  }
  async unRegister(params: DeviceModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/account/unRegisterDevice', params);
  }
}
