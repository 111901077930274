import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { getPostCreateCategories, getAnnouncementAccess } from '@/helpers';
import router from '@/router';
import { useGroupsStore } from '@/store';
import type { GroupModel } from '@/types';

export function usePostCreateMenu(): any {
  const route = router.currentRoute.value;
  const groupStore = useGroupsStore();

  const isLoading: ComputedRef<boolean> = computed(() => groupStore.isLoading);

  const canPostGroups: ComputedRef<GroupModel[]> = computed(() => {
    return groupStore.getCanPostGroups().data;
  });

  const announcementAccess: ComputedRef<boolean> = computed(() => getAnnouncementAccess(canPostGroups.value, route));

  const categories: ComputedRef<any> = computed(() =>
    getPostCreateCategories(announcementAccess.value, route).filter((n) => n.active)
  );

  return {
    categories,
    isLoading,
  };
}
