import { cloneDeep, orderBy } from 'lodash';
import { defineStore } from 'pinia';
import type { Serializer } from 'pinia-plugin-persistedstate';
import { parse, stringify } from 'zipson';

import { SortingTypeEnum, SortingDirectionEnum, SectionTypeEnum } from '@/enums';
import { logInfo, logErr } from '@/helpers/logger';
import { $api } from '@/services';
import type { BaseState } from '@/store';
import type {
  ErrorMessageModel,
  GroupModel,
  ResponseErrorModel,
  MenuBySections,
  MenuCustomItemModel,
  MenuModel,
  MenuFileItemModel,
  MenuGroupItemModel,
  ResponseMenuModel,
} from '@/types';

/*
const NOT_AVAILABLE_ITEMS = [
  'Undefined',
  'GettingStarted',
  'Invite',
  'Ideas',
  'OrgChart',
];
*/

interface MenuState extends BaseState<MenuModel> {
  timer: NodeJS.Timeout | null;
  sortingType: SortingTypeEnum;
  sortingDirection: SortingDirectionEnum;
  groups: Map<number, MenuGroupItemModel>;
  groupsPinned: Set<number>;
  groupsPermanentlyPinned: Set<number>;
  groupsUnpinned: Set<number>;
  fileItems: (MenuCustomItemModel | MenuFileItemModel)[];
  menuBySections: MenuBySections;
  unreadMessagesTotal: number;
}

export const useMenuStore = defineStore({
  id: 'menu',
  state: (): MenuState => ({
    data: null,
    errors: [],
    isLoading: false,
    timer: null,
    fileItems: [],
    sortingType: SortingTypeEnum.Name,
    sortingDirection: SortingDirectionEnum.Asc,
    groups: new Map<number, MenuGroupItemModel>(),
    groupsPinned: new Set<number>(),
    groupsPermanentlyPinned: new Set<number>(),
    groupsUnpinned: new Set<number>(),
    menuBySections: {
      Favorites: {
        sectionType: SectionTypeEnum.Favorites,
        title: '',
        isPublic: false,
        items: [],
      },
      Helpful: {
        sectionType: SectionTypeEnum.Helpful,
        title: '',
        isPublic: false,
        items: [],
      },
      Groups: {
        sectionType: SectionTypeEnum.Groups,
        title: '',
        isPublic: false,
        items: [],
      },
    },
    unreadMessagesTotal: 0,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getMenuRaw: (state) => {
      return state.data;
    },
    getMenuBySections: (state) => {
      return state.menuBySections;
    },
    getGroups: (state) => {
      if (state.groups && state.groups.size > 0) {
        return customOrderBy(state.sortingType, state.sortingDirection, Array.from(state.groups.values()));
      }

      logInfo('No groups available - make sure to load data if required');
      return [];
    },
    getGroupsPermanentlyPinned: (state) => {
      if (state.groupsPermanentlyPinned && state.groupsPermanentlyPinned.size > 0) {
        const result = customOrderBy(
          state.sortingType,
          state.sortingDirection,
          Array.from(state.groupsPermanentlyPinned).map((id) => state.groups.get(id)!)
        );
        return result;
      }

      logInfo('No permanently pinned groups available - make sure to load data if required');
      return [];
    },
    getGroupsPinned: (state) => {
      if (state.groupsPinned && state.groupsPinned.size > 0) {
        const result = customOrderBy(
          state.sortingType,
          state.sortingDirection,
          Array.from(state.groupsPinned).map((id) => state.groups.get(id)!)
        );
        return result;
      }

      logInfo('No pinned groups available - make sure to load data if required');
      return [];
    },
    getGroupsUnpinned: (state) => {
      if (state.groupsUnpinned && state.groupsUnpinned.size > 0) {
        const result = customOrderBy(
          state.sortingType,
          state.sortingDirection,
          Array.from(state.groupsUnpinned).map((id) => state.groups.get(id)!)
        );
        return result;
      }

      logInfo('No unpinned groups available - make sure to load data if required');
      return [];
    },
    getUnreadMessagesTotal: (state) => {
      return state.unreadMessagesTotal;
    },
  },
  actions: {
    setTimer(timer: NodeJS.Timeout | null) {
      this.timer = timer;
    },
    toggleSortingDirection() {
      this.sortingDirection =
        this.sortingDirection === SortingDirectionEnum.Asc ? SortingDirectionEnum.Desc : SortingDirectionEnum.Asc;
    },
    setSortingType(sorting: SortingTypeEnum) {
      this.sortingType = sorting;
    },
    checkForCorrectTypes() {
      if (!(this.groups instanceof Map)) {
        this.groups = new Map<number, MenuGroupItemModel>();
      }
      if (!(this.groupsPermanentlyPinned instanceof Set)) {
        this.groupsPermanentlyPinned = new Set<number>();
      }
      if (!(this.groupsPinned instanceof Set)) {
        this.groupsPinned = new Set<number>();
      }
      if (!(this.groupsUnpinned instanceof Set)) {
        this.groupsUnpinned = new Set<number>();
      }
    },
    updateGroupStatus(group: MenuGroupItemModel) {
      this.checkForCorrectTypes();
      if (group.isPermanentlyPin) {
        this.groupsPermanentlyPinned.add(group.id);
        this.groupsPinned.delete(group.id);
        this.groupsUnpinned.delete(group.id);
      } else if (group.isPin) {
        this.groupsPinned.add(group.id);
        this.groupsUnpinned.delete(group.id);
        this.groupsPermanentlyPinned.delete(group.id);
      } else {
        this.groupsUnpinned.add(group.id);
        this.groupsPinned.delete(group.id);
        this.groupsPermanentlyPinned.delete(group.id);
      }
    },
    countUnreadMessagesTotal() {
      this.unreadMessagesTotal = Array.from(this.groups.values()).reduce((total, group) => {
        return total + (group.unreadMessagesCount || 0);
      }, 0);
    },
    addNewGroup(group: GroupModel) {
      const processedGroup = processGroup(group);
      this.groups.set(group.id, processedGroup);
      this.updateGroupStatus(processedGroup);
    },
    permanentlyPinGroup(groupId: number): boolean {
      const group = this.groups.get(groupId);
      if (!group) return false;

      group.isPermanentlyPin = true;
      this.updateGroupStatus(group);
      return true;
    },
    async getMenu(): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      this.checkForCorrectTypes();

      try {
        const response = await $api.menu.getCustomMenu();

        if (response.statusCode === 200) {
          const model = response as ResponseMenuModel;
          this.data = model.data;
          if (this.data?.sections?.length) {
            const groups =
              (this.data.sections.find((section) => section.sectionType === SectionTypeEnum.Groups)
                ?.items as MenuGroupItemModel[]) || [];

            groups.forEach((group) => {
              this.groups.set(group.id, group);
              this.updateGroupStatus(group);
            });
          }
          this.countUnreadMessagesTotal();
        } else {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
          throw new Error(error.errorMessages.join(', '));
        }
      } catch (e) {
        logErr('Error while getting custom menu', e);
      }

      this.isLoading = false;
    },
    async pinGroup(groupId: number): Promise<boolean> {
      const response = await $api.menu.pinGroup(groupId);
      if (response.statusCode === 200) {
        const group = this.groups.get(groupId);
        if (group) {
          group.isPin = true;
          this.updateGroupStatus(group);
        }
        return true;
      }

      const error = response as ResponseErrorModel;
      this.errors = cloneDeep(error.errorMessages);
      return false;
    },
    async unpinGroup(groupId: number): Promise<boolean> {
      const response = await $api.menu.unpinGroup(groupId);
      if (response.statusCode === 200) {
        const group = this.groups.get(groupId);
        if (group) {
          group.isPin = false;
          this.updateGroupStatus(group);
        }
        return true;
      }

      const error = response as ResponseErrorModel;
      this.errors = cloneDeep(error.errorMessages);
      return false;
    },
    /*
    async createMenuBySections() {
      if (this.data?.sections?.length) {
        for (const section of this.data.sections) {
          this.menuBySections[section.sectionType].title = section.title;
          this.menuBySections[section.sectionType].isPublic = section.isPublic;
          this.menuBySections[section.sectionType].items = (section.items || []).map((sectionItem) => {
            if (sectionItem.type === 'File' && 'id' in sectionItem) {
              this.fileItems.push(sectionItem);
            }
            if (sectionItem.type === 'Group') {
              return processGroup(sectionItem as MenuGroupItemModel);
            } else {
              return sectionItem;
            }
          });
        }
      }
      return;
    },
    async fetchFileItemsInfo() {
      if (this.fileItems.length !== 0) {
        const docStore = useDocStore();
        const results = await Promise.allSettled(this.fileItems.map((file) => docStore.getDocById(file.id)));
        results.forEach((result, index) => {
          if (result.status === 'fulfilled' && result.value !== undefined) {
            this.fileItems[index] = Object.assign({}, this.fileItems[index], result.value);
          }
        });
      }
      return;
    },
    mapItemsWithSections(menuItems: MenuItemModel[]) {
      const keys = Object.keys(this.menuBySections);
      for (const sectionKey of keys) {
        const section = this.menuBySections[sectionKey as keyof MenuBySections];
        if (sectionKey !== 'Groups') {
          section.items = (section.items || [])
            .map((sectionItem) => {
              const mappedItem = menuItems.find((item) => item.type === sectionItem.type);
              if (sectionItem.type === 'File' && 'id' in sectionItem) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return this.fileItems.find((fileItem) => fileItem.id === sectionItem.id)!;
              }
              return mappedItem ? assign(sectionItem, mappedItem) : sectionItem;
            })
            .filter((sectionItem) => !NOT_AVAILABLE_ITEMS.includes(sectionItem.type));
        }
      }
    },
    */
  },
  persist: {
    debug: true,
    serializer: {
      serialize(state: MenuState) {
        /**
         * NOTE: For debug purposes use following script to deserialize data from localStorage in devTools
         * @see scripts/zipson_serializer_poc.js
         */
        return stringify({
          ...state,
          groups: Array.from(state.groups.entries()), // Convert Map to array of entries
          groupsPinned: Array.from(state.groupsPinned), // Convert Set to array
          groupsPermanentlyPinned: Array.from(state.groupsPermanentlyPinned), // Convert Set to array
          groupsUnpinned: Array.from(state.groupsUnpinned), // Convert Set to array
        });
      },
      deserialize(serializedState: string) {
        const state = parse(serializedState);
        return {
          ...state,
          groups: new Map(state.groups || []), // Convert array back to Map
          groupsPinned: new Set(state.groupsPinned || []), // Convert array back to Set
          groupsPermanentlyPinned: new Set(state.groupsPermanentlyPinned || []), // Convert array back to Set
          groupsUnpinned: new Set(state.groupsUnpinned || []), // Convert array back to Set
        };
      },
    } as Serializer,
    beforeHydrate(ctx) {
      const menu = localStorage.getItem('menu');
      if (!menu) {
        logInfo('No menu data found in localStorage');
        return;
      }

      const savedState = parse(menu);

      if (savedState.groups && Array.isArray(savedState.groups)) {
        ctx.store.groups = new Map(savedState.groups);
      }

      if (savedState.groupsPinned && Array.isArray(savedState.groupsPinned)) {
        ctx.store.groupsPinned = new Set(savedState.groupsPinned);
      }

      if (savedState.groupsPermanentlyPinned && Array.isArray(savedState.groupsPermanentlyPinned)) {
        ctx.store.groupsPermanentlyPinned = new Set(savedState.groupsPermanentlyPinned);
      }

      if (savedState.groupsUnpinned && Array.isArray(savedState.groupsUnpinned)) {
        ctx.store.groupsUnpinned = new Set(savedState.groupsUnpinned);
      }
    },
    afterHydrate(ctx) {
      const serializedState = {
        ...ctx.store,
        groups: Array.from(ctx.store.groups.size > 0 && ctx.store.groups?.entries()), // Convert Map to array of entries
        groupsPinned: Array.from(ctx.store.groupsPinned), // Convert Set to array
        groupsPermanentlyPinned: Array.from(ctx.store.groupsPermanentlyPinned), // Convert Set to array
        groupsUnpinned: Array.from(ctx.store.groupsUnpinned), // Convert Set to array
      };
      localStorage.setItem('menu', stringify(serializedState));
    },
  },
});

const processGroup = (group: GroupModel): MenuGroupItemModel => {
  const {
    type,
    stats: { messages },
    ...rest
  } = group;
  return Object.assign(rest, {
    index: 0,
    isPin: false,
    type: 'Group',
    groupType: type,
    unreadMessagesCount: messages,
  });
};

const customOrderBy = (
  sorting: SortingTypeEnum,
  sortingDirection: SortingDirectionEnum,
  groups: MenuGroupItemModel[]
) => {
  const sortingPaths: Record<SortingTypeEnum, string> = {
    [SortingTypeEnum.Name]: 'title',
    [SortingTypeEnum.Type]: 'type',
    [SortingTypeEnum.UnreadMessagesCount]: 'unreadMessagesCount',
    [SortingTypeEnum.StatsMembers]: 'stats.members',
    [SortingTypeEnum.StatsMessages]: 'stats.messages',
    [SortingTypeEnum.Group]: 'title',
    [SortingTypeEnum.Author]: 'title',
    [SortingTypeEnum.Date]: 'title',
    [SortingTypeEnum.Size]: 'title',
  };

  const sortingPath = sortingPaths[sorting] || 'title';
  return orderBy(groups, [sortingPath], [sortingDirection]);
};
