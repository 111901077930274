import type { NotificationsIdsModel } from '@/types';

export const defaultNotificationsIds: NotificationsIdsModel = {
  all: {
    ids: [],
  },
  unread: {
    ids: [],
  },
};
