<template>
  <h3 v-if="!isTitleEditing" class="task-title" @click="editTitle()">
    {{ taskData.title }}
  </h3>
  <div v-else class="task-title-edit">
    <app-input-outline
      v-model:value="data.title"
      v-model:key-up="keyUpAction"
      :label="$t('feed.task.title')"
      :is-floating="false"
      apply-auto-focus
    />

    <div v-if="titleErrors.length > 0">
      <form-error name="titleErrors" :errors-array="titleErrors" />
    </div>

    <ion-button
      mode="md"
      fill="outline"
      color="medium"
      :disabled="isLoading || titleErrors.length > 0"
      @click.stop="updateTaskTitle()"
    >
      <span>{{ $t('save') }}</span>
      <icons-provider
        v-if="isLoading"
        slot="start"
        :icon-props="{ width: '16', height: '16' }"
        :name="AppIconsEnum.CircleAnim"
        class="mr-md"
      />
    </ion-button>
    <ion-button mode="md" fill="outline" color="medium" :disabled="isLoading" @click.stop="cancelEdit()">
      {{ $t('cancel') }}
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { ErrorObject } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';
import type { ComputedRef, PropType } from 'vue';
import { computed, ref, reactive, watch } from 'vue';

import { FormError, IconsProvider, AppInputOutline } from '@/components';
import { AppIconsEnum } from '@/enums';
import { required } from '@/helpers';
import { useProjectsStore } from '@/store';
import type { TaskManagementTaskModel } from '@/types';

const props = defineProps({
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
});

const rules = computed(() => ({
  title: {
    required,
  },
}));

const projectsStore = useProjectsStore();
const isLoading = ref<boolean>(false);
const isTitleEditing = ref<boolean>(false);
const keyUpAction = ref<boolean>(false);
const data = reactive({
  title: props.taskData.title,
});

const editTitle = () => {
  isTitleEditing.value = true;
};

const cancelEdit = () => {
  isTitleEditing.value = false;
  data.title = props.taskData.title;
  v$.value.$reset();
};

const v$ = useVuelidate(rules, data);
const titleErrors: ComputedRef<string[]> = computed(() => {
  const errors = v$.value.title.$errors.map((m: ErrorObject) => m.$message as string);
  return [...errors];
});

const updateTaskTitle = async () => {
  isLoading.value = true;
  const isValid = await v$.value.$validate();

  if (isValid) {
    if (await projectsStore.updateTaskTitle(props.taskData.id, data.title)) {
      isTitleEditing.value = false;
    }
  }

  isLoading.value = false;
};

watch(keyUpAction, async () => {
  if (keyUpAction.value) updateTaskTitle();
});
</script>

<style scoped lang="scss">
h3 {
  padding: 0;
  margin: 0;
}

.task-title {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &-edit {
    margin-right: app-padding(md);
    margin-bottom: app-padding(md);
    ion-button {
      @include resetStyleFromIonicButton;
      margin-top: app-padding(md);
      --border-radius: #{app-radius(md)};
      --border-width: 1px;
    }

    ion-button:last-child {
      margin-left: app-padding(md);
    }
  }
}
</style>
