<template>
  <icons-provider
    v-if="showLogoOnly"
    name="communex-small"
    :icon-props="{
      width: dimensions.width,
      height: dimensions.height,
      fill: color,
    }"
  />
  <icons-provider
    v-else
    name="communex"
    :icon-props="{
      width: dimensions.width,
      height: dimensions.height,
      fill: color,
    }"
  />
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { IconsProvider } from '@/components';
import { ThemeAppEnum } from '@/enums';
import { convertHexToRgb } from '@/helpers';
import { useAppStore, useNetworkStore } from '@/store';
import type { NetworkFullSettingsModel } from '@/types';

const props = defineProps({
  withColor: {
    type: Boolean,
    default: false,
  },
  showLogoOnly: {
    type: Boolean,
    default: true,
  },
});

const appStore = useAppStore();
const networkStore = useNetworkStore();

const networkSettings: ComputedRef<NetworkFullSettingsModel | null> = computed(() => networkStore.settings);

/* const logoIsLoad = ref(false); */
const color: ComputedRef<string> = computed(() => {
  if (networkSettings.value?.headBackgroundColor && props.showLogoOnly) {
    return (
      'rgb(' +
      convertHexToRgb(networkSettings.value.headBackgroundColor)
        .split(',')
        .map((value) => {
          return Math.round(+value / 2);
        })
        .join(',') +
      ')'
    );
    //TODO: Should be dynamic based on --ion-color-intra in variables.css
  } else {
    return appStore.theme === ThemeAppEnum.Dark ? '#ffffff' : '#121212';
  }
});

const dimensions = computed(() => {
  if (props.showLogoOnly) {
    return {
      width: '29',
      height: '29',
    };
  } else {
    return {
      width: '100%',
      height: '100%',
    };
  }
});

/*
  ?
  decided to leave this commented for future implementation of dark theme supporting with new icon generator
    const path: ComputedRef<string> = computed(() => {
      if (props.withColor) {
        const color =
          networkSettings.value !== null
            ? networkSettings.value.mobileColor
            : getComputedStyle(document.documentElement).getPropertyValue('--ion-color-intra');
        return appStore.getAppLogoPathWithColor(LightOrDark.getColor(color));
      } else {
        return appStore.getAppLogoPath;
      }
  });
*/
</script>

<style scoped lang="scss">
ion-img {
  max-width: 20rem;
}
</style>
